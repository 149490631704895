import { Button, Popover } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Images from "../../theme/Images";
import Menu from "../../assets/icons/menu.svg";
import "./styles.scss";
import MenuDrawer from "../menuDrawer";
import PantryDrawer from "../PantryDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationModal from "../confirmationModal";
import LoginModal from "../loginModal";
import RegisterModal from "../registerModal";
import { userSignOutRequest } from "../../redux/slicers/user";
import { style } from "@mui/system";
import SuggestIngredientsModal from "../SuggestIngredientsModal";
import AddRatingModal from "../AddRatingModal";
import { toastAlert } from "../../services/utils";
import { ALERT_TYPES } from "../../constants";
import { BASE_URL } from "../../config/webService";
const Header = () => {
  const authenticated = useSelector(({ user }) => user.isAuthenticated);
  const userData = useSelector(({ user }) => user.data.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [pantryOpen, setPantryOpen] = useState(false);
  const [loginOpen, setloginOpen] = useState(false);
  const [registerOpen, setregisterOpen] = useState(false);
  const [suggestIngredientsOpen, setSuggestIngredientsOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setPantryOpen(false);
  };
  const navigate = useNavigate();
  const handleCloseLogin = () => {
    setloginOpen(!loginOpen);
  };
  const handleCloseRegister = () => {
    setregisterOpen(!registerOpen);
  };
  const handleCloseSuggest = () => {
    setSuggestIngredientsOpen(!suggestIngredientsOpen);
  };
  const handleLogout = () => {
    dispatch(userSignOutRequest());
    toastAlert("Signed out", ALERT_TYPES.success);
  };
  const pathName = useLocation();

  const userImage = userData?.image ? BASE_URL + userData?.image : Images.User;

  return (
    <div className="main-header">
      <img
        src={Images.HeaderLogo}
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
      <div
        className="menu-icon-cont"
        onClick={() => {
          setOpen(true);
        }}
      >
        <img src={Menu} />
      </div>
      {!authenticated && (
        <div className="header-btn-cont">
          <Button type="primary" onClick={() => setPantryOpen(true)}>
            <Images.PantryIcon />
            My Pantry
          </Button>
          <Button className="login-btn auth-btn" onClick={handleCloseLogin}>
            Login
          </Button>
          |
          <Button
            type="primary"
            className="auth-btn"
            onClick={handleCloseRegister}
          >
            Signup
          </Button>
        </div>
      )}
      {authenticated && (
        <div className="header-btn-cont">
          <Button type="primary" onClick={handleCloseSuggest}>
            Suggest Ingredient
          </Button>
          <p>|</p>

          <Button
            type="primary"
            onClick={() => navigate("/my-recipe")}
            className={`${pathName.pathname === "/my-recipe" && "btn-path"}`}
          >
            My Recipe
          </Button>
          <Button type="primary" onClick={() => setPantryOpen(true)}>
            <Images.PantryIcon />
            My Pantry
          </Button>
          <img
            src={
              pathName.pathname.includes("favorites")
                ? Images.HeartFilled
                : Images.Heart
            }
            className="heart-icon"
            onClick={() => navigate("/favorites")}
          />
          <p>|</p>
          <div className="header-user-name">
            <div className="user-image">
              <img src={userImage} />
            </div>
            <Popover
              trigger="click"
              placement="bottom"
              content={
                <div className="popover-content">
                  <p
                    className="popover-options"
                    onClick={() => navigate("/profile")}
                  >
                    My Account
                  </p>
                  <p className="popover-options" onClick={handleLogout}>
                    Logout
                  </p>
                </div>
              }
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <div className="user-name">
                <p>{userData?.username}</p>
                <Images.DownArrow />
              </div>
            </Popover>
          </div>
        </div>
      )}
      {loginOpen && (
        <LoginModal
          handleClose={handleCloseLogin}
          handleRegister={() => {
            handleCloseLogin();
            handleCloseRegister();
          }}
        />
      )}
      {registerOpen && (
        <RegisterModal
          handleClose={handleCloseRegister}
          handleLogin={() => {
            handleCloseRegister();
            handleCloseLogin();
          }}
        />
      )}
      {suggestIngredientsOpen && (
        <SuggestIngredientsModal handleClose={handleCloseSuggest} />
      )}
      {open && (
        <MenuDrawer
          open={open}
          handleClose={handleClose}
          handleSuggest={handleCloseSuggest}
          handlePantry={() => {
            handleClose();
            setPantryOpen(true);
          }}
        />
      )}
      <PantryDrawer open={pantryOpen} handleClose={handleClose} />
    </div>
  );
};

export default Header;
