import { fork } from "redux-saga/effects";
import general from "./general";
import subscription from "./subscription";
import Recipe from "./recipe";
import User from "./user";
import Ingredients from "./ingredients";
import Filters from "./filters";
import Collections from "./collections";
import Ratings from "./ratings";
import Comments from "./comments";

export default function* root() {
	yield fork(general);
	yield fork(Recipe);
	yield fork(subscription);
	yield fork(User);
	yield fork(Ingredients);
	yield fork(Filters);
	yield fork(Collections);
	yield fork(Ratings);
	yield fork(Comments);
}
