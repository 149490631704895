import { Button, Col, Form, Input, Row, Upload } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "../../../components";
import { ERROR_SOMETHING_WENT_WRONG } from "../../../config/webService";
import { ALERT_TYPES, HOME_ROUTE } from "../../../constants";
import { forgotPasswordRequest } from "../../../redux/slicers/user";
import { toastAlert } from "../../../services/utils";
import { Images } from "../../../theme";
import "./styles.scss";
function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const [ForgotPasswordForm] = Form.useForm();

  const handleSubmit = (val) => {
    setLoading(true);
    dispatch(
      forgotPasswordRequest({
        payloadData: { ...val },
        responseCallback: (status, res) => {
          setLoading(false);
          if (status === true) {
            toastAlert("Email Sent Successfully.");
            navigate(HOME_ROUTE);
          } else {
            toastAlert(
              res?.message ?? ERROR_SOMETHING_WENT_WRONG,
              ALERT_TYPES.error
            );
          }
        },
      })
    );
  };

  return (
    <div className="forgot-password-wrapper">
      <Row gutter={[10, 15]}>
        <Col span={24}>
          <span className="forgot-password-header">
            <img
              src={Images.Back}
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            />
            <h4>Back</h4>
          </span>
        </Col>
        <Col span={24} className="forgot-password-heading-col">
          <h4>Forgot Your Password</h4>
          <p>
            Enter your registered email below to receive password reset
            instruction
          </p>
        </Col>
        <Col span={24} className="profile-form-col">
          <Form
            layout="vertical"
            className="profile-form"
            form={ForgotPasswordForm}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please Enter a Email",
                },
                {
                  type: "email",
                  message: "Invalid email address.",
                },
              ]}
              label="Email *"
            >
              <Input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item>
              <ButtonComponent
                isLoading={loading}
                className="profile-btn"
                type="submit"
                ripple={false}
                disabled={loading}
                text={"Send"}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default ForgotPassword;
