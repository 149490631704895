import { Col, Form, Input, Rate, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ALERT_TYPES, COLLECTION_CARDS } from "../../constants";
import {
	createRatingRequest,
	getRatingsRequest,
	updateRatingRequest,
} from "../../redux/slicers/ratings";
import { toastAlert } from "../../services/utils";
import { Images } from "../../theme";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";
function AddRatingModal({
	handleClose,
	image,
	title,
	subtitle,
	isFav,
	handleRemoveFav,
	ingredients,
	handleFullRecipe,
	isDisabled,
	id,
	...props
}) {
	const dispatch = useDispatch();
	const [selectedCollection, setSelectedCollection] = useState("");
	const [rating, setRating] = useState(0);
	const user = useSelector((state) => state?.user?.data?.user);
	const [isLoading, setIsLoading] = useState(false);

	const allRatings = useSelector((state) => state?.ratings?.ratings);

	const userRating = allRatings?.find(
		(r) =>
			r?.recipeId == `${id}` &&
			r?.source === props?.owner &&
			r?.userId === user?.id
	);

	useEffect(() => {
		if (userRating?.rating) {
			setRating(userRating?.rating);
		}
	}, [userRating]);

	const handleChangeRating = (val) => {
		setRating(val);
	};

	const addRating = () => {
		if (rating == 0) {
			toastAlert("Select Rating", ALERT_TYPES.error);
			return;
		}

		setIsLoading(true);

		if (!userRating) {
			const payload = {
				data: {
					user: { connect: [{ id: user?.id }] },
					recipe_id: `${id}`,
					source: props?.owner,
					rating: rating,
				},
			};

			dispatch(
				createRatingRequest({
					payloadData: payload,
					responseCallback: (status) => {
						setIsLoading(false);
						if (status) {
							dispatch(getRatingsRequest());
							handleClose();
						}
					},
				})
			);
		} else {
			const payload = {
				data: {
					rating: rating,
				},
			};

			dispatch(
				updateRatingRequest({
					ratingId: userRating?.id,
					payloadData: payload,
					responseCallback: (status) => {
						if (status) {
							dispatch(getRatingsRequest());
							handleClose();
						}
					},
				})
			);
		}
	};

	return (
		<Modal
			className="add-rating-modal"
			visible={true}
			centered
			onCancel={handleClose}
			footer={null}
			closeIcon={<img src={Images.CloseIcon} />}
		>
			<div className="modal-wrapper">
				<Row align="middle" gutter={[20, 10]} style={{ width: "100%" }}>
					<Col span={24}>
						<span className="title-wrapper">
							<h4 className="title">Add Quick Rating</h4>
							<p className="subtitle">Add Your Reviews</p>
						</span>
					</Col>
					<Col span={24} className="rating-wrapper">
						<Rate
							value={rating}
							className="recipe-rating"
							onChange={handleChangeRating}
						/>
					</Col>
					<Col span={24} className="create-btn-wrapper">
						<ButtonComponent
							onClick={addRating}
							className="create-modal-btn"
							text="Add Rating"
							isLoading={isLoading}
						/>
					</Col>
				</Row>
			</div>
		</Modal>
	);
}

export default AddRatingModal;
