import { BASE_URL } from "../config/webService";
import { EDMAM_TITLE, SPOONACULAR_TITLE } from "../constants";
import { isEmptyValue, generateGuid } from "../services/utils";
// import  uuid  from "uuid";

// const unique_id = uuid.v4();

// SPOONACULAR API DATA MANUPILATOR
export function manipulateSpoonacularData(data) {
  function ingredients(show) {
    const { measures, name } = show;

    return {
      text:
        Math.round(measures?.metric?.amount) +
        " " +
        measures?.metric?.unitLong +
        " " +
        name,
    };
  }
  let recipeObj = {};
  recipeObj["owner"] = SPOONACULAR_TITLE;
  recipeObj["url"] = data?.sourceUrl ?? "";
  recipeObj["source"] = data?.sourceName ?? "";
  recipeObj["title"] = data?.title ?? "";
  recipeObj["thumbnail"] = data?.image ?? "";
  recipeObj["ingredients"] = data?.extendedIngredients?.map(ingredients);
  recipeObj["id"] = data?.id ?? "";

  return recipeObj;
}
export function manipulateSpoonacularDataByIngredients(data, ingredientsList) {
  function ingredients(show) {
    const { amount, unit, name } = show;

    return {
      text: Math.round(amount) + " " + unit + " " + name,
    };
  }
  let recipeObj = {};
  recipeObj["owner"] = SPOONACULAR_TITLE;
  recipeObj["url"] = data?.sourceUrl ?? "";
  recipeObj["source"] = data?.sourceName ?? "";
  recipeObj["title"] = data?.title ?? "";
  recipeObj["thumbnail"] = data?.image ?? "";
  recipeObj["ingredients"] =
    data?.usedIngredients
      ?.map(ingredients)
      .concat(data?.missedIngredients?.map(ingredients)) ?? "";
  recipeObj["id"] = data?.id ?? "";

  let missingIngredient = "";

  console.log({ ingredientsList });
  // if (data?.usedIngredients?.length > 0) {
  // 	for (let ing of data?.usedIngredients) {
  // 		const ingredient = ing?.name?.toLowerCase()
  // 		const findIng = ingredientsList?.find((a) =>
  // 		ingredient?.includes(a?.toLowerCase())
  // 		);

  // 		if (!findIng  && ingredient !== "water" && ingredient !== "salt") {
  // 			recipeObj["missingIngredient"] = ing?.name;
  // 			break;
  // 		}
  // 	}
  // }

  if (data?.missedIngredients?.length > 0) {
    for (let ing of data?.missedIngredients) {
      const selectedIngredients = [...ingredientsList]?.join(" ");
      const arr1 = ing?.name
        ?.toLowerCase()
        .replace(/[^\w\s]/gi, "")
        .split(" ");

      console.log({ selectedIngredients });

      const arr2 = selectedIngredients
        ?.toLowerCase()
        ?.replace(/[^\w\s]/gi, "")
        .split(" ");

      console.log(data.title, arr1, arr2);

      let included = false;

      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] === "water" || arr1[i] === "salt") {
          included = true;
          continue;
        }

        if (arr2.includes(arr1[i])) {
          included = true;
          break;
        }
      }

      if (included === false) {
        missingIngredient = ing?.name;
      } else {
        continue;
      }

      if (missingIngredient?.length > 0) {
        recipeObj["missingIngredient"] = missingIngredient;
        break;
      }
    }
  }

  return recipeObj;
}

// SPOONACULAR API DATA MANUPILATOR
export function getSpoonacularRecipeListManipulator(list) {
  if (isEmptyValue(list)) return [];

  let recipeList = [];

  list.forEach((item, index) => {
    recipeList.push(manipulateSpoonacularData(item));
  });
  return recipeList;
}
export function getSpoonacularRecipeListManipulatorByIngredients(
  list,
  ingredientsList
) {
  if (isEmptyValue(list)) return [];

  let recipeList = [];

  list.forEach((item, index) => {
    recipeList.push(
      manipulateSpoonacularDataByIngredients(item, ingredientsList)
    );
  });
  return recipeList;
}

// EDMAM API DATA MANUPILATOR
export function manipulateEdmamRecipeData(data, i, ingredientsList = []) {
  try {
    function ingredients(show) {
      const { text } = show;

      return { text };
    }
    let recipeObj = {};

    const urlSplit = data?._links?.self?.href?.split("?")?.[0] || "";
    const id = urlSplit?.split("/")?.[urlSplit?.split("/")?.length - 1];

    recipeObj["owner"] = EDMAM_TITLE;
    recipeObj["url"] = data?.recipe?.url ?? "";
    recipeObj["source"] = data?.recipe?.source ?? "";
    recipeObj["title"] = data?.recipe?.label ?? "";
    recipeObj["thumbnail"] = data?.recipe?.images?.SMALL?.url ?? "";
    recipeObj["ingredients"] = data?.recipe?.ingredients?.map(ingredients);
    recipeObj["id"] = id ?? data;

    let missingIngredient = "";

    if (data?.recipe?.ingredients?.length > 0) {
      for (let ing of data?.recipe?.ingredients) {
        const selectedIngredients = [...ingredientsList]?.join(" ");
        const arr1 = ing?.food
          ?.toLowerCase()
          .replace(/[^\w\s]/gi, "")
          .split(" ");
        const arr2 = selectedIngredients
          ?.toLowerCase()
          ?.replace(/[^\w\s]/gi, "")
          .split(" ");
        let included = false;
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] === "water" || arr1[i] === "salt") {
            included = true;
            continue;
          }
          if (arr2.includes(arr1[i])) {
            included = true;
            break;
          }
        }

        if (!included) {
          missingIngredient = ing?.food;
          break;
        } else {
          continue;
        }
      }
    }

    if (missingIngredient) {
      recipeObj["missingIngredient"] = missingIngredient;
    }
    return recipeObj;
  } catch (error) {
    console.error(error);
  }
}

// EDMAM API DATA MANUPILATOR
export function getEdmamRecipeListManipulator(list, ingredients = []) {
  if (isEmptyValue(list)) return [];

  let recipeList = [];

  list.forEach((item, index) => {
    recipeList.push(manipulateEdmamRecipeData(item, index, ingredients));
  });
  return recipeList;
}

export function manipulateFoodieRecipeByIngredients(
  data,
  ingredientsList = []
) {
  try {
    let recipeObj = {};
    recipeObj["owner"] = "foodie";
    recipeObj["url"] = data?.website ?? "";
    recipeObj["source"] = "";
    recipeObj["title"] = data?.title ?? "";
    recipeObj["thumbnail"] = data?.image?.url ? data?.image?.url : "";
    recipeObj["ingredients"] = data?.ingredients?.map((i) => {
      return {
        text: `${i?.quantity} ${i?.name}`,
      };
    });
    recipeObj["id"] = data?.id ?? "";
    if (data?.ingredients?.length > 0) {
      for (let ing of data?.ingredients) {
        const selectedIngredients = [...ingredientsList]?.join(" ");
        const arr1 = ing?.name
          ?.toLowerCase()
          .replace(/[^\w\s]/gi, "")
          .split(" ");
        const arr2 = selectedIngredients
          ?.toLowerCase()
          ?.replace(/[^\w\s]/gi, "")
          .split(" ");
        let included = false;
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] === "water" || arr1[i] === "salt") {
            included = true;
            continue;
          }
          if (arr2.includes(arr1[i])) {
            included = true;
            break;
          }
        }

        if (!included) {
          recipeObj["missingIngredient"] = ing?.name;
          break;
        } else {
          continue;
        }
      }
    }

    return recipeObj;
  } catch (error) {
    console.error(error);
  }
}

export function manipulatorGetStrapiRandomRecipes(list, ingedientList = []) {
  try {
    if (isEmptyValue(list)) {
      return [];
    }

    let recipeList = [];

    list?.forEach((item) => {
      recipeList.push(manipulateFoodieRecipeByIngredients(item, ingedientList));
    });

    return recipeList;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export function getMyRecipeManipulator(list) {
  try {
    if (_.isEmpty(list)) return [];

    const allRecipes = [];

    for (let item of list) {
      const attributes = item?.attributes;

      const payload = {
        id: item?.id,
        cook_time: attributes?.cook_time ?? 0,
        serves: attributes?.serves ?? "",
        title: attributes?.title ?? "",
        url: attributes?.website ?? "",
        thumbnail: attributes?.image?.data?.attributes?.url
          ? BASE_URL + attributes?.image?.data?.attributes?.url
          : "",
        owner: "foodie",
        isApprove: attributes?.isApprove ?? false,
        ingredients: attributes?.ingredients?.map((i) => {
          return {
            text: `${i?.quantity} ${i?.name}`,
          };
        }),
      };

      allRecipes.push(payload);
    }

    return allRecipes;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export function getSidebarMealsManipulator(list) {
  if (isEmptyValue(list)) return list;

  const meals = [];

  for (let meal of list) {
    const attributes = meal?.attributes;
    const payload = {
      id: meal?.id,
      name: attributes?.name ?? "",
      edamam: attributes?.edamam ?? {},
      spoonacular: attributes?.spoonacular ?? {},
      options:
        attributes?.options?.map((item) => ({ ...item, check: true })) ?? [],
    };

    meals.push(payload);
  }

  return meals;
}
