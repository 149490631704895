import { Col, Image, Input, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ALERT_TYPES, PROFILE_IMAGE_FORMATS } from "../../constants";
import { createCommentRequest } from "../../redux/slicers/comments";
import { uploadFileRequest } from "../../redux/slicers/general";
import { toastAlert } from "../../services/utils";
import { Images } from "../../theme";
import "./styles.scss";
function CommentBox({
	image,
	title,
	subtitle,
	isFav,
	handleRemoveFav,
	ingredients,
	handleFullRecipe,
	isDisabled,
	id,
	recipeComments,
	...props
}) {
	const dispatch = useDispatch();
	const [comment, setComment] = useState("");
	const inputRef = useRef(null);
	const [files, setFiles] = useState([]);

	const user = useSelector((state) => state?.user?.data?.user);
	const comments = useSelector((state) => state?.comments?.comments);

	const handleChangeComment = (e) => {
		setComment(e?.target?.value);
	};

	const handleChangeFilePicker = (e) => {
		const allFiles = e?.target?.files;
		let filesToPush = [];

		for (let f of allFiles) {
			if (PROFILE_IMAGE_FORMATS.includes(f?.type)) {
				filesToPush.push(f);
			} else {
				toastAlert("Only JPEG and PNG images are allowed", ALERT_TYPES.error);
			}
		}

		setFiles(filesToPush);
	};

	const handleSubmit = () => {
		if (!comment) {
			toastAlert("Add Comment", ALERT_TYPES.error);
			return;
		}

		const payload = {};
		if (files?.length > 0) {
			const formData = new FormData();
			for (let f in files) {
				formData.append("files", files[f], "image-" + f + 1);
			}

			dispatch(
				uploadFileRequest({
					payloadData: formData,
					responseCallback: (status, res) => {
						if (status) {
							const ids = res?.map((item) => item?.id);

							const payload = {
								data: {
									images: ids,
									comment_text: comment,
									recipe_id: `${id}`,
									source: props?.owner,
									user: { connect: [{ id: user?.id }] },
								},
							};

							dispatch(
								createCommentRequest({
									payloadData: payload,
									responseCallback: (status, res) => {
										if (status) {
											recipeComments();
											setComment("");
											setFiles([]);
										}
									},
								})
							);
						}
					},
				})
			);
		} else {
			const payload = {
				data: {
					comment_text: comment,
					recipe_id: `${id}`,
					source: props?.owner,
					user: { connect: [{ id: user?.id }] },
				},
			};

			dispatch(
				createCommentRequest({
					payloadData: payload,
					responseCallback: (status, res) => {
						if (status) {
							recipeComments();
							setComment("");
							setFiles([]);
						}
					},
				})
			);
		}
	};

	return (
		<div className="comment-box-wrapper">
			<div className="title">
				<h3>Comments</h3>
			</div>

			<div className="comment-content">
				{comments?.length > 0 &&
					comments?.map((commentItem) => (
						<div className="comment-item">
							<div className="commenter-info">
								<img src={commentItem?.user?.image ?? Images.User1} />

								<span className="commenter-name">
									<h5> {commentItem?.user?.username} </h5>
								</span>

								<p className="date">
									.{moment(commentItem?.created).format("DD MMM")}{" "}
								</p>
							</div>

							<span className="comment-text">{commentItem?.comment}</span>

							<span className="comment-images">
								{commentItem?.images?.length > 0 &&
									commentItem?.images?.map((item) => <Image src={item} />)}
							</span>
						</div>
					))}

				{/* <div className="comment-item">
					<div className="commenter-info">
						<img src={Images.User2} />

						<span className="commenter-name">
							<h5>Doe Wan</h5>
							<p>@Doe</p>
						</span>

						<p className="date">• 20 dec</p>
					</div>

					<span className="comment-text">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. posuere
						turpis sit amet lobortis commodo. Sed ornare blandit viverra. In
						pharetra ex ac odio iaculis
					</span>
				</div> */}
			</div>

			<input
				type="file"
				multiple={true}
				accept={"image/jpeg, image/png"}
				style={{ display: "none" }}
				ref={inputRef}
				onChange={handleChangeFilePicker}
			/>

			<Input
				placeholder="Add Comments"
				suffix={
					<img
						src={Images.Camera}
						onClick={() => inputRef?.current?.click?.()}
					/>
				}
				value={comment}
				onChange={handleChangeComment}
				onPressEnter={handleSubmit}
			/>
		</div>
	);
}

export default CommentBox;
