import { Col, Form, Image, Input, Row, Upload } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLLECTION_CARDS, FILE_FORMATS_COLLECTION } from "../../constants";
import { FileImageOutlined, PlusCircleOutlined } from "@ant-design/icons";

import {
	createCollectionRequest,
	createFavouriteRequest,
	getAllCollectionRequest,
} from "../../redux/slicers/collections";
import { blobToBase64, getFileFormat, toastAlert } from "../../services/utils";
import { Images } from "../../theme";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";
import { uploadFileRequest } from "../../redux/slicers/general";
function CreateCollectionModal({ handleClose, title, ...props }) {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [collectionImage, setcollectionImage] = useState(null);

	const user = useSelector((state) => state.user?.data);

	const handleFinish = (val) => {
		// if (isLoading) return;
		setIsLoading(true);
		const { image } = val;

		const data = new FormData();
		data.append("files", image.file, image.file.name);

		const payload = {
			data: {
				name: val?.collection,
				user: user?.user?.id,
				image: null,
				// user: { connect: [{ id: user?.user?.id }] },
			},
		};

		dispatch(
			uploadFileRequest({
				payloadData: data,
				responseCallback: (status, res) => {
					if (status) {
						payload.data.image = res[0]?.id;
						dispatch(
							createCollectionRequest({
								payloadData: payload,
								responseCallback: (status, colres) => {
									if (status) {
										const favouritePayload = {
											data: {
												source: props?.owner,
												recipe_id: `${props?.id}`,
												user: user?.user?.id,
												collection: colres?.id,
											},
										};
										dispatch(
											createFavouriteRequest({
												payloadData: favouritePayload,
												responseCallback: (status, favres) => {
													dispatch(
														getAllCollectionRequest({
															payloadData: { userId: user?.user?.id },
															responseCallback: () => {},
														})
													);
													if (status) {
														toastAlert("Favourite Added Successfully.");
													}
													handleClose();
													setIsLoading(false);
												},
											})
										);
									} else {
										handleClose();
										setIsLoading(false);
									}
								},
							})
						);
					} else {
						handleClose();
						setIsLoading(false);
					}
				},
			})
		);
		// setIsLoading(true);

		// dispatch(
		//   createCollectionRequest({
		//     payloadData: payload,
		//     responseCallback: (status, res) => {
		//       if (status) {
		//         const favouritePayload = {
		//           data: {
		//             source: props?.owner,
		//             recipe_id: `${props?.id}`,
		//             user: { connect: [{ id: user?.user?.id }] },
		//             collection: { connect: [{ id: res?.id }] },
		//           },
		//         };

		//         dispatch(
		//           createFavouriteRequest({
		//             payloadData: favouritePayload,
		//             responseCallback: (res) => {
		//               dispatch(
		//                 getAllCollectionRequest({
		//                   payloadData: { userId: user?.user?.id },
		//                   responseCallback: {},
		//                 })
		//               );
		//               handleClose();
		//               toastAlert("Favourite Added Successfully.");
		//               setIsLoading(false);
		//             },
		//           })
		//         );
		//       } else {
		//         setIsLoading(false);
		//       }
		//     },
		//   })
		// );
	};

	return (
		<Modal
			className="create-collection-modal"
			visible={true}
			centered
			onCancel={() => {
				form.resetFields();
				setcollectionImage(null);
				handleClose();
			}}
			footer={null}
			closeIcon={<img src={Images.CloseIcon} />}
		>
			<div className="modal-wrapper">
				<Form form={form} onFinish={handleFinish}>
					<Row align="middle" gutter={[20, 10]}>
						<Col span={24}>
							<span className="title-wrapper">
								<h4 className="title">{title}</h4>
							</span>
						</Col>
						<Col span={24}>
							<Form.Item
								name={"collection"}
								rules={[
									{
										message: "Collection Name is required.",
										required: true,
									},
									{
										whitespace: true,
										message: "Whitespaces are not allowed",
									},
								]}
							>
								<Input placeholder="Collection Name" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name={"image"}
								rules={[
									{
										validator: (_, value) => {
											if (!value?.fileList?.length) {
												return Promise.reject(
													new Error("Collection Image is required.")
												);
											} else if (
												!FILE_FORMATS_COLLECTION.includes(
													getFileFormat(value.file.name)
												)
											) {
												return Promise.reject(
													new Error("Only png, jpg, jpeg formats are accepted")
												);
											} else {
												return Promise.resolve();
											}
										},
									},
								]}
							>
								<Upload
									maxCount={1}
									accept=".png, .jpg, .jpeg"
									beforeUpload={() => false}
									multiple={false}
									showUploadList={false}
									onChange={(e) => {
										blobToBase64(e.file, (base64) => {
											setcollectionImage(base64);
										});
									}}
								>
									<span className="file-upload-fav-wrapper">
										<Image
											preview={false}
											className="img-upload"
											src={collectionImage || Images.AddImage}
										/>
									</span>
								</Upload>
							</Form.Item>
						</Col>
						<Col span={24} className="create-btn-wrapper">
							<ButtonComponent
								className="create-modal-btn"
								text="Create"
								type="submit"
								isLoading={isLoading}
								disabled={isLoading}
							/>
						</Col>
					</Row>
				</Form>
			</div>
		</Modal>
	);
}

export default CreateCollectionModal;
