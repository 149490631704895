import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";

import "./styles.scss";
import { Images } from "../../theme";
import { useDispatch } from "react-redux";
import { userLoginRequest, userSignUpRequest } from "../../redux/slicers/user";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { BeatLoader } from "react-spinners";
import {
  checkAlphaNumericOnly,
  checkPasswordValidation,
  checkUSPhoneNumber,
  toastAlert,
} from "../../services/utils";
import { ALERT_TYPES } from "../../constants";
const RegisterModal = ({
  handleClose,
  onSubmit,
  title = "",
  description = "",
  callback = () => {},
  fields = () => "",
  handleLogin,
}) => {
  const [loading, setLoading] = useState(false);
  const [RegisterForm] = Form.useForm();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    setLoading(true);
    const values = RegisterForm.getFieldsValue();
    const payload = {
      username: values.email,
      email: values.email,
      password: values.confirmPassword,
      phone_number: values?.phone_number ?? "",
      first_name: values.firstName,
      last_name: values.lastName,
    };

    dispatch(
      userSignUpRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          setLoading(false);

          if (status) {
            toastAlert("Registered Successfully", ALERT_TYPES.success);
            handleClose();
            callback();
          } else {
            toastAlert(res.data?.message, ALERT_TYPES.error);
          }
        },
      })
    );
  };
  return (
    <Modal
      visible={true}
      title={<span>{title}</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
      closeIcon={<img src={Images.CloseIcon} />}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="login-wrapper">
            <h4 className="login-wrapper_title">Register</h4>
            <h6 className="login-wrapper_desc">
              This is only for Pro2col Health members. If you don't already have
              an account, please go to{" "}
              <a href="https://pro2colhealth.com/" target="_blank">
                pro2colhealth.com
              </a>{" "}
              to become a member.
            </h6>
            <Form
              form={RegisterForm}
              validateTrigger="onSubmit"
              onFinish={handleSubmit}
            >
              <Row className="login-rows-wrapper" gutter={[0, 0]}>
                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  className="email-wrapper"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter a Valid Email",
                      },
                    ]}
                    label="Email *"
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      size="large"
                      className="email-wrapper_input"
                      type={"email"}
                      placeholder="username@gmail.com"
                    />
                  </Form.Item>
                </Col>
                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  className="email-wrapper"
                >
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject(
                              new Error("First name is required")
                            );
                          } else if (!checkAlphaNumericOnly(value)) {
                            return Promise.reject(
                              new Error(
                                "First name should not contain special characters"
                              )
                            );
                          } else if (value.length < 4) {
                            return Promise.reject(
                              new Error(
                                "First name should contain at least 4 characters"
                              )
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                    label="First name *"
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      size="large"
                      className="email-wrapper_input"
                      type={"text"}
                      placeholder="First name"
                    />
                  </Form.Item>
                </Col>

                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  className="email-wrapper"
                >
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject(
                              new Error("Last name is required")
                            );
                          } else if (!checkAlphaNumericOnly(value)) {
                            return Promise.reject(
                              new Error(
                                "Last name should not contain special characters"
                              )
                            );
                          } else if (value.length < 4) {
                            return Promise.reject(
                              new Error(
                                "Last name should contain at least 4 characters"
                              )
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                    label="Last name *"
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      size="large"
                      className="email-wrapper_input"
                      type={"text"}
                      placeholder="Last name"
                    />
                  </Form.Item>

                  {/* <Form.Item
                    name="phone_number"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                            // return Promise.reject(
                            // 	new Error(number.errorMessage.required)
                            // );
                          } else if (value && value.includes(" ")) {
                            return Promise.reject(
                              new Error("Cannot accept whitespaces.")
                            );
                          } else if (value && !isValidPhoneNumber(value)) {
                            return Promise.reject(
                              new Error("Invalid phone number.")
                            );
                          } else if (value && isValidPhoneNumber(value)) {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                    label="Phone number (Optional)"
                    labelCol={{ span: 24 }}
                  >
                    <PhoneInput
                      international
                      defaultCountry="US"
                      placeholder="1234567899"
                    /> */}
                  {/* <Input
						size="large"
						className="email-wrapper_input"
						type={"text"}
						placeholder="1234567899"
						prefix={"+1"}
						controls={false}
					/> */}
                  {/* </Form.Item> */}
                </Col>

                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  className="password-wrapper"
                >
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject(
                              new Error("Password is required")
                            );
                          } else if (value && !checkPasswordValidation(value)) {
                            return Promise.reject(
                              new Error(
                                "Password should contain at least 8 characters, 1 Upper case one Lower Case and 1 Special Character!"
                              )
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                    label="Password *"
                    labelCol={{ span: 24 }}
                  >
                    <Input.Password
                      size="large"
                      className="password-wrapper_input"
                      placeholder="Password"
                    />
                  </Form.Item>
                </Col>
                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  className="password-wrapper"
                >
                  <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Re-Enter The Password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                    label="Confirm Password *"
                    labelCol={{ span: 24 }}
                  >
                    <Input.Password
                      size="large"
                      className="password-wrapper_input"
                      placeholder="Confirm Password"
                    />
                  </Form.Item>
                </Col>

                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Button
                    className="btn-login"
                    type="primary"
                    htmlType="submit"
                  >
                    {loading ? (
                      <BeatLoader color="#fff" size="10" />
                    ) : (
                      "Register"
                    )}
                  </Button>
                </Col>
                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="dont-have-acc">
                    <strong>Already Have an account? </strong>
                    <b style={{ cursor: "pointer" }} onClick={handleLogin}>
                      &nbsp; Login Now
                    </b>
                  </span>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        {/* <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button className="submit" onClick={handleSubmit}>
            Yes, confirm
          </button>
        </div> */}
      </div>
    </Modal>
  );
};

export default RegisterModal;
