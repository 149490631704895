import { BASE_URL } from "../config/webService";

export const categoriesManipulator = (res) => {
	const allCategories = [];

	const allIngredients = [];

	for (let item of res) {
		const attributes = item?.attributes;

		let data = {
			id: item?.id,
			name: attributes?.name,
			image: BASE_URL + attributes?.icon?.data?.attributes?.url || "",
			items: [],
		};

		const ingredients = attributes?.ingredients?.data;

		for (let ingredient of ingredients) {
			const ing = {
				name: ingredient?.attributes?.name,
				image: ingredient?.attributes?.image,
				isApprove: ingredient?.attributes?.isApprove,
				id: ingredient?.id,
			};

			if (ing?.isApprove) {
				data.items.push(ing);
				ing.categoryId = item?.id;
				allIngredients.push(ing);
			}
		}

		allCategories.push(data);
	}

	return { allCategories, allIngredients };
};
