import ErrorBackground2 from "../assets/backgrounds/404_bg2.png";
// import HeaderLogo from "../assets/logos/Foodie.svg";
// import HeaderLogo from "../assets/logos/pro2col-logo-2.svg";
import HeaderLogo from "../assets/logos/HeaderLogo-updated.png";
import CloseIcon from "../assets/icons/Cross.svg";
import Heart from "../assets/icons/Heart.svg";
import HeartFilled from "../assets/icons/HeartFilled.svg";
import Share from "../assets/icons/Share.svg";
import RatingStars from "../assets/icons/rating-stars.png";
import User from "../assets/icons/User.svg";
import User1 from "../assets/icons/user1.png";
import User2 from "../assets/icons/user2.png";
import Filters from "../assets/icons/filter.svg";
import FiltersActive from "../assets/icons/filter-active.svg";
import Check from "../assets/icons/Check.svg";
import Food1 from "../assets/images/food1.png";
import Food2 from "../assets/images/food2.png";
import Food3 from "../assets/images/food3.png";
import Food4 from "../assets/images/food4.png";
import Food5 from "../assets/images/food5.png";
import Food6 from "../assets/images/food6.png";
import Food7 from "../assets/images/food7.png";
import Food8 from "../assets/images/food8.png";
import Food9 from "../assets/images/food9.png";
import FoodPop from "../assets/images/FoodPop.png";
import Profile from "../assets/images/profile.png";
import { ReactComponent as DownArrow } from "../assets/icons/Arrow-down.svg";
import { ReactComponent as PantryIcon } from "../assets/icons/bag.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/Search.svg";
import FruitsIcon from "../assets/icons/fruits-veg.svg";
import MeatIcon from "../assets/icons/MeatEggs.svg";
import HerbsSpicesIcon from "../assets/icons/HerbsSpicesIcon.svg";
import Camera from "../assets/icons/camera.svg";
import Back from "../assets/icons/Back.svg";
import Delete from "../assets/icons/delete.svg";
import AddImage from "../assets/icons/addImage.svg";
import DragDots from "../assets/icons/dragIcon.png";
import Edit from "../assets/icons/edit.svg";
import TrashCan from "../assets/icons/trash.svg";
import Search from "../assets/icons/Search.svg";
import Cross from "../assets/icons/cancel.svg";
import Placeholder from "../assets/images/Placeholder.svg";

export default {
  ErrorBackground2,
  HeaderLogo,
  Heart,
  User,
  User1,
  User2,
  DownArrow,
  PantryIcon,
  SearchIcon,
  FruitsIcon,
  CloseIcon,
  Filters,
  Food1,
  Food2,
  Food3,
  Food4,
  Food5,
  Food6,
  Food7,
  Food8,
  Food9,
  FiltersActive,
  MeatIcon,
  HerbsSpicesIcon,
  RatingStars,
  Check,
  Share,
  FoodPop,
  Camera,
  Back,
  HeartFilled,
  Delete,
  AddImage,
  DragDots,
  Edit,
  Profile,
  Search,
  TrashCan,
  Cross,
  Placeholder,
};
