import _ from "lodash";
import moment from "moment";
import { BASE_URL } from "../config/webService";

function getImages(list) {
	try {
		if (_.isEmpty(list)) return [];

		const images = [];

		for (let image of list) {
			const url = image?.attributes?.url
				? BASE_URL + image?.attributes?.url
				: "";
			images.push(url);
		}

		return images;
	} catch (error) {
		return [];
	}
}

export function getCommentsListManipulator(list) {
	try {
		if (_.isEmpty(list)) return [];

		let allComments = [];

		for (let comment of list) {
			const attributes = comment?.attributes;
			const user = attributes?.user;
			const payload = {
				id: comment?.id,
				comment: attributes?.comment_text ?? "",
				recipe_id: attributes?.recipe_id ?? "",
				source: attributes?.source ?? "",
				images:
					attributes?.images?.data?.length > 0
						? getImages(attributes?.images?.data)
						: [],
				created: attributes?.createdAt ?? moment().format(),
				user: {
					id: user?.data?.id,
					...user?.data?.attributes,
					image: user?.data?.attributes?.image
						? BASE_URL + user?.data?.attributes?.image
						: "",
				},
			};

			allComments.push(payload);
		}

		return allComments;
	} catch (error) {
		console.log("error", error);
		return [];
	}
}
