import { Button, Col, Rate, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRecipeCommentsRequest } from "../../redux/slicers/comments";
import { Images } from "../../theme";
import AddRatingModal from "../AddRatingModal";
import AddToCollectionModal from "../AddToCollectionModal";
import CreateCollectionModal from "../CreateCollectionModal";
import LoginModal from "../loginModal";
import CommentBox from "../PopoverCommentBox";
import RegisterModal from "../registerModal";
import "./styles.scss";
function DetailsPopoverContent({
  image,
  title,
  subtitle,
  isFav,
  handleRemoveFav,
  ingredients,
  handleFullRecipe,
  isDisabled,
  id,
  ...props
}) {
  const dispatch = useDispatch();
  const [addToFav, setAddToFav] = useState(false);
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const [addRating, setAddRating] = useState(false);
  const [isCreateCollection, setIsCreateCollection] = useState(false);
  const [ImageError, setImageError] = useState(false);
  const navigate = useNavigate();
  const authenticated = useSelector(({ user }) => user.isAuthenticated);
  const ratings = useSelector((state) => state?.ratings?.ratings);

  const user = useSelector(({ user }) => user?.data?.user);
  const favouriteList = useSelector(
    (state) => state?.collections?.favouriteList
  );

  useEffect(() => {
    recipeComments();
  }, []);

  const recipeComments = () => {
    dispatch(
      getRecipeCommentsRequest({
        payloadData: {
          recipe_id: id,
          source: props?.owner,
        },
        responseCallback: () => {},
      })
    );
  };

  const handleDeleteFavourite = () => {
    dispatch(
      deleteFavouriteRequest({
        payloadData: { favouriteId: isFavourite?.id },
        responseCallback: (status, res) => {
          dispatch(
            getAllCollectionRequest({
              payloadData: {
                userId: user?.id,
              },
            })
          );
        },
      })
    );
  };

  const recipeRatings = ratings?.filter(
    (r) => r?.source === props?.owner && r?.recipeId == `${id}`
  );

  const isFavourite = favouriteList?.find(
    (f) => f?.source === props?.owner && f?.recipe_id === `${id}`
  );

  let rating = 0;

  if (recipeRatings?.length > 0) {
    for (let rate of recipeRatings) {
      rating += rate?.rating;
    }
  }

  rating = recipeRatings?.length > 0 ? rating / recipeRatings?.length : 0;

  return (
    <div className="details-popover-wrapper">
      <Row gutter={[38, 15]}>
        <Col
          lg={{ span: 12 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <img
            src={ImageError ? Images.Placeholder : image}
            onError={() => setImageError(true)}
            placeholder={Images.Placeholder}
            className="main-img"
          />
        </Col>
        <Col
          lg={{ span: 12 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="pop-recipee-details">
            <p>
              <span className="pop-over-title">{title}</span>
              <span className="subtitle">{subtitle}</span>
            </p>
            <img
              src={isFavourite ? Images.HeartFilled : Images.Heart}
              className="popover-heart-icon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (authenticated) {
                  if (isFavourite) {
                    handleDeleteFavourite();
                  } else {
                    setAddToFav(true);
                  }
                } else {
                  setLogin(true);
                }
              }}
            />
          </span>
          {/* <div className="popover-rating">
						<p>
							<span className="title">{rating}</span>
							<span className="subtitle">/5</span>
						</p>
						<p className="rating-text">
							Overall Quality Based on {recipeRatings?.length ?? 0} ratings
						</p>
						<span className="rating-input">
							<Rate disabled value={rating} />
							<p
								style={{ cursor: "pointer" }}
								onClick={() => {
									if (authenticated) {
										setAddRating(true);
									} else {
										setLogin(true);
									}
								}}
							>
								Add Quick Review
							</p>
						</span>
					</div> */}
          <span className="popover-ingredients">
            <h4>Ingredients</h4>
            <ul className="ingredients-list">
              {ingredients?.map((item, i) => (
                <li>
                  <span>
                    {item.text} <img src={Images.Check} />
                  </span>
                </li>
              ))}
            </ul>
          </span>
          <span className="popover-btn">
            <Row gutter={[10]}>
              <Col span={24}>
                <Button
                  type="primary"
                  className={`view-recipe-btn ${
                    isDisabled && "view-recipe-btn-disabled"
                  }`}
                  onClick={isDisabled ? () => {} : handleFullRecipe}
                >
                  View Full Recipe
                </Button>
              </Col>
              {/* <Col span={4}>
								<img
									src={Images.Share}
									style={{ cursor: "pointer", minHeight: "46px" }}
								/>
							</Col> */}
            </Row>
          </span>
        </Col>
        {/* <Col
          lg={{ span: 8 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <CommentBox
            image={image}
            title={title}
            subtitle={subtitle}
            isFav={isFav}
            handleRemoveFav={handleRemoveFav}
            ingredients={ingredients}
            handleFullRecipe={handleFullRecipe}
            isDisabled={isDisabled}
            id={id}
            recipeComments={recipeComments}
            {...props}
          />
        </Col> */}
      </Row>
      {addToFav && (
        <AddToCollectionModal
          title="Add this recipe to a collection"
          handleClose={() => setAddToFav(false)}
          handleCreate={() => {
            setAddToFav(false);
            setIsCreateCollection(true);
          }}
          id={id}
          owner={props.owner}
        />
      )}

      {isCreateCollection && (
        <CreateCollectionModal
          title="Create a New Collection"
          handleClose={() => setIsCreateCollection(false)}
          id={id}
          owner={props.owner}
        />
      )}

      {login && (
        <LoginModal
          handleClose={() => setLogin(false)}
          handleRegister={() => {
            setLogin(false);
            setRegister(true);
          }}
        />
      )}

      {register && (
        <RegisterModal
          handleClose={() => setRegister(false)}
          handleLogin={() => {
            setRegister(false);
            setLogin(true);
          }}
        />
      )}

      {addRating && (
        <AddRatingModal
          image={image}
          title={title}
          subtitle={subtitle}
          isFav={isFav}
          handleRemoveFav={handleRemoveFav}
          ingredients={ingredients}
          handleFullRecipe={handleFullRecipe}
          isDisabled={isDisabled}
          id={id}
          {...props}
          handleClose={() => setAddRating(false)}
        />
      )}
    </div>
  );
}

export default DetailsPopoverContent;
