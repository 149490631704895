import React from "react";
import { FoodieContent, SideBar } from "../../../components";
import "./styles.scss";
const Dashboard = () => {
  return (
    <section className="dashboard">
      <SideBar />
      <FoodieContent />
    </section>
  );
};

export default Dashboard;
