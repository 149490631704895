import _ from "lodash";

export function getRatingManipulator(list) {
	if (_.isEmpty(list)) return [];

	const allRatings = [];

	for (let item of list) {
		const attributes = item?.attributes;
		const payload = {
			id: item?.id || "",
			source: attributes?.source ?? "",
			recipeId: attributes?.recipe_id ?? "",
			rating: attributes?.rating ?? 0,
			userId: attributes?.user?.data?.id ?? "",
		};

		allRatings.push(payload);
	}

	return allRatings;
}
