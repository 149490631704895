// @flow
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  recipes: [],
  filteredRecipes: [],
  myRecipes: [],
  mealFilter: {},
  sidebarMeals: [],
  loadingSidebarMeals: true,
};

const RecipeReducer = createSlice({
  name: "recipe",
  initialState,
  reducers: {
    getRecipesRequest(state) {
      state.recipes = [];
    },
    getSpoonacularRecipesSuccess(state, action) {
      let comingdata = action?.payload;

      state.recipes = [...state.recipes, ...comingdata];
    },
    getEdmamRecipesSuccess(state, action) {
      let items = [...state.recipes];
      let comingdata = action?.payload;
      comingdata.forEach((element) => {
        items.push(element);
      });

      state.recipes = [...state.recipes, ...comingdata];
    },
    getRecipesByIngredientsRequest(state) {
      state.filteredRecipes = [];
    },
    getSpoonacularRecipesByIngredientsSuccess(state, action) {
      let items = [...state.filteredRecipes];
      let newData = [];
      let comingdata = action?.payload;
      comingdata.forEach((element) => {
        // const elementExist = items.includes(element);s
        const isElementExist = items?.find((ele) => ele?.id === element?.id);
        if (_.isEmpty(isElementExist)) {
          newData.push(element);
        }
      });

      state.filteredRecipes = [...state.filteredRecipes, ...comingdata];
    },

    getEdamamRecipeByIngredientSuccess(state, action) {
      state.filteredRecipes = [...state.filteredRecipes, ...action?.payload];
    },

    getStrapiRandomRecipeSuccess(state, action) {
      state.recipes = [...state.recipes, ...action.payload];
    },

    getStrapiFilteredRecipeSuccess(state, action) {
      state.filteredRecipes = [...state.filteredRecipes, ...action.payload];
    },
    clearFilter(state) {
      state.filteredRecipes = [];
    },

    setMealFilter(state, action) {
      state.mealFilter = action?.payload?.payloadData;
    },

    createRecipeRequest() {},
    createRecipeSuccess() {},

    getMyRecipesRequest() {},
    getMyRecipesSuccess(state, action) {
      state.myRecipes = action.payload;
    },

    deleteMyRecipeRequest(state, action) {
      state.myRecipes = state?.myRecipes?.filter(
        (a) => a?.id !== action?.payload?.payloadData?.recipeId
      );
    },

    getSidebarMealsRequest(state) {
      state.sidebarMeals = [];
    },
    getSidebarMealsSuccess(state, action) {
      state.sidebarMeals = action?.payload;
    },
    setLoaderSidebarMeals(state, action) {
      state.loadingSidebarMeals = action.payload;
    },

    createRecipeLinkRequest() {},
  },
});

export const {
  setLoaderSidebarMeals,
  getRecipesRequest,
  getSpoonacularRecipesSuccess,
  getEdmamRecipesSuccess,
  getRecipesByIngredientsRequest,
  getSpoonacularRecipesByIngredientsSuccess,
  clearFilter,
  getEdamamRecipeByIngredientSuccess,
  createRecipeRequest,
  createRecipeSuccess,
  getStrapiRandomRecipeSuccess,
  getStrapiFilteredRecipeSuccess,
  getMyRecipesRequest,
  getMyRecipesSuccess,
  deleteMyRecipeRequest,
  setMealFilter,
  getSidebarMealsRequest,
  getSidebarMealsSuccess,
  createRecipeLinkRequest,
} = RecipeReducer.actions;

export default RecipeReducer.reducer;
