// @flow
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import Immutable from "seamless-immutable";
import { cloneDeepItem } from "../../services/utils";

const UserReducer = createSlice({
	name: "user",
	initialState: Immutable({
		data: {},
		profileSections: [],
		isAuthenticated: false,
		isError: null,
	}),
	reducers: {
		// USER LOGIN
		userLoginRequest(state, action) {},
		userLoginSuccess(state, action) {
			state.isAuthenticated = true;

			state.data = { ...action.payload, access_token: action.payload?.jwt };
		},
		// REFRESH TOKEN
		refreshToken(state, action) {
			let newData = cloneDeepItem(state.data);
			newData.access_token = action.payload.access_token;
			newData.refresh_token = action.payload.refresh_token;
			Immutable.merge(state, { data: newData });
		},

		// SET AUTH ERROR
		setAuthError(state, action) {
			state.isError = action.payload;
		},

		// REMOVE AUTH ERROR
		removeAuthError(state, action) {
			state.isError = null;
		},

		// USER SIGNOUT

		// USER SIGNOUT
		userSignOutRequest(state, action) {
			state.isAuthenticated = false;
		},

		userSignOutSuccess(state, action) {
			state.isAuthenticated = false;
		},
		//USER SIGNUP
		userSignUpRequest(state, action) {},
		userSignUpSuccess(state, action) {
			state.isAuthenticated = true;
			state.data = action.payload;
		},

		updateUserRequest() {},
		updateUserSuccess(state, action) {
			state.data.user = action.payload;
		},

		updateUserInfoRequest() {},

		forgotPasswordRequest() {},

		verificationCodeRequest() {},

		resetPasswordRequest() {},
	},
});

export const {
	userLoginRequest,
	userLoginSuccess,
	refreshToken,
	setAuthError,
	removeAuthError,
	userSignOutRequest,
	userSignOutSuccess,
	userSignUpRequest,
	userSignUpSuccess,
	updateUserRequest,
	updateUserSuccess,
	forgotPasswordRequest,
	verificationCodeRequest,
	resetPasswordRequest,
	updateUserInfoRequest,
} = UserReducer.actions;

export default UserReducer.reducer;
