import { Button, Col, Input, Row, Select } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  DetailsPopoverContent,
  FoodCardComponent,
  Loader,
} from "../../../components";
import { FOOD_CARD, SPOONACULAR_TITLE, EDMAM_TITLE } from "../../../constants";
import {
  getEdamamFavouriteDetailRequest,
  getFoodieFavouriteDetailRequest,
  getSpoonFavouriteDetailRequest,
} from "../../../redux/slicers/collections";
import { Images } from "../../../theme";
import "./styles.scss";

function FavoriteDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isFilter, setIsFilter] = useState(false);
  const [recipeList, setRecipeList] = useState(FOOD_CARD);
  const [selectedDetails, setSelectedDetails] = useState(false);
  const [viewDetailsCard, setviewDetailsCard] = useState(null);
  const [foodCards, setfoodCards] = useState([]);
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(true);

  const [foodieRecipes, setFoodieRecipes] = useState([]);
  const [spoonRecipes, setSpoonRecipes] = useState([]);
  const [edamamRecipes, setEdamamRecipes] = useState([]);

  const collections = useSelector((state) => state?.collections?.collections);

  const collection = collections?.find((item) => item?.id == id);

  const favourites = collection?.favourites;

  const spoonacularRecipes = favourites?.filter(
    (f) => f?.source === SPOONACULAR_TITLE
  );

  useEffect(() => {
    if (favourites?.length > 0) {
      const spoonacularRecipes = favourites
        ?.filter((f) => f?.source === SPOONACULAR_TITLE)
        ?.map((item) => item?.recipe_id);

      const edmamRecipes = favourites
        ?.filter((f) => f?.source === EDMAM_TITLE)
        ?.map((item) => item?.recipe_id);

      const foodieRecipes = favourites
        ?.filter((f) => f?.source === "foodie")
        ?.map((item) => item?.recipe_id);

      const payload = {
        data: {
          spoonacular: spoonacularRecipes,
          edamam: edmamRecipes,
          foodie: foodieRecipes,
        },
      };

      dispatch(
        getSpoonFavouriteDetailRequest({
          payloadData: payload,
          responseCallback: (status, res) => {
            setLoading(false);
            if (status) {
              setSpoonRecipes(res);
            }
          },
        })
      );
      dispatch(
        getFoodieFavouriteDetailRequest({
          payloadData: payload,
          responseCallback: (status, res) => {
            setLoading(false);
            if (status) {
              setFoodieRecipes(res);
            }
          },
        })
      );
      dispatch(
        getEdamamFavouriteDetailRequest({
          payloadData: payload,
          responseCallback: (status, res) => {
            setLoading(false);
            if (status) {
              setEdamamRecipes(res);
            }
          },
        })
      );
    } else if (collection && !favourites?.length) {
      setLoading(false);
    }
  }, [favourites]);

  useEffect(() => {
    setfoodCards([...foodieRecipes, ...spoonRecipes, ...edamamRecipes]);
  }, [foodieRecipes, spoonRecipes, edamamRecipes]);

  const handleViewDetailList = (data, i, n) => {
    setviewDetailsCard(data);
    let mod = (i + 1) % n;

    let newIndex = n - mod < n ? i + 1 + n - mod : i + 1;
    let foodcards = foodCards.slice(0);
    if (foodCards.find((dt) => dt?.display)) {
      let displayIndex = foodCards.findIndex((dt) => dt?.display);
      foodcards.splice(displayIndex, 1);
      setfoodCards(foodcards);
      setviewDetailsCard(null);
      return;
    }

    foodcards.splice(newIndex, 0, { display: data });
    setfoodCards(foodcards);
  };

  const handleFavRemove = (item) => {
    setfoodCards(recipeList.filter((a) => a !== item));
    setRecipeList(recipeList.filter((a) => a !== item));
  };

  const handleChangeSearch = (e) => {
    setSearch(e?.target?.value);
    setviewDetailsCard(null);
    setfoodCards([...foodieRecipes, ...spoonRecipes, ...edamamRecipes]);
  };

  const filteredRecipes = [...foodCards].filter(
    (a) => a?.title?.toLowerCase()?.match(search?.toLowerCase()) || a?.display
  );

  if (loading) {
    return (
      <span className="fav-loader">
        <Loader />
      </span>
    );
  }

  return (
    <div className="fav-details-wrapper">
      <Row gutter={[16, 0]} align="middle" className="top-row">
        <Col
          lg={{ span: 16 }}
          md={{ span: 16 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="title">
            <img src={Images.Back} onClick={() => navigate(-1)} />
            <h4>{collection?.title}</h4>
          </span>
        </Col>
        <Col
          lg={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="content-filters">
            <div className="content-filters_inputWrapper">
              <Input
                placeholder="Search..."
                className="content-filters_inputWrapper-input"
                value={search}
                onChange={(e) => setSearch(e.target?.value)}
              />

              <img src={Images.Search} />
            </div>
          </span>
        </Col>
      </Row>
      {isFilter && (
        <Row gutter={[10, 10]} className="top-row" justify="end">
          <Col
            lg={{ span: 6 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Select
              placeholder="Key Ingredients"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Col>
          <Col
            lg={{ span: 6 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Select
              placeholder="Exclude"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Col>
          <Col
            lg={{ span: 6 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Select
              placeholder="Meal Type"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Col>
          <Col
            lg={{ span: 6 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Select
              placeholder="Cuisines"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Col>
          <Col
            lg={{ span: 6 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Select
              placeholder="Diet"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Col>
          <Col
            lg={{ span: 6 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Select
              placeholder="Max Ingredients"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Col>
          <Col
            lg={{ span: 6 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Select
              placeholder="Rating"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Col>
          <Col
            lg={{ span: 6 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Select
              placeholder="Recipe Time"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Col>
          <Col
            lg={{ span: 6 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            {/* <span cla> */}
            <Button type="primary">Apply Filter</Button>
            {/* </span> */}
          </Col>
        </Row>
      )}

      <Row className="tab-row" gutter={[0, 10]}>
        <Col
          lg={{ span: 20 }}
          md={{ span: 20 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <h3 className="sub-head">{collection?.title}</h3>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        {filteredRecipes?.length > 0 &&
          filteredRecipes?.map((item, i) => (
            <>
              {item?.display === viewDetailsCard ? (
                <Col
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  className="content-popover"
                >
                  <img
                    src={Images.CloseIcon}
                    className="popover-cancel"
                    onClick={() => {
                      handleViewDetailList(item.id, i, 6);
                      setSelectedDetails(undefined);
                    }}
                  />
                  <DetailsPopoverContent
                    isFav={true}
                    image={
                      foodCards?.find((dt) => dt.id === viewDetailsCard)
                        ?.thumbnail
                    }
                    title={
                      foodCards?.find((dt) => dt.id === viewDetailsCard)?.title
                    }
                    subtitle={
                      foodCards?.find((dt) => dt.id === viewDetailsCard)
                        ?.subTitle
                    }
                    owner={
                      foodCards?.find((dt) => dt.id === viewDetailsCard)?.owner
                    }
                    id={foodCards?.find((dt) => dt.id === viewDetailsCard)?.id}
                    handleRemoveFav={() =>
                      handleFavRemove(
                        foodCards?.find((dt) => dt.id === viewDetailsCard)
                      )
                    }
                  />
                </Col>
              ) : (
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 4 }}
                  md={{ span: 8 }}
                  sm={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <span className="responsive-1">
                    <FoodCardComponent
                      title={item.title}
                      subtitle={item.subTitle}
                      handleDetails={() => {
                        handleViewDetailList(item.id, i, 6);
                        setSelectedDetails(item.id);
                      }}
                      isFav
                      handleRemoveFav={() => handleFavRemove(item)}
                      id={item.id}
                      selected={selectedDetails}
                      {...item}
                      image={item.thumbnail}
                    />
                  </span>
                  <span className="responsive-2">
                    <FoodCardComponent
                      title={item.title}
                      subtitle={item.subTitle}
                      handleDetails={() => {
                        handleViewDetailList(item.id, i, 3);
                        setSelectedDetails(item.id);
                      }}
                      id={item.id}
                      handleRemoveFav={() => handleFavRemove(item)}
                      selected={selectedDetails}
                      isFav
                      {...item}
                      image={item.thumbnail}
                    />
                  </span>
                  <span className="responsive-3">
                    <FoodCardComponent
                      title={item.title}
                      subtitle={item.subTitle}
                      handleDetails={() => {
                        navigate(`/recipe-details/${item.id}`);
                      }}
                      id={item.id}
                      selected={selectedDetails}
                      handleRemoveFav={() => handleFavRemove(item)}
                      isFav
                      {...item}
                      image={item.thumbnail}
                    />
                  </span>
                </Col>
              )}
            </>
          ))}
      </Row>
    </div>
  );
}

export default FavoriteDetails;
