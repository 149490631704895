import { Button, Col, Form, Input, Row, Upload } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput,{isValidPhoneNumber} from "react-phone-number-input";
import { Navigate, useNavigate } from "react-router-dom";
import { ButtonComponent } from "../../../components";
import {
	BASE_URL,
	ERROR_SOMETHING_WENT_WRONG,
} from "../../../config/webService";
import {
	ALERT_TYPES,
	HOME_ROUTE,
	PROFILE_IMAGE_FORMATS,
} from "../../../constants";
import { uploadFileRequest } from "../../../redux/slicers/general";
import {
	updateUserInfoRequest,
	updateUserRequest,
} from "../../../redux/slicers/user";
import {
	blobToBase64,
	checkUSPhoneNumber,
	toastAlert,
} from "../../../services/utils";
import { Images } from "../../../theme";
import "./styles.scss";
function MyProfile() {
	const userData = useSelector(({ user }) => user.data.user);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [name, setName] = useState(userData?.username);
	const [profileForm] = Form.useForm();
	const [passwordForm] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [file, setFile] = useState([]);
	const [imageFileSrc, setImageFileSrc] = useState(null);

	console.log({ userData });

	useEffect(() => {
		profileForm.setFieldValue("name", userData?.username);
		profileForm.setFieldValue("email", userData?.email);
		profileForm.setFieldValue("phone_number", userData?.phone_number);
	}, []);

	useEffect(() => {
		if (file?.length > 0) {
			blobToBase64(file[0]?.originFileObj, (base64) => {
				setImageFileSrc(base64);
			});
		}
	}, [file]);

	const onFileUpload = (val) => {
		if (val?.fileList?.length > 0) {
			// setFile(val?.fileList);
			const upFile = val?.fileList[0];
			if (upFile?.name && PROFILE_IMAGE_FORMATS.includes(upFile?.type)) {
				setFile(val?.fileList);
			}
		} else {
			setFile([]);
			setImageFileSrc(null);
		}
	};

	const saveUserData = (val) => {
		const payload = {
			email: userData.email,
			// username: val?.name,
			password: val?.newPassword,
			currentPassword: val?.currentPassword,
			passwordConfirmation: val?.confirmPassword,
			// imageId: val?.imageId,
		};

		dispatch(
			updateUserRequest({
				payloadData: payload,
				responseCallback: (status, res) => {
					if (status) {
						// navigate(HOME_ROUTE);
						passwordForm.resetFields();
						toastAlert("Password updated Successfully.");
					} else {
						toastAlert(
							res?.message ?? ERROR_SOMETHING_WENT_WRONG,
							ALERT_TYPES.error
						);
					}
				},
			})
		);
	};

	const updateUserInfo = (val) => {
		dispatch(
			updateUserInfoRequest({
				payloadData: val,
				responseCallback: (status, res) => {
					if (status) {
						toastAlert("Info Updated Successfully.");
					} else {
						toastAlert(
							res?.message ?? ERROR_SOMETHING_WENT_WRONG,
							ALERT_TYPES.error
						);
					}
				},
			})
		);
	};

	const onFinish = (val) => {
		setIsLoading(true);

		if (file?.length > 0) {
			const formData = new FormData();

			formData.append("files", file[0]?.originFileObj, "userImage");

			dispatch(
				uploadFileRequest({
					payloadData: formData,
					responseCallback: (status, res) => {
						if (status) {
							const imageId = res[0]?.url;
							updateUserInfo({ ...val, imageId });
						} else {
							toastAlert(ERROR_SOMETHING_WENT_WRONG);
							setIsLoading(false);
						}
					},
				})
			);
		} else {
			updateUserInfo(val);
		}
	};

	const image = userData?.image ? BASE_URL + userData.image : Images.Profile;

	return (
		<div className="profile-wrapper">
			<Row gutter={[10, 15]}>
				<Col span={24}>
					<span className="profile-header">
						<img
							src={Images.Back}
							onClick={() => navigate(-1)}
							style={{ cursor: "pointer" }}
						/>
						<h4>My Profile</h4>
					</span>
				</Col>

				<Col span={24} className="profile-pic-col">
					<div className="profile-pic-wrapper">
						<div className="profile-image">
							<img src={imageFileSrc ?? image} />
						</div>
						<Upload
							onChange={onFileUpload}
							multiple={false}
							maxCount={1}
							accept={PROFILE_IMAGE_FORMATS}
							fileList={file}
							beforeUpload={() => false}
						>
							<img
								src={file?.fileList?.[0]?.thumbUrl ?? Images.Edit}
								className="edit-icon"
							/>
						</Upload>
					</div>
				</Col>

				<Col span={24} className="profile-form-col">
					<Form
						layout="vertical"
						className="profile-form"
						form={profileForm}
						onFinish={onFinish}
					>
						<h4>Information</h4>

						<Form.Item
							name="username"
							rules={[
								{
									required: true,
									message: "Please Enter a Name",
								},
							]}
							label="Name"
							initialValue={name}
						>
							<Input />
						</Form.Item>

						<Form.Item label="Email" name={"email"}>
							<Input disabled />
						</Form.Item>

						<Form.Item
							name="phone_number"
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											return Promise.resolve();
											// return Promise.reject(
											// 	new Error(number.errorMessage.required)
											// );
										} else if (value && value.includes(" ")) {
											return Promise.reject(
												new Error("Cannot accept whitespaces.")
											);
										} else if (value && !isValidPhoneNumber(value)) {
											return Promise.reject(new Error("Invalid phone number."));
										} else if (value && isValidPhoneNumber(value)) {
											return Promise.resolve();
										}
									},
								},
							]}
							label="Phone number"
							labelCol={{ span: 24 }}
						>
						<PhoneInput
							international
							defaultCountry="US"
							placeholder="1234567899"
						/>
						</Form.Item>

						<ButtonComponent
							className="profile-btn"
							type="submit"
							text="Save Changes"
						/>
					</Form>
				</Col>

				<Col span={24} className="profile-form-col">
					<div className="form-seperator"></div>

					<Form
						form={passwordForm}
						layout="vertical"
						className="profile-form"
						onFinish={saveUserData}
					>
						<h4>Change Password</h4>

						<Form.Item
							label="Current Password"
							name="currentPassword"
							rules={[
								{
									required: true,
									message: "Please Enter Your Current Password",
								},
							]}
						>
							<Input.Password placeholder="Current Password" />
						</Form.Item>

						<Form.Item
							label="New Password"
							name="newPassword"
							rules={[
								{
									required: true,
									message: "Please Enter New Passsword",
								},
							]}
						>
							<Input.Password placeholder="New Password" />
						</Form.Item>

						<Form.Item
							label="Confirm Password"
							name="confirmPassword"
							rules={[
								{
									required: true,
									message: "Please Re-Enter New Password",
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue("newPassword") === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												"The two passwords that you entered do not match!"
											)
										);
									},
								}),
							]}
						>
							<Input.Password placeholder="Confirm Password" />
						</Form.Item>

						<ButtonComponent
							className="profile-btn"
							type="submit"
							text="Update Password"
						/>
					</Form>
				</Col>
			</Row>
		</div>
	);
}

export default MyProfile;
