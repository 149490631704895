import { Button, Col, Form, Input, Row, Upload } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonComponent, Loader } from "../../../components";
import { ERROR_SOMETHING_WENT_WRONG } from "../../../config/webService";
import { ALERT_TYPES, HOME_ROUTE } from "../../../constants";
import {
	resetPasswordRequest,
	verificationCodeRequest,
} from "../../../redux/slicers/user";
import { checkPasswordValidation, toastAlert } from "../../../services/utils";
import { Images } from "../../../theme";
import "./styles.scss";
function ResetPassword() {
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const [ResetPasswordForm] = Form.useForm();

	const [pageLoading, setPageLoading] = useState(true);
	const [verificationData, setVerificationData] = useState({});
	const [requestLoading, setRequestLoading] = useState(false);
	const [requestCall, setRequestCall] = useState(false);

	useEffect(() => {
		if (requestCall) {
			return;
		}
		dispatch(
			verificationCodeRequest({
				payloadData: { code: params?.code },
				responseCallback: (status, res) => {
					if (status) {
						const data = res?.[0];

						const timeDifference = moment(data?.attributes?.updatedAt).diff(
							moment(),
							"minutes"
						);

						if (data?.attributes?.isExpired || timeDifference > 60) {
							navigate(HOME_ROUTE);
							toastAlert("Token is expired.", ALERT_TYPES.error);
							return;
						}

						setVerificationData(data);

						setPageLoading(false);
					} else {
						navigate(HOME_ROUTE);
						toastAlert("Invalid Token", ALERT_TYPES.error);
					}
				},
			})
		);

		setRequestCall(true);
	}, [[]]);

	const handleFinish = (val) => {
		setRequestLoading(true);
		const payload = {
			...val,
			id: verificationData?.id,
			...verificationData?.attributes,
		};

		dispatch(
			resetPasswordRequest({
				payloadData: payload,
				responseCallback: (status, res) => {
					setRequestLoading(false);
					if (status) {
						toastAlert("Password Reset Successfully.");
						navigate(HOME_ROUTE, { replace: true });
					} else {
						toastAlert(
							res?.message || ERROR_SOMETHING_WENT_WRONG,
							ALERT_TYPES.error
						);
					}
				},
			})
		);
	};

	if (pageLoading) {
		return <Loader />;
	}

	return (
		<div className="reset-password-wrapper">
			<Row gutter={[10, 15]}>
				<Col span={24}>
					<span className="forgot-password-header">
						<img
							src={Images.Back}
							onClick={() => navigate(-1)}
							style={{ cursor: "pointer" }}
						/>
						<h4>Back</h4>
					</span>
				</Col>
				<Col span={24} className="forgot-password-heading-col">
					<h4>Reset Your Password</h4>
					<p>Enter a new password for your account</p>
				</Col>
				<Col span={24} className="profile-form-col">
					<Form
						layout="vertical"
						className="profile-form"
						form={ResetPasswordForm}
						onFinish={handleFinish}
					>
						<Form.Item
							name="newPassword"
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											return Promise.reject(
												new Error("New Password is required")
											);
										} else if (value && !checkPasswordValidation(value)) {
											return Promise.reject(
												new Error(
													"Password should contain at least 8 characters, 1 Upper case one Lower Case and 1 Special Character!"
												)
											);
										} else {
											return Promise.resolve();
										}
									},
								},
							]}
							label="New Password"
						>
							<Input.Password placeholder="New Password" />
						</Form.Item>
						<Form.Item
							name="confirmNewPassword"
							rules={[
								{
									required: true,
									message: "Please Re-Enter The Password",
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue("newPassword") === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												"The two passwords that you entered do not match!"
											)
										);
									},
								}),
							]}
							label="Confirm Password"
						>
							<Input.Password placeholder="Confirm Password" />
						</Form.Item>

						<Form.Item>
							<ButtonComponent
								className="profile-btn"
								text="Reset Password"
								type="submit"
								ripple={false}
								isLoading={requestLoading}
								disabled={requestLoading}
							/>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</div>
	);
}

export default ResetPassword;
