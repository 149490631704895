import React, { useState } from "react";
import { Button, Checkbox, Col, Form, Input, Modal, Row } from "antd";

import "./styles.scss";
import { Images } from "../../theme";
import { useDispatch } from "react-redux";
import { BeatLoader } from "react-spinners";
import { isValidURL, toastAlert } from "../../services/utils";
import { ALERT_TYPES } from "../../constants";
import { ERROR_SOMETHING_WENT_WRONG } from "../../config/webService";
import { createRecipeLinkRequest } from "../../redux/slicers/recipe";

const SuggestRecipe = ({ handleClose, title = "" }) => {
	const [loading, setLoading] = useState(false);
	const [RegisterForm] = Form.useForm();
	const dispatch = useDispatch();

	const handleSubmit = () => {
		setLoading(true);
		const values = RegisterForm.getFieldsValue();
		const payload = {
			data: {
				recipe_link: values.recipe_link,
			},
		};

		dispatch(
			createRecipeLinkRequest({
				payloadData: payload,
				responseCallback: (status, res) => {
					setLoading(false);

					if (status) {
						toastAlert("Recipe added successfully.", ALERT_TYPES.success);
						handleClose();
					} else {
						toastAlert(
							res.data?.message ?? ERROR_SOMETHING_WENT_WRONG,
							ALERT_TYPES.error
						);
					}
				},
			})
		);
	};

	return (
		<Modal
			visible={true}
			title={<span>{title}</span>}
			centered
			onCancel={handleClose}
			footer={null}
			className="modal"
			closeIcon={<img src={Images.CloseIcon} />}
		>
			<div className="modal-wrapper">
				<div className="modal-body">
					<div className="login-wrapper">
						<h4 className="login-wrapper_title">Add your Recipe</h4>
						{/* <h6 className="login-wrapper_desc">
							Join for Free. Unlock your expanded pantry and even more recipe
							details by signing up
						</h6> */}
						<Form
							form={RegisterForm}
							validateTrigger="onSubmit"
							onFinish={handleSubmit}
						>
							<Row className="login-rows-wrapper" gutter={[0, 0]}>
								<Col
									lg={{ span: 24 }}
									xl={{ span: 24 }}
									md={{ span: 24 }}
									sm={{ span: 24 }}
									xs={{ span: 24 }}
									className="email-wrapper"
								>
									<Form.Item
										name="recipe_link"
										rules={[
											{
												validator: (_, value) => {
													if (!value) {
														return Promise.reject(
															new Error("Recipe link is required")
														);
													} else if (!isValidURL(value)) {
														return Promise.reject(new Error("Invalid url."));
													} else {
														return Promise.resolve();
													}
												},
											},
										]}
										label="Recipe Link"
										labelCol={{ span: 24 }}
									>
										<Input
											size="large"
											className="email-wrapper_input"
											type={"text"}
											placeholder="https://www.recipelink.com"
										/>
									</Form.Item>
								</Col>

								<Col
									lg={{ span: 24 }}
									xl={{ span: 24 }}
									md={{ span: 24 }}
									sm={{ span: 24 }}
									xs={{ span: 24 }}
									className="email-wrapper"
								>
									<Form.Item
										name="terms_condition"
										valuePropName="checked"
										rules={[
											{
												validator: (_, value, check) => {
													console.log({ _, value, check });
													if (!value) {
														return Promise.reject(new Error("is required"));
													} else {
														return Promise.resolve();
													}
												},
											},
										]}
										// label="Recipe Link"
										labelCol={{ span: 24 }}
										// labelAlign="left"
									>
										<div>
											<Checkbox />
											<span style={{ marginLeft: "10px" }}>
												Accept Terms & Condition
											</span>
										</div>
									</Form.Item>
								</Col>

								<Col
									lg={{ span: 24 }}
									xl={{ span: 24 }}
									md={{ span: 24 }}
									sm={{ span: 24 }}
									xs={{ span: 24 }}
								>
									<Button
										className="btn-login"
										type="primary"
										htmlType="submit"
									>
										{loading ? <BeatLoader color="#fff" size="10" /> : "Submit"}
									</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
				{/* <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            Close
          </button>
          <button className="submit" onClick={handleSubmit}>
            Yes, confirm
          </button>
        </div> */}
			</div>
		</Modal>
	);
};

export default SuggestRecipe;
