import React from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import "./styles.scss";
import { Images } from "../../theme";
import { useDispatch } from "react-redux";
import { userLoginRequest } from "../../redux/slicers/user";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toastAlert } from "../../services/utils";
import { ALERT_TYPES } from "../../constants";
const LoginModal = ({
  handleClose,
  onSubmit,
  title = "",
  description = "",
  fields = () => "",
  handleRegister,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [LoginForm] = Form.useForm();
  const handleSubmit = () => {
    setLoading(true);
    const values = LoginForm.getFieldsValue();
    const payload = { identifier: values.email, password: values.password };

    dispatch(
      userLoginRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          setLoading(false);

          if (status) {
            toastAlert("Signed in Successfully", ALERT_TYPES.success);

            handleClose();
          } else {
            toastAlert(res?.data?.message, ALERT_TYPES.error);
          }
        },
      })
    );
  };

  return (
    <Modal
      visible={true}
      title={<span>{title}</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal"
      closeIcon={<img src={Images.CloseIcon} />}
    >
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="login-wrapper">
            <h4 className="login-wrapper_title">Login</h4>
            <h6 className="login-wrapper_desc">
              Welcome Back! Login to enhance your experience and create
              delicious food!
            </h6>
            <Form onFinish={handleSubmit} form={LoginForm}>
              <Row className="login-rows-wrapper" gutter={[0, 0]}>
                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  className="email-wrapper"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter a Valid Email",
                      },
                    ]}
                    label="Email *"
                    labelCol={{ span: 24 }}
                  >
                    {/* <h6 className="email-wrapper_label">Email</h6> */}
                    <Input
                      size="large"
                      className="email-wrapper_input"
                      type="email"
                      placeholder="username@gmail.com"
                      // value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  className="password-wrapper"
                >
                  <Form.Item
                    name="password"
                    label="Password *"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter a Password",
                      },
                    ]}
                  >
                    {/* <h6 className="password-wrapper_label">Password</h6> */}
                    <Input.Password
                      size="large"
                      className="password-wrapper_input"
                      // value={password}
                      placeholder="Password"
                      // onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  className="forgot-pass-wrapper"
                >
                  <h6 onClick={() => navigate("/forgot-password")}>
                    Forgot Password?
                  </h6>
                </Col>
                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Form.Item>
                    <Button
                      className="btn-login"
                      type="primary"
                      htmlType="submit"
                    >
                      {loading ? (
                        <BeatLoader size="10" color="#fff" />
                      ) : (
                        "Login"
                      )}
                    </Button>
                  </Form.Item>
                </Col>
                <Col
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="dont-have-acc">
                    <strong>Don’t have an account yet?</strong>
                    <b style={{ cursor: "pointer" }} onClick={handleRegister}>
                      &nbsp;Register Now
                    </b>
                  </span>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
