// @flow
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import Immutable from "seamless-immutable";

const CommentsReducer = createSlice({
	name: "comments",
	initialState: Immutable({
		comments: [],
	}),
	reducers: {
		createCommentRequest() {},
		getRecipeCommentsRequest(state) {
			state.comments = [];
		},

		getRecipeCommentsSuccess(state, action) {
			state.comments = action.payload;
		},
	},
});

export const {
	createCommentRequest,
	getRecipeCommentsRequest,
	getRecipeCommentsSuccess,
} = CommentsReducer.actions;

export default CommentsReducer.reducer;
