import _ from "lodash";
import { BASE_URL } from "../config/webService";

const favoritesManipulator = (list) => {
	if (_.isEmpty(list)) return [];

	const favourites = [];

	for (let item of list) {
		const attributes = item?.attributes;

		const payload = {
			id: item?.id,
			recipe_id: attributes?.recipe_id,
			source: attributes?.source,
		};

		favourites.push(payload);
	}

	return favourites;
};

export function collectionManipulator(list) {
	if (_.isEmpty(list)) return [];

	const collection = [];
	let allFavourites = [];

	for (let item of list) {
		const attributes = item?.attributes;
		const newFavourites = favoritesManipulator(attributes.favourites?.data);

		allFavourites = [...allFavourites, ...newFavourites];

		const payload = {
			id: item?.id,
			title: attributes?.name || "",
			image: attributes?.image?.data?.attributes?.url
				? BASE_URL + attributes?.image?.data?.attributes?.url
				: "",
			favourites: [...newFavourites],
		};

		collection.push(payload);
	}

	return { collection, allFavourites };
}
