import { Button, Col, Input, Radio, Row, Select } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CollectionCard, FoodCardComponent } from "../../../components";
import { COLLECTION_CARDS } from "../../../constants";
import { Images } from "../../../theme";
import "./styles.scss";
function Favorites() {
	const navigate = useNavigate();
	const [isFilter, setIsFilter] = useState(false);
	const [search, setSearch] = useState("");

	const handleDetailsClick = (item) => {
		navigate(`/favorites/${item}`);
	};

	const handleChangeSearch = (e) => {
		setSearch(e?.target?.value);
	};

	const collections = useSelector((state) => state?.collections?.collections);

	const filteredCollections = collections?.filter((a) =>
		a?.title?.toLowerCase()?.match(search?.toLowerCase())
	);

	return (
		<div className="favorites-wrapper">
			<Row gutter={[16, 0]} align="middle" className="top-row">
				<Col
					lg={{ span: 16 }}
					md={{ span: 16 }}
					sm={{ span: 24 }}
					xs={{ span: 24 }}
				>
					<span className="title">
						<img src={Images.Back} onClick={() => navigate(-1)} />
						<h4>Favorite</h4>
					</span>
				</Col>
				<Col
					lg={{ span: 8 }}
					md={{ span: 8 }}
					sm={{ span: 24 }}
					xs={{ span: 24 }}
				>
					<span className="content-filters">
						<div className="content-filters_inputWrapper">
							<Input
								placeholder="Search..."
								className="content-filters_inputWrapper-input"
								value={search}
								onChange={(e) => setSearch(e.target?.value)}
							/>

							<img src={Images.Search} />
						</div>
					</span>
				</Col>
			</Row>
			{isFilter && (
				<Row gutter={[10, 10]} className="top-row" justify="end">
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Key Ingredients"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Exclude"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Meal Type"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Cuisines"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Diet"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Max Ingredients"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Rating"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Recipe Time"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 24 }}
						sm={{ span: 24 }}
						xs={{ span: 24 }}
					>
						<Button type="primary">Apply Filter</Button>
					</Col>
				</Row>
			)}
			<Row gutter={[10, 10]}>
				{filteredCollections?.length > 0 &&
					filteredCollections.map((item, i) => (
						<Col
							lg={{ span: 6 }}
							md={{ span: 8 }}
							sm={{ span: 24 }}
							xs={{ span: 24 }}
						>
							<CollectionCard
								item={item}
								handleDetails={() => handleDetailsClick(item.id)}
							/>
						</Col>
					))}
			</Row>
		</div>
	);
}

export default Favorites;
