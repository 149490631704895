import { take, put, call, fork } from "redux-saga/effects";
import {
	BASE_URL,
	callRequest,
	CREATE_COMMENT,
	GET_RECIPES_COMMENT,
} from "../../config/webService";
import { getCommentsListManipulator } from "../../dataManuplator/comments";
import { toastAlert } from "../../services/utils";
import {
	createCommentRequest,
	getRecipeCommentsRequest,
	getRecipeCommentsSuccess,
} from "../slicers/comments";

function* createComment() {
	while (true) {
		const { payload } = yield take(createCommentRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				CREATE_COMMENT,
				payloadData,
				"",
				"",
				{},
				BASE_URL
			);

			if (response) {
				// yield put(userLoginSuccess(response));
				responseCallback(true, response);
			} else {
				responseCallback(false, response);
			}
		} catch (error) {}
	}
}

function* getRecipeComments() {
	while (true) {
		const { payload } = yield take(getRecipeCommentsRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				GET_RECIPES_COMMENT,
				{},
				"",
				`populate=*&filters[recipe_id][$eq]=${payloadData?.recipe_id}&filters[source][$eq]=${payloadData?.source}`,
				{},
				BASE_URL
			);

			if (response?.data) {
				yield put(
					getRecipeCommentsSuccess(getCommentsListManipulator(response.data))
				);
				responseCallback(true, response);
			} else {
				responseCallback(false, response);
			}
		} catch (error) {}
	}
}

export default function* root() {
	yield fork(createComment);
	yield fork(getRecipeComments);
}
