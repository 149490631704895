import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecipesRequest } from "../../../redux/slicers/recipe";
import { FoodieContent, SideBar } from "../../../components";
import "./styles.scss";
const Home = () => {
	// const dispatch = useDispatch();
	// const recipes = useSelector((state) => state.recipe?.recipes);
	//
	// useEffect(() => {
	//   dispatch(getRecipesRequest((res) => {}));
	// }, []);

	return (
		<section className="home-wrapper">
			<SideBar />
			<FoodieContent />
		</section>
	);
};

export default Home;
