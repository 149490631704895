import { RepeatOneSharp } from "@mui/icons-material";
import { call, fork, put, take, takeEvery } from "redux-saga/effects";
import {
	BASE_URL,
	callRequest,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	UPDATE_USER,
	UPDATE_USER_INFO,
	UPLOAD_FILE,
	USER_LOGIN,
	USER_REGISTER,
	VERIFICATION_CODE,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../services/utils";
import {
	forgotPasswordRequest,
	resetPasswordRequest,
	updateUserInfoRequest,
	updateUserRequest,
	updateUserSuccess,
	userLoginRequest,
	userLoginSuccess,
	userSignUpRequest,
	userSignUpSuccess,
	verificationCodeRequest,
} from "../slicers/user";

function* signupRequest() {
	while (true) {
		const { payload } = yield take(userSignUpRequest.type);
		const { payloadData, responseCallback } = payload;
		try {
			const response = yield call(
				callRequest,
				USER_REGISTER,
				payloadData,
				"",
				"",
				{},
				BASE_URL
			);

			if (response.jwt) {
				yield put(userSignUpSuccess(response));
				responseCallback(true, response);
			} else {
				responseCallback(false, response);
			}
		} catch (err) {
			responseCallback && responseCallback(false, err);
		}
	}
}

function* loginRequest() {
	while (true) {
		const { payload } = yield take(userLoginRequest.type);
		const { payloadData, responseCallback } = payload;
		try {
			const response = yield call(
				callRequest,
				USER_LOGIN,
				payloadData,
				"",
				"",
				{},
				BASE_URL
			);
			if (response.jwt) {
				yield put(userLoginSuccess(response));
				responseCallback(true, response);
			} else {
				responseCallback(false, response);
			}
		} catch (err) {
			responseCallback && responseCallback(false, err);
		}
	}
}

function* updateUser() {
	while (true) {
		const { payload } = yield take(updateUserRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				UPDATE_USER,
				payloadData,
				"",
				"",
				{},
				BASE_URL
			);

			if (response?.email) {
				yield put(updateUserSuccess(response));
				responseCallback(true, response);
			} else {
				responseCallback(false, response?.data);
			}
		} catch (error) {
			responseCallback(false, error);
		}
	}
}

function* forgotPassword() {
	while (true) {
		const { payload } = yield take(forgotPasswordRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				FORGOT_PASSWORD,
				payloadData,
				"",
				"",
				{},
				BASE_URL
			);

			if (response?.status) {
				responseCallback(true, response);
			} else {
				responseCallback(false, response?.data);
			}
		} catch (error) {
			responseCallback(false, error);
		}
	}
}

function* verificationCode() {
	while (true) {
		const { payload } = yield take(verificationCodeRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				VERIFICATION_CODE,
				{},
				"",
				`filters[code][$eq]=${payloadData?.code}`,
				{},
				BASE_URL
			);

			if (!_.isEmpty(response?.data)) {
				responseCallback(true, response?.data);
			} else {
				responseCallback(false, response?.data);
			}
		} catch (error) {
			responseCallback(false, error);
		}
	}
}

function* resetPassword() {
	while (true) {
		const { payload } = yield take(resetPasswordRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				RESET_PASSWORD,
				payloadData,
				"",
				"",
				{},
				BASE_URL
			);

			if (response?.status) {
				responseCallback(true, response);
			} else {
				responseCallback(false, response);
			}
		} catch (error) {
			responseCallback(false, error);
		}
	}
}

// updateUserInfoRequest

function* updateUserInfo() {
	while (true) {
		const { payload } = yield take(updateUserInfoRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				UPDATE_USER_INFO,
				payloadData,
				"",
				"",
				{},
				BASE_URL
			);

			if (!response?.error) {
				yield put(updateUserSuccess(response));
				responseCallback(true, response);
			} else {
				responseCallback(false, response);
			}
		} catch (error) {
			responseCallback(false, error);
		}
	}
}

export default function* root() {
	yield fork(signupRequest);
	yield fork(loginRequest);
	yield fork(updateUser);
	yield fork(forgotPassword);
	yield fork(verificationCode);
	yield fork(resetPassword);
	yield fork(updateUserInfo);
}
