import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getIngredientsCategoriesRequest,
	getIngredientsRequest,
} from "../../redux/slicers/ingredients";
import SideBarIngredients from "../SideBarIngredients";
import SideBarMeals from "../SideBarMeals";
import SideBarSearch from "../SideBarSearch";
import "./styles.scss";
function SideBar() {
	const dispatch = useDispatch();

	const list = useSelector(({ ingredients }) => ingredients.list);
	const loaderSidebarIngs = useSelector(
		(state) => state?.ingredients?.ingredientsLoader
	);

	const loaderSidebarMeals = useSelector(
		(state) => state?.recipe?.loadingSidebarMeals
	);

	useEffect(() => {
		// dispatch(getIngredientsCategoriesRequest((res) => {}));
	}, []);

	return (
		<div className="sidebar-wrapper">
			<SideBarSearch />
			<div className="sidebar-options-wrapper">
				{loaderSidebarIngs || loaderSidebarMeals ? (
					<>
						<Skeleton active />
						<Skeleton active />

						<Skeleton active />
					</>
				) : (
					<>
						<SideBarMeals />
						<SideBarIngredients list={list} />
					</>
				)}
			</div>
		</div>
	);
}

export default SideBar;
