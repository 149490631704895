import { PhotoSizeSelectLargeOutlined } from "@mui/icons-material";
import { Button, Col, Popover, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteFavouriteRequest,
	getAllCollectionRequest,
} from "../../redux/slicers/collections";
import { Images } from "../../theme";
import AddToCollectionModal from "../AddToCollectionModal";
import CreateCollectionModal from "../CreateCollectionModal";
import LoginModal from "../loginModal";
import RegisterModal from "../registerModal";
import "./styles.scss";

function FoodCardComponent({
	title,
	subtitle,
	image,
	handleDetails,
	deleteBtn,
	isFav,
	handleDelete,
	handleRemoveFav,
	id,
	selected,
	hasAllIngredients,
	missingIngredient,
	...props
}) {
	const [favModal, setFavModal] = useState();
	const dispatch = useDispatch();

	const authenticated = useSelector(({ user }) => user.isAuthenticated);
	const user = useSelector(({ user }) => user?.data?.user);
	const favouriteList = useSelector(
		(state) => state?.collections?.favouriteList
	);

	const selectedList = useSelector((state) => state?.ingredients?.selected);

	const [login, setLogin] = useState(false);
	const [register, setRegister] = useState(false);
	const [isCreateCollection, setIsCreateCollection] = useState();
	const [imageError, setImageError] = useState(false);

	const handleAddFav = () => {
		setFavModal(!favModal);
	};

	const handleCreateCollection = () => {
		setIsCreateCollection(!isCreateCollection);
		setFavModal(false);
	};

	const handleDeleteFavourite = () => {
		dispatch(
			deleteFavouriteRequest({
				payloadData: { favouriteId: isFavourite?.id },
				responseCallback: (status, res) => {
					dispatch(
						getAllCollectionRequest({
							payloadData: {
								userId: user?.id,
							},
						})
					);
				},
			})
		);
	};

	const isFavourite = favouriteList?.find(
		(f) => f?.source === props?.owner && f?.recipe_id === `${id}`
	);

	return (
		<div
			className={`food-card-wrapper ${
				selected === id && "food-card-wrapper-selected"
			}`}
		>
			<Row className="info-row" gutter={[10, 5]} align="top">
				<Col span={24}>
					<img
						src={imageError ? Images.Placeholder : image}
						className="food-card-image"
						placeholder={Images.Placeholder}
						onError={() => setImageError(true)}
					/>
				</Col>
				<Col span={20}>
					<span className="food-card-info">
						<h5>{title}</h5>
						<p>{subtitle}</p>
					</span>
				</Col>
				<Col
					span={4}
					style={{
						display: "flex",
						alignItems: "flex-start",
						justifyContent: "flex-end",
					}}
				>
					{isFavourite ? (
						<img
							src={Images.HeartFilled}
							className="card-heart"
							onClick={handleDeleteFavourite}
						/>
					) : (
						<img
							src={Images.Heart}
							className="card-heart"
							onClick={() => {
								if (authenticated) {
									handleAddFav();
								} else {
									setLogin(true);
								}
							}}
						/>
					)}
				</Col>
			</Row>
			{selectedList?.length > 0 && (
				<Row>
					{missingIngredient && (
						<span className="ingredients-missing">
							*{missingIngredient} is missing
						</span>
					)}
				</Row>
			)}
			<Row className="btn-wrapper" gutter={10}>
				<Col span={deleteBtn ? 20 : 24}>
					<Button
						type="primary"
						onClick={handleDetails}
						className="pop-over-btn"
					>
						View Details
					</Button>
				</Col>
				{deleteBtn && (
					<Col span={4} style={{ padding: 0 }}>
						<Button type="primary" className="del-btn" onClick={handleDelete}>
							<img src={Images.Delete} />
						</Button>
					</Col>
				)}
			</Row>
			{favModal && (
				<AddToCollectionModal
					handleClose={handleAddFav}
					title="Add this recipe to a collection"
					handleCreate={handleCreateCollection}
					id={id}
					owner={props.owner}
				/>
			)}
			{isCreateCollection && (
				<CreateCollectionModal
					title="Create a New Collection"
					handleClose={handleCreateCollection}
					id={id}
					owner={props.owner}
				/>
			)}
			{login && (
				<LoginModal
					handleClose={() => setLogin(false)}
					handleRegister={() => {
						setLogin(false);
						setRegister(true);
					}}
				/>
			)}
			{register && (
				<RegisterModal
					handleClose={() => setRegister(false)}
					handleLogin={() => {
						setRegister(false);
						setLogin(true);
					}}
				/>
			)}
		</div>
	);
}

export default FoodCardComponent;
