import { Button, Col, Input, Radio, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	CreateRecipeModal,
	DetailsPopoverContent,
	FoodCardComponent,
	Loader,
} from "../../../components";
import { FOOD_CARD } from "../../../constants";
import {
	deleteMyRecipeRequest,
	getMyRecipesRequest,
} from "../../../redux/slicers/recipe";
import { Images } from "../../../theme";
import "./styles.scss";
function MyRecipe() {
	const dispatch = useDispatch();
	const [isFilter, setIsFilter] = useState(false);
	const navigate = useNavigate();
	const [pending, setPending] = useState();
	const [addModal, setAddModal] = useState(false);
	const [selectedDetails, setSelectedDetails] = useState(false);
	const [viewDetailsCard, setviewDetailsCard] = useState(null);
	const [foodCards, setfoodCards] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [search, setSearch] = useState("");

	const userData = useSelector(({ user }) => user?.data?.user);

	const myRecipes = useSelector((state) => state?.recipe?.myRecipes);

	let filteredFoodCards = pending
		? foodCards?.filter((item) => !item?.isApprove || item?.display)
		: foodCards?.filter((item) => item?.isApprove || item?.display);

	filteredFoodCards = filteredFoodCards?.filter(
		(a) => a?.title?.toLowerCase()?.match(search?.toLowerCase()) || a?.display
	);

	const selectedItem = filteredFoodCards.find(
		(dt) => dt.id === viewDetailsCard
	);

	useEffect(() => {
		dispatch(
			getMyRecipesRequest({
				payloadData: { userId: userData?.id },
				responseCallback: () => {
					setIsLoading(false);
				},
			})
		);
	}, []);

	useEffect(() => {
		setfoodCards(myRecipes);
	}, [myRecipes]);

	const handleTabChange = ({ target: { value } }) => {
		setviewDetailsCard(false);
		setfoodCards(myRecipes);
		if (value === "pending") {
			setPending(true);
		} else {
			setPending(false);
		}
	};
	const handleAddRecipe = () => {
		setAddModal(!addModal);
	};

	const handleViewDetailList = (data, i, n) => {
		setviewDetailsCard(data);
		let mod = (i + 1) % n;

		let newIndex = n - mod < n ? i + 1 + n - mod : i + 1;
		let foodcards = filteredFoodCards?.slice(0);
		if (foodCards.find((dt) => dt?.display)) {
			let displayIndex = foodCards.findIndex((dt) => dt?.display);
			foodcards.splice(displayIndex, 1);
		}

		foodcards.splice(newIndex, 0, { display: data });
		setfoodCards(foodcards);
	};

	const handleRecipeDel = (item) => {
		dispatch(
			deleteMyRecipeRequest({
				payloadData: { recipeId: item?.id },
				responseCallback: (status) => {
					if (status) {
						dispatch(
							getMyRecipesRequest({
								payloadData: { userId: userData?.id },
								responseCallback: (status) => {},
							})
						);
					}
				},
			})
		);
	};

	const handleChange = (e) => {
		setSearch(e?.target?.value);
		setviewDetailsCard(null);
		setfoodCards(myRecipes);
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className="my-recipe-wrapper">
			<Row gutter={[16, 0]} align="middle" className="top-row">
				<Col
					lg={{ span: 16 }}
					md={{ span: 16 }}
					sm={{ span: 24 }}
					xs={{ span: 24 }}
				>
					<span className="title">
						<img src={Images.Back} onClick={() => navigate(-1)} />
						<h4>My Recipe</h4>
					</span>
				</Col>
				<Col
					lg={{ span: 8 }}
					md={{ span: 8 }}
					sm={{ span: 24 }}
					xs={{ span: 24 }}
				>
					<span className="content-filters">
						<div className="content-filters_inputWrapper">
							<Input
								placeholder="Search..."
								className="content-filters_inputWrapper-input"
								value={search}
								onChange={(e) => setSearch(e.target?.value)}
							/>

							<img src={Images.Search} />
						</div>
					</span>
				</Col>
			</Row>
			{isFilter && (
				<Row gutter={[10, 10]} className="top-row" justify="end">
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Key Ingredients"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Exclude"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Meal Type"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Cuisines"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Diet"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Max Ingredients"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Rating"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Recipe Time"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col>
					<Col
						lg={{ span: 6 }}
						md={{ span: 24 }}
						sm={{ span: 24 }}
						xs={{ span: 24 }}
					>
						{/* <span cla> */}
						<Button type="primary">Apply Filter</Button>
						{/* </span> */}
					</Col>
				</Row>
			)}
			<Row className="tab-row" gutter={[10, 10]}>
				<Col
					lg={{ span: 20 }}
					md={{ span: 16 }}
					sm={{ span: 24 }}
					xs={{ span: 24 }}
				>
					<Radio.Group
						value={pending ? "pending" : "approved"}
						buttonStyle="solid"
						onChange={handleTabChange}
					>
						<Radio.Button value="approved">Approved</Radio.Button>
						<Radio.Button value="pending">Pending</Radio.Button>
					</Radio.Group>
				</Col>
				<Col
					style={{ display: "flex", justifyContent: "flex-end" }}
					lg={{ span: 4 }}
					md={{ span: 8 }}
					sm={{ span: 24 }}
					xs={{ span: 24 }}
				>
					<Button
						type="primary"
						style={{ width: "100%" }}
						onClick={handleAddRecipe}
					>
						Create my Recipe
					</Button>
				</Col>
			</Row>
			<Row gutter={[10, 10]}>
				{filteredFoodCards?.length > 0 &&
					filteredFoodCards?.map((item, i) => (
						<>
							{item?.display === viewDetailsCard ? (
								<Col
									lg={{ span: 24 }}
									md={{ span: 24 }}
									sm={{ span: 24 }}
									xs={{ span: 24 }}
									className="content-popover"
								>
									<img
										src={Images.CloseIcon}
										className="popover-cancel"
										onClick={() => {
											handleViewDetailList(item.id, i, 6);
											setviewDetailsCard(null);
										}}
									/>
									<DetailsPopoverContent
										image={
											filteredFoodCards.find((dt) => dt.id === viewDetailsCard)
												?.thumbnail
										}
										title={
											filteredFoodCards.find((dt) => dt.id === viewDetailsCard)
												?.title
										}
										subtitle={
											filteredFoodCards.find((dt) => dt.id === viewDetailsCard)
												?.subTitle
										}
										{...selectedItem}
									/>
								</Col>
							) : (
								<Col
									xl={{ span: 4 }}
									lg={{ span: 4 }}
									md={{ span: 8 }}
									sm={{ span: 12 }}
									xs={{ span: 24 }}
								>
									<span className="responsive-1">
										<FoodCardComponent
											title={item.title}
											subtitle={item.subTitle}
											handleDetails={() => {
												handleViewDetailList(item.id, i, 6);
												setSelectedDetails(item.id);
											}}
											id={item.id}
											selected={selectedDetails}
											deleteBtn={pending}
											handleDelete={() => handleRecipeDel(item)}
											{...item}
											image={item.thumbnail}
										/>
									</span>
									<span className="responsive-2">
										<FoodCardComponent
											title={item.title}
											subtitle={item.subTitle}
											handleDetails={() => {
												handleViewDetailList(item.id, i, 3);
												setSelectedDetails(item.id);
											}}
											id={item.id}
											selected={selectedDetails}
											deleteBtn={pending}
											handleDelete={() => handleRecipeDel(item)}
											{...item}
											image={item.thumbnail}
										/>
									</span>
									<span className="responsive-3">
										<FoodCardComponent
											title={item.title}
											subtitle={item.subTitle}
											handleDetails={() => {
												navigate(`/recipe-details/${item.id}`);
											}}
											id={item.id}
											selected={selectedDetails}
											deleteBtn={pending}
											handleDelete={() => handleRecipeDel(item)}
											{...item}
											image={item.thumbnail}
										/>
									</span>
								</Col>
							)}
						</>
					))}
			</Row>
			{addModal && <CreateRecipeModal handleClose={handleAddRecipe} />}
		</div>
	);
}

export default MyRecipe;
