import { Col, Drawer, Row } from "antd";
import React, { useEffect, useState } from "react";
import { INGREDIENTS_OPTIONS } from "../../constants";
import PantryCards from "../PantryCards";
import Masonry from "@mui/lab/Masonry";

import "./styles.scss";
import { ContactPageOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { ingredDelete, ingredClear } from "../../redux/slicers/ingredients";

function PantryDrawer({ open, handleClose }) {
	const dispatch = useDispatch();

	const [listing, setListing] = useState([]);

	const selected = useSelector(({ ingredients }) => ingredients.selected);
	const allCategories = useSelector((state) => state.ingredients?.list);

	useEffect(() => {
		manageDisplayPantry();
	}, [selected]);

	const manageDisplayPantry = () => {
		const filteredData = _.chain(selected)
			.groupBy("categoryId")
			.map((value, key) => ({ categoryId: key, ingredients: value }))
			.value();

		setListing(filteredData);
	};

	const handleRemoveIngredients = (item) => {
		dispatch(ingredDelete(item));
	};

	const handleClearAllIngredients = () => {
		if (listing?.length > 0) {
			dispatch(ingredClear());
		}
	};

	return (
		<Drawer
			placement="bottom"
			open={open}
			onClose={handleClose}
			closeIcon={false}
			className="pantry-drawer"
		>
			<h4 className="pantry-drawer-heading">My Pantry</h4>

			<div className="clearRow">
				<span
					className={`clearRow_btn ${
						!listing?.length && "clearRow_btn-disabled"
					}`}
					onClick={handleClearAllIngredients}
				>
					Clear all
				</span>
			</div>

			<Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={3}>
				{listing?.map((item, i) => {
					const category = allCategories?.find(
						(c) => c?.id == item?.categoryId
					);

					return (
						<PantryCards
							image={category.image}
							title={category.name}
							list={item?.ingredients}
							closeDrawer={handleClose}
							handleDelete={handleRemoveIngredients}
						/>
					);
				})}
			</Masonry>
		</Drawer>
	);
}

export default PantryDrawer;
