import React from "react";
import { useDispatch } from "react-redux";
import { pantryIngredSelect } from "../../redux/slicers/ingredients";
import { Images } from "../../theme";
import "./styles.scss";
function PantryCards({ image, title, list, closeDrawer, handleDelete }) {
	const dispatch = useDispatch();
	const handleIngredientSelect = (item) => {
		dispatch(pantryIngredSelect(item));
		closeDrawer();
	};

	return (
		<div className="pantry-card-wrapper">
			<div className="card-header">
				<img src={image} alt="" />
				<h4>{title}</h4>
			</div>
			<div className="card-body">
				{list?.map((item, i) => (
					<span className="pantry-items">
						<p>{item.name}</p>
						<span>
							<img
								src={Images.Search}
								alt=""
								onClick={() => handleIngredientSelect(item.name)}
							/>
							<img
								src={Images.TrashCan}
								alt=""
								onClick={() => handleDelete(item.id)}
							/>
						</span>
					</span>
				))}
			</div>
		</div>
	);
}

export default PantryCards;
