import { Button, Col, Form, Input, Row, Steps } from "antd";
import Modal from "antd/lib/modal/Modal";
import _ from "lodash";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ALERT_TYPES, COLLECTION_CARDS } from "../../constants";
import { createIngredientRequest } from "../../redux/slicers/ingredients";
import { toastAlert } from "../../services/utils";
import { Images } from "../../theme";
import "./styles.scss";

function SuggestIngredientsModal({ handleClose }) {
  const { Step } = Steps;
  const dispatch = useDispatch();
  const [step1Form] = Form.useForm();

  const [inputVal, setInputVal] = useState("");
  const [step, setStep] = useState(1);
  const [initLenght, setInitLength] = useState(6);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const categoryList = useSelector((state) => state?.ingredients?.list);
  const ingredientsList = useSelector(
    (state) => state?.ingredients?.ingredients
  );

  const handleClickNext = () => {
    if (step === 3) {
      // handleClose();

      for (let ingredient of suggestions) {
        const payload = {
          data: {
            name: ingredient,
            category: {
              connect: [{ id: selectedMeal?.id }],
            },
          },
        };

        dispatch(
          createIngredientRequest({
            payloadData: payload,
            responseCallback: () => {
              toastAlert("Ingredient suggested successfully.");
              handleClose();
            },
          })
        );
      }
    } else if (step === 1) {
      step1Form.submit();
      if (suggestions.length > 0) {
        setStep(step + 1);
      }
    } else if (step === 2) {
      if (suggestions.length > 0) {
        if (!_.isEmpty(selectedMeal)) {
          setStep(step + 1);
        } else {
          toastAlert("Category is required.", ALERT_TYPES.error);
        }
      }
    }
  };

  const handleAddSuggestion = () => {
    const ingredient = ingredientsList?.find(
      (i) => i?.name?.toLowerCase() === inputVal?.toLowerCase()
    );

    if (
      !suggestions.includes(inputVal) &&
      inputVal !== "" &&
      (_.isEmpty(ingredient) || ingredient === undefined)
    ) {
      setSuggestions([...suggestions, inputVal]);
    } else {
      toastAlert("Ingredient already in list.", ALERT_TYPES.error);
    }
    setInputVal("");
  };

  const handleDeleteSuggestion = (item) => {
    let clone = suggestions.slice(0);
    let index = clone.indexOf(item);
    clone.splice(index, 1);
    setSuggestions(clone);
  };

  const handleStep1 = () => {
    step1Form.resetFields();
  };

  return (
    <Modal
      width={750}
      className="suggest-ingredients-modal"
      visible={true}
      centered
      onCancel={handleClose}
      footer={[
        <Row
          gutter={[40, 10]}
          style={{ width: "100%" }}
          className="suggestion-btn-container"
        >
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Button
              className="back-modal-btn"
              type="primary"
              onClick={() => {
                if (step === 1) {
                  handleClose();
                } else {
                  setStep(step - 1);
                }
              }}
            >
              {step === 1 ? "Cancel" : "Back"}
            </Button>
          </Col>
          <Col lg={{ span: 16 }} xs={{ span: 24 }}>
            <Button className="next-modal-btn" onClick={handleClickNext}>
              Next
            </Button>
          </Col>
        </Row>,
      ]}
      closeIcon={<img src={Images.CloseIcon} />}
    >
      <div className="modal-wrapper">
        <Row align="middle" gutter={[20, 10]} type="flex" justify="center">
          <Col span={24}>
            <span className="title-wrapper">
              <h4 className="title">Suggest Ingredients</h4>
            </span>
          </Col>
          <Col span={16} className="steps-wrapper">
            <Steps current={step - 1} width={400}>
              <Step />
              <Step />
              <Step />
            </Steps>
          </Col>
          <Col span={24} className="suggestion-content-wrapper">
            {step === 1 && (
              <span className="add-ingredient-wrapper">
                <h4>Add new ingredient</h4>
                <p>
                  Help us expand our pantry! What ingredient did we miss from
                  our list?
                </p>
                <Form
                  form={step1Form}
                  onFinish={handleStep1}
                  onPressEnter={() => step1Form.resetFields()}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please Enter At Least One Ingredient",
                      },
                    ]}
                    name="suggestions"
                  >
                    <Input
                      placeholder="Suggest an Ingredient 
											Here"
                      value={inputVal}
                      onChange={(e) => setInputVal(e.target.value)}
                      onPressEnter={handleAddSuggestion}
                      autoFocus
                    />
                  </Form.Item>
                </Form>
              </span>
            )}{" "}
            {step === 2 && (
              <span className="add-ingredient-wrapper">
                <h4>Choose category</h4>
                <p>{`What category would be the best fit for ${suggestions?.join(
                  ", "
                )}?`}</p>
                <span className="meals-options">
                  {categoryList.slice(0, initLenght).map((item, i) => (
                    <div
                      className={`meals-options-items ${
                        selectedMeal?.id == item?.id &&
                        "meals-options-items-active "
                      }`}
                      onClick={() => setSelectedMeal(item)}
                      key={i}
                    >
                      <span>{item.name}</span>
                    </div>
                  ))}
                  {categoryList.length > 6 && (
                    <div
                      className=" meals-options-items meals-options-items-active"
                      onClick={() => {
                        if (initLenght > 6) {
                          setInitLength(6);
                        } else {
                          setInitLength(categoryList.length);
                        }
                      }}
                    >
                      <span>{initLenght > 6 ? "See Less" : "See More"}</span>
                    </div>
                  )}
                </span>
              </span>
            )}
            {step === 3 && (
              <span className="add-ingredient-wrapper">
                <h4>Confirm</h4>
                <p>Please Confirm The Suggestion</p>
                <Row className="confirm-info">
                  <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                    <h4>Ingredient</h4>
                    <ul>
                      {suggestions.map((item) => (
                        <li>
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </Col>

                  <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                    <h4>Category</h4>
                    <ul>
                      {suggestions.map(() => (
                        <li>
                          <span>{selectedMeal.name}</span>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </span>
            )}
          </Col>
          {step === 1 && (
            <Col span={24}>
              <span className="added-suggestion">
                {suggestions.map((item, i) => (
                  <div className="added-item-wrapper">
                    <div className="added-item">
                      <p>{item}</p>
                    </div>
                    <div
                      className="del-icon-wrapper"
                      onClick={() => {
                        handleDeleteSuggestion(item);
                      }}
                    >
                      <img src={Images.Delete} />
                    </div>
                  </div>
                ))}
              </span>
            </Col>
          )}
        </Row>
      </div>
    </Modal>
  );
}

export default SuggestIngredientsModal;
