// @flow
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import Immutable from "seamless-immutable";

const IngredientReducer = createSlice({
  name: "ingredients",
  initialState: Immutable({
    list: [],
    selected: [],
    pantrySelected: "",
    ingredients: [],
    ingredientsLoader: true,
  }),
  reducers: {
    //Select Ingredients
    ingredSelect(state, action) {
      state.selected = [...state.selected, action.payload];
    },
    ingredDelete(state, action) {
      state.selected = state.selected.filter(
        (item) => item?.id !== action.payload
      );
    },
    ingredClear(state, action) {
      state.selected = [];
    },
    pantryIngredSelect(state, action) {
      state.pantrySelected = action.payload;
    },
    getIngredientsCategoriesRequest() {},
    getIngredientsCategoriesSuccess(state, action) {
      state.list = action?.payload?.allCategories;
      state.ingredients = action?.payload?.allIngredients;
      // state.list = action.payload;
    },
    getIngredientsRequest() {},
    getIngredientsSuccess(state, action) {
      // state.ingredients = action.payload;
    },

    setIngsLoader(state, action) {
      state.ingredientsLoader = action.payload;
    },

    createIngredientRequest() {},
    createIngredientSuccess() {},
  },
});

export const {
  setIngsLoader,
  ingredSelect,
  ingredDelete,
  ingredClear,
  pantryIngredSelect,
  getIngredientsCategoriesRequest,
  getIngredientsCategoriesSuccess,
  getIngredientsRequest,
  getIngredientsSuccess,
  createIngredientRequest,
  createIngredientSuccess,
} = IngredientReducer.actions;

export default IngredientReducer.reducer;
