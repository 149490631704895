import { take, put, call, fork } from "redux-saga/effects";
import {
  BASE_URL,
  callRequest,
  GET_CUISINES_FILTER,
  GET_DIETS_FILTER,
  GET_MEALS_FILTER,
} from "../../config/webService";
import { manipulateFilters } from "../../dataManuplator/filters";
import { toastAlert } from "../../services/utils";
import {
  getCuisinesFilterRequest,
  getCuisinesFilterSuccess,
  getDietsFilterRequest,
  getDietsFilterSuccess,
  getMealsFilterRequest,
  getMealsFilterSuccess,
  setMealsLoader,
} from "../slicers/filters";

function* getMealsFilter() {
  while (true) {
    const { payload } = yield take(getMealsFilterRequest.type);

    try {
      const response = yield call(
        callRequest,
        GET_MEALS_FILTER,
        [],
        "",
        "",
        {},
        BASE_URL
      );
      if (response.data) {
        yield put(getMealsFilterSuccess(manipulateFilters(response.data)));
      }
      yield put(setMealsLoader(false));
    } catch (error) {}
  }
}
function* getCuisinesFilter() {
  while (true) {
    const { payload } = yield take(getCuisinesFilterRequest.type);

    try {
      const response = yield call(
        callRequest,
        GET_CUISINES_FILTER,
        [],
        "",
        "",
        {},
        BASE_URL
      );
      if (response.data) {
        yield put(getCuisinesFilterSuccess(manipulateFilters(response.data)));
      }
    } catch (error) {}
  }
}
function* getDietsFilter() {
  while (true) {
    const { payload } = yield take(getDietsFilterRequest.type);

    try {
      const response = yield call(
        callRequest,
        GET_DIETS_FILTER,
        [],
        "",
        "",
        {},
        BASE_URL
      );

      if (response.data) {
        yield put(getDietsFilterSuccess(manipulateFilters(response.data)));
      }
    } catch (error) {}
  }
}

export default function* root() {
  yield fork(getCuisinesFilter);
  yield fork(getMealsFilter);
  yield fork(getDietsFilter);
}
