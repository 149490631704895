import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Error } from "../modules";
import { PAGE_ROUTES, ACCESS_TYPES } from "../constants";
import {
	PrivateSharedLayout,
	AuthSharedLayout,
	PublicSharedLayout,
} from "../sharedLayouts";
import { useDispatch, useSelector } from "react-redux";
import {
	getCuisinesFilterRequest,
	getDietsFilterRequest,
	getMealsFilterRequest,
} from "../redux/slicers/filters";
import {
	getIngredientsCategoriesRequest,
	getIngredientsRequest,
} from "../redux/slicers/ingredients";
import { getAllCollectionRequest } from "../redux/slicers/collections";
import { getRatingsRequest } from "../redux/slicers/ratings";
import { getSidebarMealsRequest } from "../redux/slicers/recipe";

const renderRouteSharedLayout = (title, description, access, component) => (
	<React.Fragment>
		{access === ACCESS_TYPES.AUTH ? (
			<AuthSharedLayout> {component}</AuthSharedLayout>
		) : access === ACCESS_TYPES.PRIVATE ? (
			<PrivateSharedLayout>{component}</PrivateSharedLayout>
		) : (
			<PublicSharedLayout>{component}</PublicSharedLayout>
		)}
	</React.Fragment>
);

const PageRoutes = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const user = useSelector((state) => state?.user);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	useEffect(() => {
		dispatch(getSidebarMealsRequest({ responseCallback: () => {} }));

		dispatch(getMealsFilterRequest());
		dispatch(getDietsFilterRequest());
		dispatch(getCuisinesFilterRequest());
		dispatch(getIngredientsCategoriesRequest((res) => {}));
		dispatch(getRatingsRequest());
	}, []);

	useEffect(() => {
		if (user?.isAuthenticated) {
			dispatch(
				getAllCollectionRequest({
					payloadData: { userId: user.data?.user?.id },
				})
			);
		}
	}, [user?.isAuthenticated]);

	return (
		<>
			<Routes>
				{PAGE_ROUTES.map((item, index) => (
					<Route
						path={item.route}
						element={renderRouteSharedLayout(
							item.title,
							item.description,
							item.access,
							item.component
						)}
						key={index}
					/>
				))}
				<Route path="*" element={<Error />} />
			</Routes>
		</>
	);
};

export default PageRoutes;
