import { take, put, call, fork } from "redux-saga/effects";
import { contactUsForm, uploadFileRequest } from "../slicers/general";
import { SAGA_ALERT_TIMEOUT, ALERT_TYPES } from "../../constants";
import {
  BASE_URL,
  callRequest,
  CONTACT_US,
  UPLOAD_FILE,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";

function* contactUs() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(contactUsForm.type);

    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CONTACT_US,
        payloadData,
        "",
        "",
        {}
      );
      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.error);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* uploadFile() {
  while (true) {
    const { payload } = yield take(uploadFileRequest.type);
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        UPLOAD_FILE,
        payloadData,
        "",
        "",
        {},
        BASE_URL
      );

      if (response) {
        // yield put(userLoginSuccess(response));
        responseCallback(true, response);
      } else {
        responseCallback(false, response);
      }
    } catch (error) {
      responseCallback(false, error);
    }
  }
}

export default function* root() {
  yield fork(contactUs);
  yield fork(uploadFile);
}
