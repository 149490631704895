// @flow
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import Immutable from "seamless-immutable";

const FiltersReducer = createSlice({
  name: "filters",
  initialState: Immutable({
    meals: [],
    mealsLoading: true,
    cuisines: [],
    diet: [],
  }),
  reducers: {
    //MEALS
    getMealsFilterRequest() {},
    getMealsFilterSuccess(state, action) {
      state.meals = action.payload;
    },

    setMealsLoader(state, action) {
      state.mealsLoading = action.payload;
    },

    //CUISINES
    getCuisinesFilterRequest() {},
    getCuisinesFilterSuccess(state, action) {
      state.cuisines = action.payload;
    },
    //DIET
    getDietsFilterRequest() {},
    getDietsFilterSuccess(state, action) {
      state.diet = action.payload;
    },
  },
});

export const {
  setMealsLoader,
  getCuisinesFilterRequest,
  getCuisinesFilterSuccess,
  getDietsFilterRequest,
  getDietsFilterSuccess,
  getMealsFilterRequest,
  getMealsFilterSuccess,
} = FiltersReducer.actions;

export default FiltersReducer.reducer;
