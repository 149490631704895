import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";
function IngredientsCard({
	image,
	title,
	items,
	handleIng,
	selectedIng,
	...props
}) {
	let selectedItems = [];
	const [initLenght, setInitLength] = useState(7);
	const selected = useSelector(({ ingredients }) => ingredients.selected);

	const allKeys = [...items].map((a) => a?.id);

	const selectedElements = selected?.filter((a) => allKeys.includes(a?.id));

	return (
		<div className="ingredient-card-wrapper">
			<div className="card-top">
				<img src={image} />
				<div className="top-info">
					<h4>{title}</h4>
					<p>
						{selectedElements.length || 0}/{items?.length} Ingredients
					</p>
				</div>
			</div>
			<div className="item-options">
				{items?.slice(0, initLenght).map((item, i) => (
					<div
						key={i}
						className={`item-options-items ${
							selected.includes(item) && "item-options-items-active"
						}`}
						onClick={() => handleIng(item)}
					>
						<span> {item.name}</span>
					</div>
				))}
				{items?.length !== initLenght && (
					<div
						className="item-options-items"
						onClick={() => setInitLength(items?.length)}
					>
						<span>+{items?.length - initLenght}</span>{" "}
					</div>
				)}
				{initLenght === items?.length && (
					<div
						className="item-options-items item-options-items-active"
						onClick={() => setInitLength(7)}
					>
						<span>See Less</span>{" "}
					</div>
				)}
			</div>
		</div>
	);
}

export default IngredientsCard;
