import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Header, Footer } from "../../components";
import { DASHBOARD_ROUTE } from "../../constants";

function PublicSharedLayout({ children }) {
	const isAuthenticated = useSelector((state) => state?.user?.isAuthenticated);
	const navigate = useNavigate();

	// useEffect(() => {
	//   if (isAuthenticated) {
	//     navigate(DASHBOARD_ROUTE);
	//   }
	// }, [isAuthenticated]);
	return (
		<section>
			<Header />
			{children}
		</section>
	);
}

export default PublicSharedLayout;
