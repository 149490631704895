export const manipulateFiltersData = (data) => {
  let filtersData = {};

  filtersData["label"] = data?.attributes?.name ?? "";
  filtersData["value"] = data?.id ?? 0;
  filtersData["id"] = data?.id ?? 0;
  filtersData["spoonacular"] = data?.attributes?.spoonacular ?? "";
  filtersData["edamam"] = data?.attributes?.edamam ?? "";

  return filtersData;
};

export const manipulateFilters = (data) => {
  let filters = [];

  data.forEach((item, index) => {
    filters.push(manipulateFiltersData(item));
  });

  return filters;
};
