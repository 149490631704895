import { take, put, call, fork } from "redux-saga/effects";
import { contactUsForm, uploadFileRequest } from "../slicers/general";
import { SAGA_ALERT_TIMEOUT, ALERT_TYPES } from "../../constants";
import {
	BASE_URL,
	callRequest,
	CREATE_COLLECTION,
	CREATE_FAVOURITE,
	DELETE_FAVOURITE,
	GET_ALL_COLLECTIONS,
	GET_EDAMAM_FAVOURITE_RECIPE_DETAIL,
	GET_FOODIE_FAVOURITE_RECIPE_DETAIL,
	GET_SPOONACULAR_FAVOURITE_RECIPE_DETAIL,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";
import {
	createCollectionRequest,
	createFavouriteRequest,
	deleteFavouriteRequest,
	deleteFavouriteSuccess,
	getAllCollectionRequest,
	getAllCollectionSuccess,
	getSpoonFavouriteDetailRequest,
	getFoodieFavouriteDetailRequest,
	getEdamamFavouriteDetailRequest,
} from "../slicers/collections";
import { collectionManipulator } from "../../dataManuplator/collection";
import { getEdmamRecipeListManipulator } from "../../dataManuplator/recipe";
import { getSpoonacularRecipeListManipulator } from "../../dataManuplator/recipe";
import { manipulatorGetStrapiRandomRecipes } from "../../dataManuplator/recipe";

function* createCollection() {
	while (true) {
		const { payload } = yield take(createCollectionRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				CREATE_COLLECTION,
				payloadData,
				"",
				"",
				{},
				BASE_URL
			);

			if (response.data) {
				// yield put(userLoginSuccess(response));
				responseCallback && responseCallback(true, response.data);
			} else {
				responseCallback(false, response);
			}
		} catch (error) {
			responseCallback && responseCallback(false, error);
		}
	}
}

function* createFavourite() {
	while (true) {
		const { payload } = yield take(createFavouriteRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				CREATE_FAVOURITE,
				payloadData,
				"",
				"",
				{},
				BASE_URL
			);

			if (response.data) {
				// yield put(userLoginSuccess(response));
				responseCallback && responseCallback(true, response.data);
			} else {
				responseCallback && responseCallback(false, response);
			}
		} catch (error) {
			responseCallback && responseCallback(false, error);
		}
	}
}

function* getAllCollections() {
	while (true) {
		const { payload } = yield take(getAllCollectionRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				GET_ALL_COLLECTIONS,
				{},
				"",
				`populate=*&filters[user][id][$eq]=${payloadData?.userId}`,
				{},
				BASE_URL
			);

			if (response?.data) {
				yield put(
					getAllCollectionSuccess(collectionManipulator(response?.data))
				);
				responseCallback && responseCallback(true, response.data);
			} else {
				responseCallback && responseCallback(false, response);
			}
		} catch (error) {
			responseCallback && responseCallback(false, error);
		}
	}
}

function* deleteFavourite() {
	while (true) {
		const { payload } = yield take(deleteFavouriteRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				DELETE_FAVOURITE,
				{},
				`${payloadData?.favouriteId}`,
				``,
				{},
				BASE_URL
			);

			if (response?.data) {
				yield put(deleteFavouriteSuccess(response?.data));
				responseCallback && responseCallback(true, response.data);
			} else {
				toastAlert("Favourite Remove Request Failed", ALERT_TYPES.error);
			}
		} catch (error) {
			responseCallback && responseCallback(false, error);
			toastAlert("Favourite Remove Request Failed", ALERT_TYPES.error);
		}
	}
}

function* favouriteDetailSpoon() {
	while (true) {
		const { payload } = yield take(getSpoonFavouriteDetailRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				GET_SPOONACULAR_FAVOURITE_RECIPE_DETAIL,
				payloadData,
				``,
				``,
				{},
				BASE_URL
			);

			if (response?.length > 0) {
				responseCallback &&
					responseCallback(true, getSpoonacularRecipeListManipulator(response));
			} else {
				responseCallback && responseCallback(false);
			}
		} catch (error) {
			responseCallback && responseCallback(false);
		}
	}
}

function* favouriteDetailFoodie() {
	while (true) {
		const { payload } = yield take(getFoodieFavouriteDetailRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				GET_FOODIE_FAVOURITE_RECIPE_DETAIL,
				payloadData,
				``,
				``,
				{},
				BASE_URL
			);

			if (response?.length > 0) {
				responseCallback &&
					responseCallback(true, manipulatorGetStrapiRandomRecipes(response));
			} else {
				responseCallback && responseCallback(false);
			}
		} catch (error) {
			responseCallback && responseCallback(false);
		}
	}
}

function* favouriteDetailEdamam() {
	while (true) {
		const { payload } = yield take(getEdamamFavouriteDetailRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				GET_EDAMAM_FAVOURITE_RECIPE_DETAIL,
				payloadData,
				``,
				``,
				{},
				BASE_URL
			);

			if (response?.length > 0) {
				responseCallback &&
					responseCallback(true, getEdmamRecipeListManipulator(response));
			} else {
				responseCallback && responseCallback(false);
			}
		} catch (error) {
			responseCallback && responseCallback(false);
		}
	}
}

export default function* root() {
	yield fork(createCollection);
	yield fork(createFavourite);
	yield fork(getAllCollections);
	yield fork(deleteFavourite);
	yield fork(favouriteDetailSpoon);
	yield fork(favouriteDetailFoodie);
	yield fork(favouriteDetailEdamam);
}
