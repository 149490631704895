import { Button, Col, Image, Row } from "antd";
import React, { useState } from "react";
import { Images } from "../../theme";

import "./styles.scss";

function CollectionCard({ item, handleDetails }) {
  const [isImageError, setIsImageError] = useState(false);

  return (
    <div className="collection-card-wrapper">
      <Row gutter={[10, 10]}>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Image
            width={120}
            height={120}
            preview={false}
            src={isImageError ? Images.Placeholder : item?.image}
            style={{ width: "100%" }}
            onError={() => setIsImageError(true)}
          />
        </Col>
      </Row>
      <Row className="info-row" gutter={10}>
        <Col span={24}>
          <span className="food-card-info">
            <h5>{item?.title}</h5>
          </span>
        </Col>
      </Row>
      <Row className="btn-wrapper">
        <Col span={24}>
          <Button type="primary" onClick={handleDetails}>
            View Details
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CollectionCard;
