import {
	Login,
	Home,
	Dashboard,
	MyRecipe,
	MyProfile,
	FavoriteDetails,
	RecipeDetails,
	ForgotPassword,
	ResetPassword,
} from "../modules";
import Favorites from "../modules/private/Favorites";
import { Images } from "../theme";

export const ALERT_TIMEOUT = 3000;
export const DEV_ENV = "dev";
export const PROD_ENV = "prod";
export const API_LOG = process.env.REACT_APP_ENV === DEV_ENV;
export const API_TIMEOUT = 50000;

export const EDMAM_TITLE = "edamam";
export const SPOONACULAR_TITLE = "spoonacular";

export const ERROR_MESSAGES = {
	INTERNET_ERROR: "Please connect to the working internet",
	SESSION_EXPIRED_ERROR: "Session expired, Please login again",
	SOMETHING_WRONG: "Something went wrong",
	FAILED_TO_FETCH: "Failed to fetch, try to refresh the page",
};

export const FILE_FORMATS_COLLECTION = ["png", "jpg", "jpeg"];

export const SUCCESS_MESSAGES = {
	CONTACTUS_FORM: "Form Submitted Successfully",
	LOGIN: "Login Successfully",
	LOGOUT: "Logout Successfully",
	APPOINMENT_FORM: "Request send successfully",
	CANCEL_APPOINMENT: "Appoinment cancelled successfully",
	CREATE_PATIENT: "Patient Created Successfully",
	UPDATE_PATIENT: "Patient Updated Successfully",
	DELETE_PATIENT: "Patient Deleted Successfully",
	APPOINMENT_REVIEW: "Review Submitted Successfully",
};
export const ACCESS_TYPES = {
	AUTH: "auth",
	PRIVATE: "private",
	PUBLIC: "public",
};
export const ALERT_POSITIONS = {
	topRight: "top-right",
	topLeft: "top-left",
	topCenter: "top-center",
	bottomRight: "bottom-right",
	bottomLeft: "bottom-left",
	bottomCenter: "bottom-center",
};
export const ALERT_THEMES = {
	dark: "dark",
	colored: "colored",
	light: "light",
};
export const ALERT_TYPES = {
	info: "info",
	success: "success",
	error: "error",
	warning: "warning",
	default: "default",
};
// PUBLIC ROUTES
export const HOME_ROUTE = "/";
// AUTH ROUTES
export const lOGIN_ROUTE = "/login";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const RESET_PASSWORD_ROUTE = "/reset-password/:code";
// PRIVATE ROUTES
export const DASHBOARD_ROUTE = "/dashboard";
export const MY_RECIPE_ROUTE = "/my-recipe";
export const FAVORITE_ROUTE = "/favorites";
export const PROFILE_ROUTE = "/profile";
export const FAVORITE_DETAILS_ROUTE = "/favorites/:id";
export const RECIPE_DETAILS_ROUTE = "/recipe-details/:id";

export const PAGE_ROUTES = [
	// PUBLIC ROUTES
	{
		route: HOME_ROUTE,
		title: "Unlock More Recipes",
		access: ACCESS_TYPES.PUBLIC,
		component: <Home />,
	},
	// AUTH ROUTES
	{
		route: FORGOT_PASSWORD_ROUTE,
		title: "Forgot Password",
		description: "",
		access: ACCESS_TYPES.AUTH,
		component: <ForgotPassword />,
	},
	{
		route: RESET_PASSWORD_ROUTE,
		title: "Reset Password",
		description: "",
		access: ACCESS_TYPES.AUTH,
		component: <ResetPassword />,
	},
	// PRIVATE ROUTE
	{
		route: DASHBOARD_ROUTE,
		title: "Dashboard",
		description: "",
		access: ACCESS_TYPES.PRIVATE,
		component: <Dashboard />,
	},
	{
		route: MY_RECIPE_ROUTE,
		title: "My Recipes",
		description: "",
		access: ACCESS_TYPES.PRIVATE,
		component: <MyRecipe />,
	},
	{
		route: FAVORITE_ROUTE,
		title: "Favorites",
		description: "",
		access: ACCESS_TYPES.PRIVATE,
		component: <Favorites />,
	},
	{
		route: PROFILE_ROUTE,
		title: "My Profile",
		description: "",
		access: ACCESS_TYPES.PRIVATE,
		component: <MyProfile />,
	},
	{
		route: FAVORITE_DETAILS_ROUTE,
		title: "Favorite Details",
		description: "",
		access: ACCESS_TYPES.PRIVATE,
		component: <FavoriteDetails />,
	},
	{
		route: RECIPE_DETAILS_ROUTE,
		title: "Recipe Details",
		description: "",
		access: ACCESS_TYPES.PUBLIC,
		component: <RecipeDetails />,
	},
];
export const WEB_STRINGS = {
	ErrorPage: {
		title: "404",
		subtitle: "Oops! Page not found",
		description:
			"The page you are looking was doesn't exsist. You may have mistyped the address or the page may have been moved",
		button: "Back to Home",
	},
};
export const FOOD_CARD = [
	{
		id: 1,
		title: "Microwave 1-Minute Ham & Egg Breakfast Bowl",
		subTitle: "wholefully.com",
		image: Images.Food1,
	},
	{
		id: 2,
		title: "Old Fashioned Soft Boiled Egg Breakfast",
		subTitle: "wholefully.com",
		image: Images.Food2,
	},
	{
		id: 3,
		title: "Paleo Breakfast Egg Wraps (Vegetarian Meal Prep Recipe)",
		subTitle: "wholefully.com",
		image: Images.Food3,
	},
	{
		id: 4,
		title: "Sausage and Cheese Balls Breakfast Scramble",
		subTitle: "wholefully.com",
		image: Images.Food4,
	},
	{
		id: 5,
		title: "Breakfast Stuffed Peppers Low Carb, Gluten-Free",
		subTitle: "wholefully.com",
		image: Images.Food5,
	},
	{
		id: 6,
		title: "Avocado Breakfast Bake A delicious breakfast",
		subTitle: "wholefully.com",
		image: Images.Food6,
	},
	{
		id: 7,
		title: "3-Ingredient Bacon and Egg Breakfast Muffins",
		subTitle: "wholefully.com",
		image: Images.Food7,
	},
	{
		id: 8,
		title: "Egg White Breakfast Cups (Under 50 Calories)",
		subTitle: "wholefully.com",
		image: Images.Food8,
	},
	{
		id: 9,
		title: "Microwave 1-Minute Ham & Egg Breakfast Bowl",
		subTitle: "wholefully.com",
		image: Images.Food4,
	},
	{
		id: 10,
		title: "Microwave 1-Minute Ham & Egg Breakfast Bowl",
		subTitle: "wholefully.com",
		image: Images.Food4,
	},
	{
		id: 11,
		title: "Microwave 1-Minute Ham & Egg Breakfast Bowl",
		subTitle: "wholefully.com",
		image: Images.Food4,
	},
	{
		id: 12,
		title: "Microwave 1-Minute Ham & Egg Breakfast Bowl",
		subTitle: "wholefully.com",
		image: Images.Food4,
	},
	{
		id: 13,
		title: "Microwave 1-Minute Ham & Egg Breakfast Bowl",
		subTitle: "wholefully.com",
		image: Images.Food4,
	},
	{
		id: 14,
		title: "Microwave 1-Minute Ham & Egg Breakfast Bowl",
		subTitle: "wholefully.com",
		image: Images.Food4,
	},
	{
		id: 15,
		title: "Microwave 1-Minute Ham & Egg Breakfast Bowl",
		subTitle: "wholefully.com",
		image: Images.Food4,
	},
	{
		id: 16,
		title: "Microwave 1-Minute Ham & Egg Breakfast Bowl",
		subTitle: "wholefully.com",
		image: Images.Food4,
	},
	{
		id: 17,
		title: "Microwave 1-Minute Ham & Egg Breakfast Bowl",
		subTitle: "wholefully.com",
		image: Images.Food4,
	},
];
export const COLLECTION_CARDS = [
	{
		title: "Break-fast",
		image: [Images.Food1, Images.Food2, Images.Food3],
	},
	{
		title: "Lunch",
		image: [Images.Food4, Images.Food5, Images.Food6],
	},
	{
		title: "Dinner",
		image: [Images.Food7, Images.Food8, Images.Food1],
	},
	{
		title: "Tea Time",
		image: [Images.Food2, Images.Food3, Images.Food4],
	},
	{
		title: "Break-fast",
		image: [Images.Food1, Images.Food2, Images.Food3],
	},
	{
		title: "Lunch",
		image: [Images.Food4, Images.Food5, Images.Food6],
	},
	{
		title: "Dinner",
		image: [Images.Food7, Images.Food8, Images.Food1],
	},
	{
		title: "Tea Time",
		image: [Images.Food2, Images.Food3, Images.Food4],
	},
	{
		title: "Break-fast",
		image: [Images.Food1, Images.Food2, Images.Food3],
	},
	{
		title: "Lunch",
		image: [Images.Food4, Images.Food5, Images.Food6],
	},
	{
		title: "Dinner",
		image: [Images.Food7, Images.Food8, Images.Food1],
	},
	{
		title: "Tea Time",
		image: [Images.Food2, Images.Food3, Images.Food4],
	},
];
export const INGREDIENTS_OPTIONS = [
	{
		name: "Fruits and Vegetables",
		image: Images.FruitsIcon,
		items: [
			{ name: "Avocados", id: 1 },
			{ name: "Poultry", id: 2 },
			{ name: "Plain Greek yogurt", id: 3 },
			{ name: "Fish", id: 4 },
		],
	},
	{
		name: "Meat, Eggs, & Dairy",
		image: Images.MeatIcon,
		items: [
			{ name: "Beef", id: 13 },
			{ name: "Lamb", id: 14 },
			{ name: "Chicken", id: 15 },
			{ name: "Kale", id: 16 },
			{ name: "Spinach", id: 17 },
			{ name: "Broccoli", id: 18 },
		],
	},
	{
		name: "Herbs and Spices",
		image: Images.HerbsSpicesIcon,
		items: [
			{ name: "Apple", id: 25 },
			{ name: "Grapes", id: 26 },
			{ name: "Cabbage", id: 27 },
		],
	},
	{
		name: "Fruits and Vegetables",
		image: Images.FruitsIcon,
		items: [
			{ name: "Avocados", id: 1 },
			{ name: "Poultry", id: 2 },
			{ name: "Plain Greek yogurt", id: 3 },
			{ name: "Fish", id: 4 },
		],
	},
	{
		name: "Meat, Eggs, & Dairy",
		image: Images.MeatIcon,
		items: [
			{ name: "Beef", id: 13 },
			{ name: "Lamb", id: 14 },
			{ name: "Chicken", id: 15 },
			{ name: "Kale", id: 16 },
			{ name: "Spinach", id: 17 },
			{ name: "Broccoli", id: 18 },
		],
	},
	{
		name: "Herbs and Spices",
		image: Images.HerbsSpicesIcon,
		items: [
			{ name: "Apple", id: 25 },
			{ name: "Grapes", id: 26 },
			{ name: "Cabbage", id: 27 },
		],
	},
	{
		name: "Fruits and Vegetables",
		image: Images.FruitsIcon,
		items: [
			{ name: "Avocados", id: 1 },
			{ name: "Poultry", id: 2 },
			{ name: "Plain Greek yogurt", id: 3 },
			{ name: "Fish", id: 4 },
		],
	},
	{
		name: "Meat, Eggs, & Dairy",
		image: Images.MeatIcon,
		items: [
			{ name: "Beef", id: 13 },
			{ name: "Lamb", id: 14 },
			{ name: "Chicken", id: 15 },
			{ name: "Kale", id: 16 },
			{ name: "Spinach", id: 17 },
			{ name: "Broccoli", id: 18 },
		],
	},
	{
		name: "Herbs and Spices",
		image: Images.HerbsSpicesIcon,
		items: [
			{ name: "Apple", id: 25 },
			{ name: "Grapes", id: 26 },
			{ name: "Cabbage", id: 27 },
		],
	},
	{
		name: "Fruits and Vegetables",
		image: Images.FruitsIcon,
		items: [
			{ name: "Avocados", id: 1 },
			{ name: "Poultry", id: 2 },
			{ name: "Plain Greek yogurt", id: 3 },
			{ name: "Fish", id: 4 },
		],
	},
	{
		name: "Meat, Eggs, & Dairy",
		image: Images.MeatIcon,
		items: [
			{ name: "Beef", id: 13 },
			{ name: "Lamb", id: 14 },
			{ name: "Chicken", id: 15 },
			{ name: "Kale", id: 16 },
			{ name: "Spinach", id: 17 },
			{ name: "Broccoli", id: 18 },
		],
	},
	{
		name: "Herbs and Spices",
		image: Images.HerbsSpicesIcon,
		items: [
			{ name: "Apple", id: 25 },
			{ name: "Grapes", id: 26 },
			{ name: "Cabbage", id: 27 },
		],
	},
	{
		name: "Fruits and Vegetables",
		image: Images.FruitsIcon,
		items: [
			{ name: "Avocados", id: 1 },
			{ name: "Poultry", id: 2 },
			{ name: "Plain Greek yogurt", id: 3 },
			{ name: "Fish", id: 4 },
		],
	},
	{
		name: "Meat, Eggs, & Dairy",
		image: Images.MeatIcon,
		items: [
			{ name: "Beef", id: 13 },
			{ name: "Lamb", id: 14 },
			{ name: "Chicken", id: 15 },
			{ name: "Kale", id: 16 },
			{ name: "Spinach", id: 17 },
			{ name: "Broccoli", id: 18 },
		],
	},
	{
		name: "Herbs and Spices",
		image: Images.HerbsSpicesIcon,
		items: [
			{ name: "Apple", id: 25 },
			{ name: "Grapes", id: 26 },
			{ name: "Cabbage", id: 27 },
		],
	},
	{
		name: "Fruits and Vegetables",
		image: Images.FruitsIcon,
		items: [
			{ name: "Avocados", id: 1 },
			{ name: "Poultry", id: 2 },
			{ name: "Plain Greek yogurt", id: 3 },
			{ name: "Fish", id: 4 },
		],
	},
	{
		name: "Meat, Eggs, & Dairy",
		image: Images.MeatIcon,
		items: [
			{ name: "Beef", id: 13 },
			{ name: "Lamb", id: 14 },
			{ name: "Chicken", id: 15 },
			{ name: "Kale", id: 16 },
			{ name: "Spinach", id: 17 },
			{ name: "Broccoli", id: 18 },
		],
	},
	{
		name: "Herbs and Spices",
		image: Images.HerbsSpicesIcon,
		items: [
			{ name: "Apple", id: 25 },
			{ name: "Grapes", id: 26 },
			{ name: "Cabbage", id: 27 },
		],
	},
];
export const SIDEBAR_INGREDIENTS = [
	{
		name: "Fruits and Vegetables",
		image: Images.FruitsIcon,
		items: [
			{ name: "Avocados", id: 1 },
			{ name: "Poultry", id: 2 },
			{ name: "Plain Greek yogurt", id: 3 },
			{ name: "Fish", id: 4 },
			{ name: "Low-carb veggies", id: 5 },
			{ name: "Nuts", id: 6 },
			{ name: "Low-carb veggies", id: 7 },
			{ name: "Nuts", id: 8 },
			{ name: "Low-carb veggies", id: 9 },
			{ name: "Nuts", id: 10 },
			{ name: "Low-carb veggies", id: 11 },
			{ name: "Nuts", id: 12 },
		],
	},
	{
		name: "Meat, Eggs, & Dairy",
		image: Images.MeatIcon,
		items: [
			{ name: "Beef", id: 13 },
			{ name: "Lamb", id: 14 },
			{ name: "Chicken", id: 15 },
			{ name: "Kale", id: 16 },
			{ name: "Spinach", id: 17 },
			{ name: "Broccoli", id: 18 },
			{ name: "Asparagus", id: 19 },
			{ name: "Beef", id: 20 },
			{ name: "Lamb", id: 21 },
			{ name: "Chicken", id: 22 },
			{ name: "Fish", id: 23 },
			{ name: "Mutton", id: 24 },
		],
	},
	{
		name: "Herbs and Spices",
		image: Images.HerbsSpicesIcon,
		items: [
			{ name: "Apple", id: 25 },
			{ name: "Grapes", id: 26 },
			{ name: "Cabbage", id: 27 },
			{ name: "Pecans", id: 28 },
			{ name: "Olive Oil", id: 29 },
			{ name: "Almonds", id: 30 },
			{ name: "Pecans", id: 31 },
			{ name: "Apple", id: 32 },
			{ name: "Grapes", id: 33 },
			{ name: "Cabbage", id: 34 },
			{ name: "Olive Oil", id: 35 },
			{ name: "Almonds", id: 36 },
		],
	},
	{
		name: "Fruits and Vegetables",
		image: Images.FruitsIcon,
		items: [
			{ name: "Avocados", id: 1 },
			{ name: "Poultry", id: 2 },
			{ name: "Plain Greek yogurt", id: 3 },
			{ name: "Fish", id: 4 },
			{ name: "Low-carb veggies", id: 5 },
			{ name: "Nuts", id: 6 },
			{ name: "Low-carb veggies", id: 7 },
			{ name: "Nuts", id: 8 },
			{ name: "Low-carb veggies", id: 9 },
			{ name: "Nuts", id: 10 },
			{ name: "Low-carb veggies", id: 11 },
			{ name: "Nuts", id: 12 },
		],
	},
	{
		name: "Meat, Eggs, & Dairy",
		image: Images.MeatIcon,
		items: [
			{ name: "Beef", id: 13 },
			{ name: "Lamb", id: 14 },
			{ name: "Chicken", id: 15 },
			{ name: "Kale", id: 16 },
			{ name: "Spinach", id: 17 },
			{ name: "Broccoli", id: 18 },
			{ name: "Asparagus", id: 19 },
			{ name: "Beef", id: 20 },
			{ name: "Lamb", id: 21 },
			{ name: "Chicken", id: 22 },
			{ name: "Fish", id: 23 },
			{ name: "Mutton", id: 24 },
		],
	},
	{
		name: "Herbs and Spices",
		image: Images.HerbsSpicesIcon,
		items: [
			{ name: "Apple", id: 25 },
			{ name: "Grapes", id: 26 },
			{ name: "Cabbage", id: 27 },
			{ name: "Pecans", id: 28 },
			{ name: "Olive Oil", id: 29 },
			{ name: "Almonds", id: 30 },
			{ name: "Pecans", id: 31 },
			{ name: "Apple", id: 32 },
			{ name: "Grapes", id: 33 },
			{ name: "Cabbage", id: 34 },
			{ name: "Olive Oil", id: 35 },
			{ name: "Almonds", id: 36 },
		],
	},
];
export const CONTENT_INGREDIENTS = [
	{ name: "Avocados", id: 1 },
	{ name: "Poultry", id: 2 },
	{ name: "Plain Greek yogurt", id: 3 },
	{ name: "Fish", id: 4 },
	{ name: "Low-carb veggies", id: 5 },
	{ name: "Nuts", id: 6 },
	{ name: "Low-carb veggies", id: 7 },
	{ name: "Nuts", id: 8 },
	{ name: "Low-carb veggies", id: 9 },
	{ name: "Nuts", id: 10 },
	{ name: "Low-carb veggies", id: 11 },
	{ name: "Nuts", id: 12 },
	{ name: "Beef", id: 13 },
	{ name: "Lamb", id: 14 },
	{ name: "Chicken", id: 15 },
	{ name: "Kale", id: 16 },
	{ name: "Spinach", id: 17 },
	{ name: "Broccoli", id: 18 },
	{ name: "Asparagus", id: 19 },
	{ name: "Beef", id: 20 },
	{ name: "Lamb", id: 21 },
	{ name: "Chicken", id: 22 },
	{ name: "Fish", id: 23 },
	{ name: "Mutton", id: 24 },
	{ name: "Apple", id: 25 },
	{ name: "Grapes", id: 26 },
	{ name: "Cabbage", id: 27 },
	{ name: "Pecans", id: 28 },
	{ name: "Olive Oil", id: 29 },
	{ name: "Almonds", id: 30 },
	{ name: "Pecans", id: 31 },
	{ name: "Apple", id: 32 },
	{ name: "Grapes", id: 33 },
	{ name: "Cabbage", id: 34 },
	{ name: "Olive Oil", id: 35 },
	{ name: "Almonds", id: 36 },
];

export const DIET_FILTERS = [
	{
		label: "Gluten Free",
		value: "Gluten Free",
		spooncular: "Gluten Free",
		edmam: "gluten-free",
	},

	{
		label: "Vegan",
		value: "Vegan",
		spooncular: "Vegan",
		edmam: "vegan",
	},

	{
		label: "Vegetarian",
		value: "Vegetarian",
		spooncular: "Vegetarian",
		edmam: "vegetarian",
	},
];

export const MEAL_FILTERS = [
	{
		label: "Breakfast",
		value: "breakfast",
		spooncular: "breakfast",
		edamam: "breakfast",
		type: "mealType",
	},
	{
		label: "Dessert",
		value: "dessert",
		spooncular: "dessert",
		edamam: "Desserts",
		type: "dishType",
	},
	{
		label: "Main Course",
		value: "main course",
		spooncular: "main course",
		edamam: "Main course",
		type: "dishType",
	},
	{
		label: "Salad",
		value: "salad",
		spooncular: "salad",
		edamam: "Salad",
		type: "dishType",
	},
	{
		label: "Side dish",
		value: "side dish",
		spooncular: "side dish",
		edamam: "Side dish",
		type: "dishType",
	},
	{
		label: "soups",
		value: "soups",
		spooncular: "soup",
		edamam: "Soup",
		type: "dishType",
	},
	{
		label: "Appetizer",
		value: "appetizer",
		spooncular: "appetizer",
		edamam: "starter",
		type: "dishType",
	},
	{
		label: "Snacks",
		value: "snacks",
		spooncular: "snack",
		edamam: "Snack",
		type: "mealType",
	},
];

export const CUISINE_FILTERS = [
	{
		label: "African",
		value: "African",
		spooncular: "African",
		edamam: "",
	},
	{
		label: "American",
		value: "American",
		spooncular: "American",
		edamam: "American",
	},
	{
		label: "Caribbean",
		value: "Caribbean",
		spooncular: "Caribbean",
		edamam: "Caribbean",
	},

	{
		label: "Cajun",
		value: "Cajun",
		spooncular: "Cajun",
		edamam: "",
	},

	{
		label: "French",
		value: "French",
		spooncular: "French",
		edamam: "French",
	},

	{
		label: "Greek",
		value: "Greek",
		spooncular: "Greek",
		edamam: "",
	},

	{
		label: "Indian",
		value: "Indian",
		spooncular: "Indian",
		edamam: "Indian",
	},
	{
		label: "Italian",
		value: "Italian",
		spooncular: "Italian",
		edamam: "Italian",
	},

	{
		label: "Latin American",
		value: "Latin American",
		spooncular: "Latin American",
		edamam: "South American",
	},

	{
		label: "Mediterranean",
		value: "Mediterranean",
		spooncular: "Mediterranean",
		edamam: "Mediterranean",
	},

	{
		label: "Middle Eastern",
		value: "Middle Eastern",
		spooncular: "Middle Eastern",
		edamam: "Middle Eastern",
	},

	{
		label: "Spanish",
		value: "Spanish",
		spooncular: "Spanish",
		edamam: "",
	},

	{
		label: "Thai",
		value: "Thai",
		spooncular: "Thai",
		edamam: "",
	},
];

export const PROFILE_IMAGE_FORMATS = ["image/png", "image/jpeg"];
export const EDIT_COMPANY_FIELDS = {
	profileImage: {
		rules: [
			{
				validator: (_, value) => {
					if (!value || !value.fileList?.length) {
						return Promise.reject(new Error("Image is required"));
					} else if (
						value.fileList?.length > 0 &&
						!PROFILE_IMAGE_FORMATS.includes(value.file.type)
					) {
						return Promise.reject(new Error("Invalid image type"));
					} else {
						return Promise.resolve();
					}
				},
			},
		],
	},
};
