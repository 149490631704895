import React, { useState } from "react";
import { Avatar, Button, Drawer, Skeleton } from "antd";
import "./styles.scss";
import Images from "../../theme/Images";
import SideBarMeals from "../SideBarMeals";
import SideBarIngredients from "../SideBarIngredients";
import SideBarSearch from "../SideBarSearch";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../loginModal";
import RegisterModal from "../registerModal";
import { userSignOutRequest } from "../../redux/slicers/user";
import PantryDrawer from "../PantryDrawer";
import { toastAlert } from "../../services/utils";
import { ALERT_TYPES } from "../../constants";
import { BASE_URL } from "../../config/webService";

function MenuDrawer({ open, handleClose, handleSuggest, handlePantry }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const authenticated = useSelector(({ user }) => user.isAuthenticated);
	const user = useSelector(({ user }) => user.data?.user);

	const list = useSelector(({ ingredients }) => ingredients.list);

	const loaderSidebarIngs = useSelector(
		(state) => state?.ingredients?.ingredientsLoader
	);

	const loaderSidebarMeals = useSelector(
		(state) => state?.recipe?.loadingSidebarMeals
	);

	const { pathname } = useLocation();
	const [loginOpen, setloginOpen] = useState(false);
	const [registerOpen, setregisterOpen] = useState(false);
	const [pantryOpen, setPantryOpen] = useState(false);

	const [loader, setloader] = useState(false);

	const handleCloseLogin = () => {
		setloginOpen(!loginOpen);
	};
	const handleCloseRegister = () => {
		setregisterOpen(!registerOpen);
	};
	const handleLogout = () => {
		dispatch(userSignOutRequest());
		toastAlert("Signed out", ALERT_TYPES.success);
	};
	const handlePantryClose = () => {
		setPantryOpen(false);
	};

	const userImage = user?.image ? BASE_URL + user?.image : Images?.User;
	return (
		<Drawer
			placement="left"
			open={open}
			onClose={handleClose}
			closeIcon={false}
			title={
				<span className="drawer-header">
					<img src={Images.HeaderLogo} onClick={() => navigate("/")} />
					<img src={Images.CloseIcon} onClick={handleClose} />
				</span>
			}
			className="menu-drawer"
		>
			{authenticated && (
				<div className="user-info">
					<span
						className="user-name-image"
						onClick={() => {
							handleClose();
							navigate("/profile");
						}}
					>
						<Avatar src={userImage} size={40} />
						<span>{user?.username}</span>
					</span>
					<img
						src={pathname === "/favorites" ? Images.HeartFilled : Images.Heart}
						onClick={() => {
							handleClose();
							navigate("/favorites");
						}}
					/>
				</div>
			)}
			<SideBarSearch />
			<div className="drawer-user-info">
				{authenticated && (
					<div className="drawer-btn-container">
						<Button
							type="primary"
							onClick={() => {
								handleClose();
								handleSuggest();
							}}
						>
							Suggest Ingredient
						</Button>
						<Button
							type="primary"
							onClick={() => {
								handleClose();
								navigate("/my-recipe");
							}}
						>
							My Recipe
						</Button>
						<Button type="primary" onClick={handlePantry}>
							<Images.PantryIcon />
							My Pantry
						</Button>
						<Button
							type="primary"
							onClick={() => {
								handleClose();
								navigate("/profile");
							}}
						>
							My Account
						</Button>
						<Button
							className="login-btn auth-btn"
							onClick={() => {
								handleLogout();
								handleClose();
							}}
						>
							Logout
						</Button>
					</div>
				)}
				{!authenticated && (
					<div className="drawer-btn-container">
						<Button className="login-btn auth-btn" onClick={handleCloseLogin}>
							Login
						</Button>
						<Button
							type="primary"
							className="auth-btn"
							onClick={handleCloseRegister}
						>
							Signup
						</Button>
						<Button type="primary" onClick={handlePantry}>
							<Images.PantryIcon />
							My Pantry
						</Button>
					</div>
				)}
			</div>

			{loaderSidebarIngs || loaderSidebarMeals ? (
				<>
					<Skeleton active />
					<Skeleton active />

					<Skeleton active />
				</>
			) : (
				<>
					<SideBarMeals />
					<SideBarIngredients list={list} />
				</>
			)}

			{loginOpen && (
				<LoginModal
					handleClose={handleCloseLogin}
					handleRegister={() => {
						handleCloseLogin();
						handleCloseRegister();
					}}
				/>
			)}
			{registerOpen && (
				<RegisterModal
					handleClose={handleCloseRegister}
					handleLogin={() => {
						handleCloseRegister();
						handleCloseLogin();
					}}
				/>
			)}
			<PantryDrawer open={pantryOpen} handleClose={handlePantryClose} />
		</Drawer>
	);
}

export default MenuDrawer;
