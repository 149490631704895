import { take, put, call, fork } from "redux-saga/effects";
import { SAGA_ALERT_TIMEOUT, ALERT_TYPES } from "../../constants";
import {
	BASE_URL,
	callRequest,
	CREATE_RATING,
	GET_ALL_RATINGS,
	UPDATE_RATING,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";
import {
	createRatingRequest,
	getRatingsRequest,
	getRatingsSuccess,
	updateRatingRequest,
} from "../slicers/ratings";
import { getRatingManipulator } from "../../dataManuplator/rating";

function* createRating() {
	while (true) {
		const { payload } = yield take(createRatingRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				CREATE_RATING,
				payloadData,
				"",
				"",
				{},
				BASE_URL
			);

			if (response?.data) {
				// yield put(userLoginSuccess(response));
				responseCallback(true, response);
			} else {
				responseCallback(false, response);
				toastAlert("Failed to Add Rating", ALERT_TYPES.error);
			}
		} catch (error) {
			toastAlert("Failed to Add Rating", ALERT_TYPES.error);
			responseCallback(false, error);
		}
	}
}

function* getRatings() {
	while (true) {
		const { payload } = yield take(getRatingsRequest.type);

		try {
			const response = yield call(
				callRequest,
				GET_ALL_RATINGS,
				{},
				"",
				"populate=*&pagination[page]=1&pagination[pageSize]=500000",
				{},
				BASE_URL
			);

			if (response?.data) {
				yield put(getRatingsSuccess(getRatingManipulator(response.data)));
			}
		} catch (error) {}
	}
}

function* updateRating() {
	while (true) {
		const { payload } = yield take(updateRatingRequest.type);
		const { ratingId, payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				UPDATE_RATING,
				payloadData,
				ratingId,
				"",
				{},
				BASE_URL
			);

			if (response?.data) {
				// yield put(userLoginSuccess(response));
				responseCallback(true, response);
			} else {
				responseCallback(false, response);
				toastAlert("Failed to Update Rating", ALERT_TYPES.error);
			}
		} catch (error) {
			toastAlert("Failed to Update Rating", ALERT_TYPES.error);
			responseCallback(false, error);
		}
	}
}

export default function* root() {
	yield fork(createRating);
	yield fork(getRatings);
	yield fork(updateRating);
}
