import { combineReducers } from "@reduxjs/toolkit";

import general from "./general";
import user from "./user";
import subscription from "./subscription";
import recipe from "./recipe";
import ingredients from "./ingredients";
import filters from "./filters";
import collections from "./collections";
import ratings from "./ratings";
import comments from "./comments";

export default combineReducers({
	general,
	user,
	subscription,
	recipe,
	ingredients,
	filters,
	collections,
	ratings,
	comments,
});
