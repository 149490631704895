import ApiHandler from "../services/ApiHandler";
import { getCurrentAccessToken } from "../services/utils";

export const API_TIMEOUT = 30000;
export const ABORT_REQUEST_MESSAGE = "Network failed. Aborted request.";

export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
// export const BASE_URL = "http://localhost:1337";

// export const BASE_URL = "https://a5f0-182-188-42-224.in.ngrok.io/";
// export const BASE_URL = process.env.REACT_APP_BACKEND_STAGGING_URL;
// export const BASE_URL = process.env.REACT_APP_BACKEND_PROD_URL;

export const EDAMAM_BASE_URL = "https://api.edamam.com/api";
export const SPOONACULAR_BASE_URL = "https://api.spoonacular.com";

export const ERROR_SOMETHING_WENT_WRONG =
  "Something went wrong, Please try again later";
export const ERROR_API_NOT_FOUND = "Api not found, Please try again later";

export const ERROR_NETWORK_NOT_AVAILABLE =
  "Please connect to the working Internet";

export const ERROR_ACCOUNT_BLOCKED =
  "Either your account is blocked or deleted";

export const ERROR_TOKEN_EXPIRE = "Session Expired, Please login again!";

export const REQUEST_TYPE = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
};
// AUTH
export const USER_REGISTER = {
  route: "/api/auth/local/register",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const USER_LOGIN = {
  route: "/api/auth/local",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
// ALL RECIPES

export const GET_RECIPES = {
  route: "/conact-forms/fill",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_RECIPES_EDMAM = {
  route: "/recipes/v2",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_RECIPES_SPOON = {
  route: "/recipes",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

// CATEGORIES & INGREDIENTS
export const GET_CATEGORIES = {
  route: "/api/categories?populate=*",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};
export const GET_INGREDIENTS = {
  route: "/api/ingredients?populate=*&filters[isApprove][$eq]=true",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

// DASHBOARD STATES

export const CONTACT_US = {
  route: "/conact-forms/fill",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

//FILTERS
export const GET_CUISINES_FILTER = {
  route: "/api/cuisines",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_MEALS_FILTER = {
  route: "/api/meals",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_DIETS_FILTER = {
  route: "/api/diets",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

// ALL SUBSCRIPTIONS

export const GET_SUBSCRIPTIONS = {
  route: "/api/v1/reports/stats",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const UPDATE_USER = {
  route: "/api/user/updatePassword",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_USER_INFO = {
  route: "/api/user/updateInfo",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPLOAD_FILE = {
  route: "/api/upload",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const CREATE_RECIPE = {
  route: "/api/recipes",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const CREATE_INGREDIENT = {
  route: "/api/ingredients",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const GET_STRAPI_RANDOM_RECIPES = {
  route: "/api/recipes/getRandom",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_STRAPI_FILTERED_RECIPES = {
  route: "/api/recipes/getFilterByIngredients",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const CREATE_COLLECTION = {
  route: "/api/collections",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const CREATE_FAVOURITE = {
  route: "/api/favourites",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const DELETE_FAVOURITE = {
  route: "/api/favourites",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_ALL_COLLECTIONS = {
  route: "/api/collections",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_SPOONACULAR_FAVOURITE_RECIPE_DETAIL = {
  route: "/api/recipes/favourites/spoonacular",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_FOODIE_FAVOURITE_RECIPE_DETAIL = {
  route: "/api/recipes/favourites/foodie",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_EDAMAM_FAVOURITE_RECIPE_DETAIL = {
  route: "/api/recipes/favourites/edamam",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const CREATE_RATING = {
  route: "/api/ratings",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_RATING = {
  route: "/api/ratings",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_ALL_RATINGS = {
  route: "/api/ratings",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const CREATE_COMMENT = {
  route: "/api/comments",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_RECIPES_COMMENT = {
  route: "/api/comments",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const GET_MY_RECIPES = {
  route: "/api/recipes",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const DELETE_MY_RECIPE = {
  route: "/api/recipes",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const FORGOT_PASSWORD = {
  route: "/api/user/password/forgot-password",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const VERIFICATION_CODE = {
  route: "/api/verification-codes",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const RESET_PASSWORD = {
  route: "/api/user/password/reset-password",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

// /api/sidebar-meals

export const GET_SIDEBAR_MEALS = {
  route: "/api/sidebar-meals",
  access_token_required: false,
  type: REQUEST_TYPE.GET,
};

export const CREATE_RECIPE_LINK = {
  route: "/api/recipe-links",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const callRequest = async (
  url,
  data,
  parameter,
  query,
  header = {},
  baseURL = BASE_URL
) => {
  let _header = header;
  if (url.access_token_required) {
    const _access_token = getCurrentAccessToken();
    if (_access_token) {
      _header = {
        ..._header,
        ...{
          Authorization: `Bearer ${_access_token}`,
        },
      };
    }
  }

  let _url =
    parameter && parameter !== null ? `${url.route}/${parameter}` : url.route;
  if (query && query !== null) {
    _url = `${_url}?${query}`;
  }
  let response = await ApiHandler(url.type, _url, data, _header, baseURL);

  return response;
};
