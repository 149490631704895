import { Select } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ingredClear,
  ingredDelete,
  ingredSelect,
  pantryIngredSelect,
} from "../../redux/slicers/ingredients";
import { Images } from "../../theme";
import { CloseCircleFilled, CloseCircleOutlined } from "@ant-design/icons";

import "./styles.scss";
function SideBarSearch() {
  const dispatch = useDispatch();
  const allIngredients = useSelector(
    ({ ingredients }) => ingredients.ingredients
  );
  const selected = useSelector(({ ingredients }) => ingredients.pantrySelected);
  const selectedIng = useSelector(({ ingredients }) => ingredients.selected);
  const [selectVal, setSelectVal] = useState(undefined);

  const handleIng = (id) => {
    dispatch(pantryIngredSelect(""));
    const item = allIngredients.find((e) => e.id === id);
    if (selectedIng.includes(item)) {
      dispatch(ingredDelete(id));
    } else {
      dispatch(ingredSelect(item));
    }
    setSelectVal(id);
  };

  return (
    <div className="sidebar-search-wrapper">
      <p>Simple Recipes You Can Make With Ingredients You Already Have</p>
      <Select
        showSearch
        autoFocus={false}
        autoClearSearchValue
        suffixIcon={<Images.SearchIcon />}
        placeholder="Search Ingredients..."
        value={selected !== "" ? selected : selectVal}
        options={allIngredients.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        clearIcon={
          <CloseCircleFilled style={{ color: "#40B9B6", fontSize: "18px" }} />
        }
        onClear={(e) => {
          setSelectVal(null);
          dispatch(pantryIngredSelect(""));
        }}
        allowClear={true}
        labelInValue
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        onSelect={(option) => handleIng(option.value)}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
      />
    </div>
  );
}

export default SideBarSearch;
