// @flow
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const CollectionsReducer = createSlice({
	name: "collections",
	initialState: {
		collections: [],
		favouriteList: [],
	},
	reducers: {
		createCollectionRequest() {},

		createFavouriteRequest() {},

		getAllCollectionRequest() {},

		getAllCollectionSuccess(state, action) {
			state.collections = action.payload?.collection;
			state.favouriteList = [...action?.payload?.allFavourites];
		},

		deleteFavouriteRequest() {},

		deleteFavouriteSuccess(state, action) {
			let allFavourites = [...state.favouriteList];
			allFavourites = allFavourites.filter((f) => f?.id != action?.payload?.id);

			state.favouriteList = [...allFavourites];
		},

		getSpoonFavouriteDetailRequest() {},
		getFoodieFavouriteDetailRequest() {},
		getEdamamFavouriteDetailRequest() {},
	},
});

export const {
	createCollectionRequest,
	createFavouriteRequest,
	getAllCollectionRequest,
	getAllCollectionSuccess,
	deleteFavouriteRequest,
	deleteFavouriteSuccess,
	getSpoonFavouriteDetailRequest,
	getFoodieFavouriteDetailRequest,
	getEdamamFavouriteDetailRequest,
} = CollectionsReducer.actions;

export default CollectionsReducer.reducer;
