import { Col, Image, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import _ from "lodash";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import {
	createFavouriteRequest,
	getAllCollectionRequest,
} from "../../redux/slicers/collections";
import { toastAlert } from "../../services/utils";
import { Images } from "../../theme";
import ButtonComponent from "../ButtonComponent";
import "./styles.scss";

function AddToCollectionModal({ handleClose, title, handleCreate, ...props }) {
	const dispatch = useDispatch();

	const [selectedCollection, setSelectedCollection] = useState(null);

	const collections = useSelector((state) => state?.collections?.collections);
	const user = useSelector((state) => state?.user?.data?.user);

	const handleAddToCollection = () => {
		if (_.isNil(selectedCollection)) {
			toastAlert("Select Collection", ALERT_TYPES.error);
			return;
		}

		const payload = {
			data: {
				collection: { connect: [{ id: selectedCollection }] },
				recipe_id: `${props?.id}`,
				source: props.owner,
				user: { connect: [{ id: user?.id }] },
			},
		};

		dispatch(
			createFavouriteRequest({
				payloadData: payload,
				responseCallback: (status, res) => {
					dispatch(
						getAllCollectionRequest({ payloadData: { userId: user?.id } })
					);
					handleClose();
				},
			})
		);
	};

	return (
		<>
			<Modal
				className="add-collection-modal"
				visible={true}
				centered
				onCancel={handleClose}
				footer={null}
				closeIcon={<img src={Images.CloseIcon} />}
			>
				<div className="modal-wrapper">
					<Row align="middle" gutter={[20, 10]}>
						<Col span={24}>
							<span className="title-wrapper">
								<h4 className="title">{title}</h4>
							</span>
						</Col>
						{collections?.length > 0 &&
							collections?.map((item) => (
								<Col span={24} key={item?.id}>
									<div
										className={`collection-option ${
											selectedCollection === item?.id &&
											"collection-option-active"
										}`}
										onClick={() => {
											setSelectedCollection(item?.id);
										}}
									>
										<div className="collection-option-image">
											<Row gutter={[3, 3]}>
												<Col span={12}>
													<Image preview={false} src={item?.image} alt="" />
												</Col>
											</Row>
										</div>
										<span className="collection-option-info">
											<h4>{item?.title}</h4>
											<p>{item?.favourites?.length} Recipes</p>
										</span>
									</div>
								</Col>
							))}
						<Col
							sm={{ span: 12 }}
							xs={{ span: 24 }}
							className="add-btn-wrapper"
						>
							<ButtonComponent
								onClick={handleCreate}
								className="add-modal-btn"
								text="Create New"
							/>
						</Col>{" "}
						<Col
							sm={{ span: 12 }}
							xs={{ span: 24 }}
							className="add-btn-wrapper"
						>
							<ButtonComponent
								onClick={handleAddToCollection}
								className="add-modal-btn"
								text="Add"
							/>
						</Col>
					</Row>
				</div>
			</Modal>
		</>
	);
}

export default AddToCollectionModal;
