import { Button, Col, Form, Input, Row, Select, Steps, Upload } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_SOMETHING_WENT_WRONG } from "../../config/webService";
import { EDIT_COMPANY_FIELDS, PROFILE_IMAGE_FORMATS } from "../../constants";
import { uploadFileRequest } from "../../redux/slicers/general";
import { createRecipeRequest } from "../../redux/slicers/recipe";
import { blobToBase64, toastAlert } from "../../services/utils";
import { Images } from "../../theme";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./styles.scss";

function CreateRecipeModal({ handleClose }) {
	const dispatch = useDispatch();
	const { Step } = Steps;
	const { Dragger } = Upload;

	const userData = useSelector((state) => state?.user?.data?.user);
	const { ingredients } = useSelector((state) => state.ingredients);
	const { meals, cuisines, diet } = useSelector((state) => state.filters);

	const [step, setStep] = useState(1);
	const [suggestions, setSuggestions] = useState([]);
	const [recipeSteps, setRecipeSteps] = useState([]);
	const [basicInfo, setBasicInfo] = useState({});
	const [loading, setIsLoading] = useState(false);
	const [imageFileSrc, setImageFileSrc] = useState(null);

	const [Ingredform] = Form.useForm();
	const [StepsForm] = Form.useForm();
	const [step1Form] = Form.useForm();

	const recipeImage = Form.useWatch("image", step1Form);

	useEffect(() => {
		if (recipeImage?.fileList?.length > 0) {
			blobToBase64(recipeImage?.fileList?.[0]?.originFileObj, (img) => {
				setImageFileSrc(img);
			});
		} else {
			setImageFileSrc(null);
		}
	}, [recipeImage]);

	const handleAddSuggestion = (item) => {
		if (!suggestions.includes(item)) {
			setSuggestions([...suggestions, item]);
		}
	};
	const handleRecipeStepDrop = (droppedItem) => {
		if (!droppedItem.destination) return;
		var updatedList = [...recipeSteps];
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		setRecipeSteps(updatedList);
	};
	const handleIngredientDrop = (droppedItem) => {
		if (!droppedItem.destination) return;
		var updatedList = [...suggestions];
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		setSuggestions(updatedList);
	};

	const handleDeleteSuggestion = (item) => {
		let clone = suggestions.slice(0);
		let index = clone.indexOf(item);
		clone.splice(index, 1);
		setSuggestions(clone);
	};

	const handleDeleteSteps = (item) => {
		let clone = recipeSteps.slice(0);
		let index = clone.indexOf(item);
		clone.splice(index, 1);
		setRecipeSteps(clone);
	};

	const handleIngredSubmit = () => {
		const values = Ingredform.getFieldsValue();
		if ((values.name !== undefined) & (values.quantity !== undefined)) {
			handleAddSuggestion(values);
		}
		Ingredform.resetFields();
	};

	const handleStepsSubmit = () => {
		const values = StepsForm.getFieldsValue();

		if (values.step !== undefined) {
			setRecipeSteps([...recipeSteps, values]);
			StepsForm.resetFields();
		}
	};

	const handleStep1Finish = (val) => {
		setBasicInfo({ ...val });
		setStep(step + 1);
	};

	const onSearch = (value) => {};

	const onRecipeSubmit = () => {
		setIsLoading(true);

		const filePayload = new FormData();

		filePayload.append("files", basicInfo?.image?.fileList?.[0]?.originFileObj);

		dispatch(
			uploadFileRequest({
				payloadData: filePayload,
				responseCallback: (status, res) => {
					setIsLoading(false);
					if (status) {
						const imageId = res[0]?.id;
						const recipePayload = {
							data: {
								...basicInfo,
								diet: {
									connect: [{ id: basicInfo?.diet }],
								},
								meal: {
									connect: [{ id: basicInfo?.meal }],
								},
								cuisine: {
									connect: [{ id: basicInfo?.cuisine }],
								},
								image: imageId,
								ingredients: suggestions,
								steps: recipeSteps,
								user: {
									connect: [{ id: userData?.id }],
									disconnect: [],
								},
							},
						};

						dispatch(
							createRecipeRequest({
								payloadData: recipePayload,
								responseCallback: (status) => {
									if (status) {
										toastAlert("Recipe Created Successfully");
										handleClose();
									}
								},
							})
						);
					} else {
						toastAlert(ERROR_SOMETHING_WENT_WRONG);
						setIsLoading(false);
					}
				},
			})
		);
	};

	return (
		<Modal
			width={750}
			className="create-recipe-modal"
			visible={true}
			centered
			onCancel={handleClose}
			maskClosable={false}
			footer={[
				<Row
					gutter={[40, 10]}
					style={{ width: "100%" }}
					className="suggestion-btn-container"
				>
					{step !== 1 && (
						<Col lg={{ span: 8 }} xs={{ span: 24 }}>
							<Button
								className="back-modal-btn"
								type="primary"
								onClick={() => {
									if (step === 1) {
										handleClose();
									} else {
										setStep(step - 1);
									}
								}}
							>
								Back
							</Button>
						</Col>
					)}

					<Col lg={{ span: step === 1 ? 24 : 16 }} xs={{ span: 24 }}>
						<Button
							className="next-modal-btn"
							onClick={() => {
								if (step === 3) {
									if (recipeSteps.length > 0) {
										onRecipeSubmit();
									} else {
										StepsForm.submit();
									}
								} else if (step === 1) {
									step1Form.submit();
								} else if (step === 2) {
									if (suggestions.length > 0) {
										setStep(step + 1);
									} else {
										Ingredform.submit();
									}
								}
							}}
						>
							{step === 3 ? "Upload" : "Next"}
						</Button>
					</Col>
				</Row>,
			]}
			closeIcon={<img src={Images.CloseIcon} />}
		>
			<div className="modal-wrapper">
				<Row align="middle" gutter={[20, 10]} type="flex" justify="center">
					<Col span={24}>
						<span className="title-wrapper">
							<h4 className="title">Add My Recipe</h4>
						</span>
					</Col>
					<Col span={16} className="steps-wrapper">
						<Steps current={step - 1} width={400}>
							<Step />
							<Step />
							<Step />
						</Steps>
					</Col>

					<Col span={24} className="suggestion-content-wrapper">
						{step === 1 && (
							<span className="add-ingredient-wrapper">
								<Form
									className="recipe-form"
									form={step1Form}
									onFinish={handleStep1Finish}
								>
									<div className="image-dragger-wrapper">
										<Form.Item
											name="image"
											rules={EDIT_COMPANY_FIELDS.profileImage.rules}
											className="image-form-item"
											initialValue={basicInfo?.image}
										>
											<Dragger
												className="image-dragger upload-list-inline"
												multiple={false}
												maxCount={1}
												accept={PROFILE_IMAGE_FORMATS}
												beforeUpload={() => false}
											>
												<img
													src={imageFileSrc ?? Images.AddImage}
													alt=""
													style={{ height: 59, width: 59 }}
												/>
												{!imageFileSrc && (
													<p className="ant-upload-text">Upload Recipe Photo</p>
												)}
											</Dragger>
										</Form.Item>
									</div>
									<Row gutter={[15, 10]} className="step1-form">
										<Col span={24}>
											<h4>Recipe Details</h4>
										</Col>
										<Col span={24}>
											<Form.Item
												name="title"
												rules={[
													{
														required: true,
														message: "Please Enter a Name For Recipe",
													},
												]}
											>
												<Input placeholder="Recipe name" />
											</Form.Item>
										</Col>
										<Col
											lg={{ span: 12 }}
											md={{ span: 12 }}
											sm={{ span: 24 }}
											xs={{ span: 24 }}
										>
											<Form.Item
												name="serves"
												rules={[
													{
														required: true,
														message: "Please Enter The Serving Size For Recipe",
													},
												]}
											>
												<Input placeholder="Serves" />
											</Form.Item>
										</Col>
										<Col
											lg={{ span: 12 }}
											md={{ span: 12 }}
											sm={{ span: 24 }}
											xs={{ span: 24 }}
										>
											<Form.Item
												name="cook_time"
												rules={[
													{
														required: true,
														message: "Please Enter The Cooking Time For Recipe",
													},
													{
														pattern: new RegExp(/^[0-9]*$/),
														message: "Only Numbers Are Allowed",
													},
												]}
											>
												<Input
													type="number"
													placeholder="Cook Time in Minutes"
												/>
											</Form.Item>
										</Col>
										<Col
											lg={{ span: 12 }}
											md={{ span: 12 }}
											sm={{ span: 24 }}
											xs={{ span: 24 }}
										>
											<Form.Item
												name="website"
												rules={[
													{
														required: true,
														message: "Please Enter a Link for Website or Blog",
													},
												]}
											>
												<Input placeholder="Website/Blog" />
											</Form.Item>
										</Col>

										<Col
											lg={{ span: 12 }}
											md={{ span: 12 }}
											sm={{ span: 24 }}
											xs={{ span: 24 }}
										>
											<Form.Item
												name="cuisine"
												rules={[
													{
														required: true,
														message: "Please Select Cuisine",
													},
												]}
											>
												<Select
													filterOption={(input, option) =>
														(option?.label ?? "")
															.toLowerCase()
															.includes(input.toLowerCase())
													}
													placeholder="Cuisine"
													showSearch
													optionFilterProp="children"
													options={cuisines}
												/>
											</Form.Item>
										</Col>

										<Col
											lg={{ span: 12 }}
											md={{ span: 12 }}
											sm={{ span: 24 }}
											xs={{ span: 24 }}
										>
											<Form.Item
												name="meal"
												rules={[
													{
														required: true,
														message: "Please Select Meal",
													},
												]}
											>
												<Select
													filterOption={(input, option) =>
														(option?.label ?? "")
															.toLowerCase()
															.includes(input.toLowerCase())
													}
													placeholder="Meal"
													showSearch
													optionFilterProp="children"
													options={meals}
												/>
											</Form.Item>
										</Col>

										<Col
											lg={{ span: 12 }}
											md={{ span: 12 }}
											sm={{ span: 24 }}
											xs={{ span: 24 }}
										>
											<Form.Item
												name="diet"
												rules={[
													{
														required: true,
														message: "Please Select Diet",
													},
												]}
											>
												<Select
													filterOption={(input, option) =>
														(option?.label ?? "")
															.toLowerCase()
															.includes(input.toLowerCase())
													}
													placeholder="Diet"
													showSearch
													optionFilterProp="children"
													options={diet}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Form>
								<Row>
									<Col span={12}></Col>
								</Row>
							</span>
						)}

						{step === 2 && (
							<span className="add-ingredient-wrapper">
								<h4>Ingredients</h4>
								<Form
									className="recipe-form ingred-form"
									onFinish={handleIngredSubmit}
									form={Ingredform}
								>
									<Row gutter={[15, 0]}>
										<Col
											lg={{ span: 12 }}
											md={{ span: 12 }}
											sm={{ span: 24 }}
											xs={{ span: 24 }}
										>
											<Form.Item
												name="name"
												rules={[
													{
														required: true,
														message: "Please Enter Ingredient Name!",
													},
												]}
											>
												<Select
													onSearch={onSearch}
													filterOption={(input, option) =>
														(option?.label ?? "")
															.toLowerCase()
															.includes(input.toLowerCase())
													}
													placeholder="Ingredients Name"
													showSearch
													optionFilterProp="children"
													options={ingredients.map((item, index) => {
														return { value: item.name, label: item.name };
													})}
												/>
											</Form.Item>
										</Col>
										<Col
											lg={{ span: 12 }}
											md={{ span: 12 }}
											sm={{ span: 24 }}
											xs={{ span: 24 }}
										>
											<Form.Item
												name="quantity"
												rules={[
													{
														required: true,
														message: "Please Enter Ingredient Quantity!",
													},
												]}
											>
												<Input placeholder="Ingredients Quantity" />
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item>
												<Button
													className="next-modal-btn"
													type="primary"
													htmlType="submit"
												>
													Add
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</Form>

								<DragDropContext onDragEnd={handleIngredientDrop}>
									<Droppable droppableId="list-container">
										{(provided) => (
											<Row
												style={{ width: "100%", marginTop: "15px" }}
												gutter={[5, 5]}
												className="step1-form"
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{suggestions.map((item, index) => (
													<Draggable
														key={item}
														draggableId={`dt${index + 1}`}
														index={index}
													>
														{(provided) => (
															<Col
																lg={{ span: 24 }}
																xs={{ span: 24 }}
																ref={provided.innerRef}
																{...provided.dragHandleProps}
																{...provided.draggableProps}
															>
																<div className="ingredients-item">
																	<p>
																		{item.quantity + " "}
																		{item.name}
																	</p>
																	<span>
																		<div
																			className="del-icon-wrapper"
																			onClick={() => {
																				handleDeleteSuggestion(item);
																			}}
																		>
																			<img src={Images.Delete} />
																		</div>
																		<img src={Images.DragDots} />
																	</span>
																</div>
															</Col>
															//   <div
															//     className="steps-item"
															//     ref={provided.innerRef}
															//     {...provided.dragHandleProps}
															//     {...provided.draggableProps}>
															//     <p>{item.step}</p>
															//     <span>
															//       <div
															//         className="del-icon-wrapper"
															//         onClick={() => {
															//           handleDeleteSteps(item);
															//         }}>
															//         <img src={Images.Delete} />
															//       </div>
															//       <img src={Images.DragDots} />
															//     </span>
															//   </div>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</Row>
										)}
									</Droppable>
								</DragDropContext>
								{/* <Row
                  style={{ width: "100%", marginTop: "15px" }}
                  gutter={[5, 5]}
                  className="step1-form">
                  {suggestions.map((item, i) => (
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                      <div className="ingredients-item">
                        <p>
                          {item.quantity + " "}
                          {item.name}
                        </p>
                        <span>
                          <div
                            className="del-icon-wrapper"
                            onClick={() => {
                              handleDeleteSuggestion(item);
                            }}>
                            <img src={Images.Delete} />
                          </div>
                          <img src={Images.DragDots} />
                        </span>
                      </div>
                    </Col>
                  ))}
                </Row> */}
							</span>
						)}

						{step === 3 && (
							<span className="add-ingredient-wrapper">
								<h4>Steps</h4>
								<Form
									className="recipe-form ingred-form"
									onFinish={handleStepsSubmit}
									form={StepsForm}
								>
									<Row gutter={[0, 0]} className="step3-1-form" align="top">
										<Col span={24}>
											<Form.Item
												name="step"
												rules={[
													{
														required: true,
														message: "Please Enter Recipe Steps!",
													},
												]}
											>
												<Input placeholder="Add steps" />
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item>
												<Button
													className="next-modal-btn"
													type="primary"
													htmlType="submit"
												>
													Add
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</Form>
								<div
									style={{ width: "100%", marginTop: "15px" }}
									gutter={[10, 10]}
									className="step3-form"
								>
									<DragDropContext onDragEnd={handleRecipeStepDrop}>
										<Droppable droppableId="list-container">
											{(provided) => (
												<div
													className="list-container"
													{...provided.droppableProps}
													ref={provided.innerRef}
												>
													{recipeSteps.map((item, index) => (
														<Draggable
															key={item}
															draggableId={`dt${index + 1}`}
															index={index}
														>
															{(provided) => (
																<div
																	className="steps-item"
																	ref={provided.innerRef}
																	{...provided.dragHandleProps}
																	{...provided.draggableProps}
																>
																	<p>{item.step}</p>
																	<span>
																		<div
																			className="del-icon-wrapper"
																			onClick={() => {
																				handleDeleteSteps(item);
																			}}
																		>
																			<img src={Images.Delete} />
																		</div>
																		<img src={Images.DragDots} />
																	</span>
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
									{/* {recipeSteps.map((item, i) => (
                    <Col span={24}>
                      <div className="steps-item">
                        <p>{item.step}</p>
                        <span>
                          <div
                            className="del-icon-wrapper"
                            onClick={() => {
                              handleDeleteSteps(item);
                            }}>
                            <img src={Images.Delete} />
                          </div>
                          <img src={Images.DragDots} />
                        </span>
                      </div>
                    </Col>
                  ))} */}
								</div>
							</span>
						)}
					</Col>
				</Row>
			</div>
		</Modal>
	);
}

export default CreateRecipeModal;
