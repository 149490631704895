import _, { filter } from "lodash";
import { call, fork, put, take } from "redux-saga/effects";
import {
  BASE_URL,
  callRequest,
  CREATE_RECIPE,
  CREATE_RECIPE_LINK,
  DELETE_MY_RECIPE,
  EDAMAM_BASE_URL,
  GET_MY_RECIPES,
  GET_RECIPES_EDMAM,
  GET_RECIPES_SPOON,
  GET_SIDEBAR_MEALS,
  GET_STRAPI_FILTERED_RECIPES,
  GET_STRAPI_RANDOM_RECIPES,
  SPOONACULAR_BASE_URL,
} from "../../config/webService";
import {
  getSpoonacularRecipeListManipulator,
  getEdmamRecipeListManipulator,
  getSpoonacularRecipeListManipulatorByIngredients,
  manipulatorGetStrapiRandomRecipes,
  getMyRecipeManipulator,
  getSidebarMealsManipulator,
} from "../../dataManuplator/recipe";
import {
  getRecipesRequest,
  getEdmamRecipesSuccess,
  getSpoonacularRecipesSuccess,
  getRecipesByIngredientsRequest,
  getSpoonacularRecipesByIngredientsSuccess,
  getEdamamRecipeByIngredientSuccess,
  createRecipeRequest,
  createRecipeSuccess,
  getStrapiRandomRecipeSuccess,
  getStrapiFilteredRecipeSuccess,
  getMyRecipesRequest,
  getMyRecipesSuccess,
  deleteMyRecipeRequest,
  getSidebarMealsRequest,
  getSidebarMealsSuccess,
  setLoaderSidebarMeals,
  createRecipeLinkRequest,
} from "../slicers/recipe";

function* getEdmamRecipes() {
  while (true) {
    const { payload } = yield take(getRecipesRequest.type);
    const { responseCallBack, filters, mealFilters } = payload;

    try {
      let health = "";
      let meal = "";
      let exclude = "";
      let cuisine = "";
      let newMealFilter = "";
      let maxIngredient = "";
      let cookTime = "";

      if (!_.isEmpty(mealFilters)) {
        if (mealFilters?.edamam?.type === "diet") {
          const option = mealFilters?.options?.find((a) => a?.check === true);
        }

        if (mealFilters?.edamam?.type === "health") {
          const option = mealFilters?.options?.find((a) => a?.check === true);

          newMealFilter = `&health=${
            mealFilters?.edamam?.name
          }&nutrients%5BCHOCDF%5D=${option?.quantity ?? 0}%2B`;
        }

        if (mealFilters?.edamam?.type === "cuisineType") {
          const option = mealFilters?.options?.find((a) => a?.check === true);

          newMealFilter = `&cuisineType=${
            mealFilters?.edamam?.name
          }&nutrients%5BCHOCDF%5D=${option?.quantity ?? 0}%2B`;
        }
      }

      if (filters?.cookTimeData?.edamam) {
        cookTime = `&time=${filters?.cookTimeData?.edamam}`;
      }

      if (filters?.maxIngredientsEdamam) {
        maxIngredient = `&ingr=${filters?.maxIngredientsEdamam}`;
      }

      if (filters?.diet?.length > 0) {
        const diet = filters?.diet?.map((d) => d.edmam);

        health = `&health=${diet}`;
      }

      if (!_.isEmpty(filters?.mealType)) {
        if (filters?.mealType?.type === "dishType") {
          meal = `&dishType=${filters?.mealType?.edamam}`;
        } else {
          meal = `&mealType=${filters?.mealType?.edamam}`;
        }
      }

      if (!_.isEmpty(filters?.exclude)) {
        // exclude = `&excluded=${filters?.exclude?.join(",")}`;
        for (let e of filters?.exclude) {
          exclude += `&excluded=${e}`;
        }
      }

      if (!_.isEmpty(filters?.cuisines?.edamam)) {
        cuisine = `&cuisineType=${filters?.cuisines?.edamam}`;
      }

      newMealFilter = `&diet=${"low-carb"}&nutrients%5BCHOCDF%5D=${50 ?? 0}%2B`;

      const response = yield call(
        callRequest,
        GET_RECIPES_EDMAM,
        {},
        "",
        `type=public&random=true&app_id=${process.env.REACT_APP_EDAMAM_APP_ID}&app_key=${process.env.REACT_APP_EDAMAM_API_KEY}${health}${meal}${exclude}${cuisine}${newMealFilter}${maxIngredient}${cookTime}`,
        {},
        EDAMAM_BASE_URL
      );
      yield put(
        getEdmamRecipesSuccess(getEdmamRecipeListManipulator(response.hits))
      );
      responseCallBack && responseCallBack();
    } catch (err) {
      console.log(err);
      responseCallBack && responseCallBack(err);
    }
  }
}

function* getSpoonRecipes() {
  while (true) {
    const { payload } = yield take(getRecipesRequest.type);
    const { responseCallBack, filters, mealFilters } = payload;

    try {
      let diet = "";
      let meal = "";
      let exclude = "";
      let cuisine = "";
      let keyIngredients = "";

      let newMealFilter = "";

      let cookTime = "";

      if (filters?.cookTimeData?.spoonacular) {
        cookTime = `&maxReadyTime=${filters?.cookTimeData?.spoonacular}`;
      }

      if (!_.isEmpty(mealFilters)) {
        if (mealFilters?.spoonacular?.type === "diet") {
          const options = mealFilters?.options?.filter((a) => a.check === true);
          newMealFilter = `&diet=${mealFilters?.spoonacular?.name}&maxCarbs=${
            options?.[0]?.minQuantity ?? 0
          }`;
        }
      }

      newMealFilter = `&diet=${"Ketogenic"}&maxCarbs=${50}`;

      if (filters?.keyIngredients?.length > 0) {
        keyIngredients = `&includeIngredients=${filters?.keyIngredients?.join(
          ","
        )}`;
      }

      if (filters?.diet?.length > 0) {
        diet = filters?.diet?.map((d) => d.spoonacular).join();
      }

      if (!_.isEmpty(filters?.mealType)) {
        meal = `&type=${filters?.mealType?.spoonacular}`;
      }

      if (!_.isEmpty(filters?.exclude)) {
        exclude = `&excludeIngredients=${filters?.exclude?.join(",")}`;
      }

      if (!_.isEmpty(filters?.cuisines?.spoonacular)) {
        cuisine = `&cuisine=${filters?.cuisines?.spoonacular}`;
      }

      const response = yield call(
        callRequest,
        GET_RECIPES_SPOON,
        {},
        "random",
        `apiKey=${process.env.REACT_APP_SPOONACULAR_API_KEY}&number=20&diet=${diet}${meal}${exclude}${cuisine}${newMealFilter}${keyIngredients}${cookTime}`,
        {},
        SPOONACULAR_BASE_URL
      );
      yield put(
        getSpoonacularRecipesSuccess(
          getSpoonacularRecipeListManipulator(response.recipes)
        )
      );
      responseCallBack && responseCallBack(response);
    } catch (err) {
      responseCallBack && responseCallBack(err);
    }
  }
}

function* getStrapiRandomRecipes() {
  while (true) {
    const { payload } = yield take(getRecipesRequest.type);
    const { responseCallBack, filters, mealFilters } = payload;

    try {
      let diet = "";
      let meal = "";
      let exclude = "";
      let cuisine = "";
      let keyIngredients = "";
      let maxIngredient = "";
      let cookTime = "";

      if (filters?.cookTimeData?.strapi) {
        cookTime = `&time=${filters?.cookTimeData?.strapi}`;
      }

      if (filters?.maxIngredientsStrapi) {
        maxIngredient = `&maxIngredients=${filters?.maxIngredientsStrapi}`;
      }

      if (filters?.includeIngredients?.length > 0) {
        keyIngredients = `&includeIngredients=${filters?.includeIngredients?.join(
          ","
        )}`;
      }

      if (filters?.diet?.length > 0) {
        diet = filters?.diet?.map((d) => d.spoonacular).join();
      }

      if (!_.isEmpty(filters?.mealType)) {
        meal = `&meal=${filters?.mealType?.id}`;
      }

      if (!_.isEmpty(filters?.exclude)) {
        exclude = `&excludeIngredients=${filters?.exclude?.join(",")}`;
      }

      if (!_.isEmpty(filters?.cuisines)) {
        cuisine = `&cuisine=${filters?.cuisines?.id}`;
      }

      const response = yield call(
        callRequest,
        GET_STRAPI_RANDOM_RECIPES,
        {},
        "",
        `${cuisine}${diet}${meal}${exclude}${keyIngredients}${maxIngredient}${cookTime}`,
        {},
        BASE_URL
      );

      if (response?.length > 0) {
        yield put(
          getStrapiRandomRecipeSuccess(
            manipulatorGetStrapiRandomRecipes(response)
          )
        );
      }
      responseCallBack && responseCallBack(response);
    } catch (err) {
      responseCallBack && responseCallBack(err);
    }
  }
}

function* getSpoonRecipesByIngredients() {
  while (true) {
    const { payload } = yield take(getRecipesByIngredientsRequest.type);
    const { payloadData, responseCallback, filters, mealFilters } = payload;

    let diet = "";
    let meal = "";
    let exclude = "";
    let cuisine = "cuisine";
    let keyIngredients = "";
    let newMealFilter = "";
    let cookTime = "";

    if (filters?.cookTimeData?.spoonacular) {
      cookTime = `&maxReadyTime=${filters?.cookTimeData?.spoonacular}`;
    }

    if (filters?.keyIngredients?.length > 0) {
      keyIngredients = `&includeIngredients=${filters?.keyIngredients?.join(
        ","
      )}`;
    }

    if (!_.isEmpty(mealFilters)) {
      if (mealFilters?.spoonacular?.type === "diet") {
        const options = mealFilters?.options?.filter((a) => a?.check === true);
        newMealFilter = `&diet=${mealFilters?.spoonacular?.name}&minCarbs=${
          options?.[0]?.minQuantity ?? 0
        }`;
      }
    }

    newMealFilter = `&diet=${"Ketogenic"}&maxCarbs=${50}`;

    if (filters?.diet?.length > 0) {
      diet = filters?.diet?.map((d) => d.spoonacular).join();
    }

    if (!_.isEmpty(filters?.mealType)) {
      meal = `&type=${filters?.mealType?.spoonacular}`;
    }

    if (!_.isEmpty(filters?.exclude)) {
      exclude = `&excludeIngredients=${filters?.exclude?.join(",")}`;
    }

    if (!_.isEmpty(filters?.cuisines?.spoonacular)) {
      cuisine = `&cuisine=${filters?.cuisines?.spoonacular}`;
    }

    try {
      const response = yield call(
        callRequest,
        GET_RECIPES_SPOON,
        {},
        "findByIngredients",
        `apiKey=${
          process.env.REACT_APP_SPOONACULAR_API_KEY
        }&number=100&ingredients=${payloadData.map((e) => {
          return e.toLowerCase();
        })}&diet=${diet}${meal}${exclude}${cuisine}${newMealFilter}${keyIngredients}${cookTime}`,
        {},
        SPOONACULAR_BASE_URL
      );
      yield put(
        getSpoonacularRecipesByIngredientsSuccess(
          getSpoonacularRecipeListManipulatorByIngredients(
            response,
            payloadData
          )
        )
      );
      responseCallback &&
        responseCallback(
          true,
          getSpoonacularRecipeListManipulatorByIngredients(
            response,
            payloadData
          )
        );
    } catch (err) {
      responseCallback && responseCallback(false, err);
    }
  }
}

function* getEdmamRecipesByIngredients() {
  while (true) {
    const { payload } = yield take(getRecipesByIngredientsRequest.type);
    const { payloadData, filters, mealFilters } = payload;
    let health = "";
    let meal = "";
    let exclude = "";
    let cuisine = "";
    let maxIngredient = "";
    let newMealFilter = "";
    let cookTime = "";

    if (!_.isEmpty(mealFilters)) {
      if (mealFilters?.edamam?.type === "diet") {
        const option = mealFilters?.options?.find((a) => a?.check === true);
        newMealFilter = `&diet=${
          mealFilters?.edamam?.name
        }&nutrients%5BCHOCDF%5D=${option?.quantity ?? 0}%2B`;
      }

      if (mealFilters?.edamam?.type === "health") {
        const option = mealFilters?.options?.find((a) => a?.check === true);

        newMealFilter = `&health=${
          mealFilters?.edamam?.name
        }&nutrients%5BCHOCDF%5D=${option?.quantity ?? 0}%2B`;
      }

      if (mealFilters?.edamam?.type === "cuisineType") {
        const option = mealFilters?.options?.find((a) => a?.check === true);

        newMealFilter = `&cuisineType=${
          mealFilters?.edamam?.name
        }&nutrients%5BCHOCDF%5D=${option?.quantity ?? 0}%2B`;
      }
    }

    if (filters?.cookTimeData?.edamam) {
      cookTime = `&time=${filters?.cookTimeData?.edamam}`;
    }

    if (filters?.maxIngredientsEdamam) {
      maxIngredient = `&ingr=${filters?.maxIngredientsEdamam}`;
    }

    if (filters?.diet?.length > 0) {
      const diet = filters?.diet?.map((d) => d?.edmam);

      health = `&health=${diet}`;
    }

    if (!_.isEmpty(filters?.mealType)) {
      if (filters?.mealType?.type === "dishType") {
        meal = `&dishType=${filters?.mealType?.edamam}`;
      } else {
        meal = `&mealType=${filters?.mealType?.edamam}`;
      }
    }

    if (!_.isEmpty(filters?.exclude)) {
      // exclude = `&excluded=${filters?.exclude?.join(",")}`;
      for (let e of filters?.exclude) {
        exclude += `&excluded=${e}`;
      }
    }

    if (!_.isEmpty(filters?.cuisines?.edamam)) {
      cuisine = `&cuisineType=${filters?.cuisines?.edamam}`;
    }

    newMealFilter = `&diet=${"low-carb"}&nutrients%5BCHOCDF%5D=${50 ?? 0}%2B`;

    const q = payloadData?.join(",") || "chicken";

    try {
      const response = yield call(
        callRequest,
        GET_RECIPES_EDMAM,
        {},
        "",
        `type=public&q=${q}&app_id=${process.env.REACT_APP_EDAMAM_APP_ID}&app_key=${process.env.REACT_APP_EDAMAM_API_KEY}&${health}${meal}${exclude}${cuisine}${newMealFilter}${maxIngredient}${cookTime}`,
        {},
        EDAMAM_BASE_URL
      );

      yield put(
        getEdamamRecipeByIngredientSuccess(
          getEdmamRecipeListManipulator(response?.hits, payloadData)
        )
      );
    } catch (err) {
      console.log(err);
      // responseCallback && responseCallback(false, err);
    }
  }
}

function* createRecipe() {
  while (true) {
    const { payload } = yield take(createRecipeRequest.type);
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        CREATE_RECIPE,
        payloadData,
        "",
        "",
        {},
        BASE_URL
      );

      if (response.data) {
        yield put(createRecipeSuccess());
        responseCallback && responseCallback(true);
      }
    } catch (err) {
      responseCallback && responseCallback(false, err);
    }
  }
}

function* getStrapiRecipeByIngredients() {
  while (true) {
    const { payload } = yield take(getRecipesByIngredientsRequest.type);
    const { filters, payloadData } = payload;

    const q = payloadData?.join(",");

    try {
      let diet = "";
      let meal = "";
      let exclude = "";
      let cuisine = "";
      let keyIngredients = "";
      let maxIngredient = "";
      let cookTime = "";

      if (filters?.cookTimeData?.strapi) {
        cookTime = `&time=${filters?.cookTimeData?.strapi}`;
      }

      if (filters?.maxIngredientsStrapi) {
        maxIngredient = `&maxIngredients=${filters?.maxIngredientsStrapi}`;
      }

      if (filters?.includeIngredients?.length > 0) {
        keyIngredients = `&includeIngredients=${filters?.includeIngredients?.join(
          ","
        )}`;
      }

      if (filters?.diet?.length > 0) {
        diet = filters?.diet?.map((d) => d.spoonacular).join();
      }

      if (!_.isEmpty(filters?.mealType)) {
        meal = `&meal=${filters?.mealType?.id}`;
      }

      if (!_.isEmpty(filters?.exclude)) {
        exclude = `&excludeIngredients=${filters?.exclude?.join(",")}`;
      }

      if (!_.isEmpty(filters?.cuisines)) {
        cuisine = `&cuisine=${filters?.cuisines?.id}`;
      }

      const response = yield call(
        callRequest,
        GET_STRAPI_FILTERED_RECIPES,
        {},
        "",
        `q=${q}${diet}${meal}${cuisine}${exclude}${keyIngredients}${maxIngredient}${cookTime}`,
        {},
        BASE_URL
      );

      // yield put(
      // 	getEdamamRecipeByIngredientSuccess(
      // 		getEdmamRecipeListManipulator(response?.hits)
      // 	)
      // );
      yield put(
        getStrapiFilteredRecipeSuccess(
          manipulatorGetStrapiRandomRecipes(response, payloadData)
        )
      );
    } catch (err) {
      // responseCallback && responseCallback(false, err);
    }
  }
}

function* getMyRecipes() {
  while (true) {
    const { payload } = yield take(getMyRecipesRequest.type);
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_MY_RECIPES,
        {},
        "",
        `populate=*&filters[user][id][$eq]=${payloadData?.userId}&&pagination[page]=1&pagination[pageSize]=500000`,
        {},
        BASE_URL
      );

      if (response?.data) {
        responseCallback(true);
        yield put(getMyRecipesSuccess(getMyRecipeManipulator(response?.data)));
      } else {
        responseCallback(false);
      }
    } catch (err) {
      responseCallback && responseCallback(false);
    }
  }
}

function* deleteMyRecipe() {
  while (true) {
    const { payload } = yield take(deleteMyRecipeRequest.type);
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        DELETE_MY_RECIPE,
        {},
        payloadData?.recipeId,
        "",
        {},
        BASE_URL
      );

      if (response?.data) {
        responseCallback(true);
      } else {
        responseCallback(false);
      }
    } catch (err) {
      responseCallback && responseCallback(false);
    }
  }
}

function* getSidebarMeals() {
  while (true) {
    const { payload } = yield take(getSidebarMealsRequest.type);

    yield put(setLoaderSidebarMeals(true));
    try {
      const response = yield call(
        callRequest,
        GET_SIDEBAR_MEALS,
        {},
        "",
        "populate=*",
        {},
        BASE_URL
      );

      if (response?.data) {
        yield put(
          getSidebarMealsSuccess(getSidebarMealsManipulator(response?.data))
        );
      }
      yield put(setLoaderSidebarMeals(false));
    } catch (err) {
      yield put(setLoaderSidebarMeals(false));

      // responseCallback && responseCallback(false, err);
    }
  }
}

function* createRecipeLink() {
  while (true) {
    const { payload } = yield take(createRecipeLinkRequest.type);
    const { responseCallback, payloadData } = payload;

    // yield put(setLoaderSidebarMeals(true));
    try {
      const response = yield call(
        callRequest,
        CREATE_RECIPE_LINK,
        payloadData,
        "",
        "",
        {},
        BASE_URL
      );

      if (response?.data) {
        responseCallback(true);
      } else {
        responseCallback(false, response);
      }
    } catch (err) {
      responseCallback(false, err);
      console.log(err);
    }
  }
}

export default function* root() {
  yield fork(getSpoonRecipes);
  yield fork(getEdmamRecipes);
  yield fork(getStrapiRandomRecipes);
  yield fork(getSpoonRecipesByIngredients);
  yield fork(getEdmamRecipesByIngredients);
  yield fork(createRecipe);
  yield fork(getStrapiRecipeByIngredients);
  yield fork(getMyRecipes);
  yield fork(deleteMyRecipe);
  yield fork(getSidebarMeals);
  yield fork(createRecipeLink);
}
