import { Button, Col, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { CUISINE_FILTERS, DIET_FILTERS, MEAL_FILTERS } from "../../constants";
import { Images } from "../../theme";
import DetailsPopoverContent from "../DetailsPopoverContent";
import FoodCardComponent from "../FoodCardComponent";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { ingredClear, ingredDelete } from "../../redux/slicers/ingredients";
import { useNavigate } from "react-router-dom";
import {
  clearFilter,
  getRecipesByIngredientsRequest,
  getRecipesRequest,
} from "../../redux/slicers/recipe";
import { BeatLoader } from "react-spinners";
import _ from "lodash";

const initialState = {
  keyIngredients: [],
  exclude: [],
  mealType: {},
  cuisines: {},
  dietFilter: [],
  maxIngredients: null,
  recipeTime: [],
  strapiExclude: [],
};

const MAX_INGREDIENTS = [
  {
    label: "3 ingredients or less",
    value: 1,
    edamam: "3",
    strapi: "0_3",
  },
  {
    label: "5 ingredients or less",
    value: 2,
    edamam: "5",
    strapi: "0_5",
  },
  {
    label: "10 ingredients or less",
    value: 3,
    edamam: "10",
    strapi: "0_10",
  },
  {
    label: "10+ ingredients or less",
    value: 4,
    edamam: "10%2B",
    strapi: "10_10000",
  },
  {
    label: "20+ ingredients or less",
    value: 5,
    edamam: "20%2B",
    strapi: "20_10000",
  },
];

const COOK_TIME = [
  // {
  // 	label: "Ready in < 5 min",
  // 	value: 1,
  // 	edamam: "5",
  // 	spoonacular: "5",
  // 	strapi: "0_5",
  // },
  {
    label: "Ready in < 15 min",
    value: 2,
    edamam: "15",
    spoonacular: "15",
    strapi: "0_15",
  },
  {
    label: "Ready in < 30 min",
    value: 3,
    edamam: "30",
    spoonacular: "30",
    strapi: "0_30",
  },
  {
    label: "Ready in < 60 min",
    value: 4,
    edamam: "60",
    spoonacular: "60",
    strapi: "0_60",
  },
  // {
  // 	label: "Ready in 1 - 2 hours",
  // 	value: 5,
  // 	edamam: "60%2D120",
  // 	spoonacular: "120",
  // 	strapi: "60_120",
  // },
  // {
  // 	label: "Ready in 2 - 3 hours",
  // 	value: 6,
  // 	edamam: "120%2D180",
  // 	spoonacular: "180",
  // 	strapi: "120_180",
  // },
  // {
  // 	label: "Ready in 4+ hours",
  // 	value: 6,
  // 	edamam: "240%2B",
  // 	spoonacular: "240",
  // 	strapi: "240_10000",
  // },
];

function FoodieContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedDetails, setSelectedDetails] = useState(false);
  const [viewDetailsCard, setviewDetailsCard] = useState(null);
  const [foodCards, setfoodCards] = useState([]);
  const [filters, setFilters] = useState(initialState);
  const [dietFilter, setDietFilter] = useState([]);
  const [mealFilter, setMealFilter] = useState(null);
  const [excludeFilter, setExcludeFilter] = useState([]);
  const [cuisineFilter, setCuisineFilter] = useState(null);
  const [includeIngredients, setIncludeIngredients] = useState([]);
  const [maxIngredients, setMaxIngredients] = useState(null);
  const [cookTime, setCookTime] = useState(null);

  const [search, setSearch] = useState("");

  const selected = useSelector(({ ingredients }) => ingredients.selected);
  const recipes = useSelector((state) => state.recipe?.recipes);
  const mealFilters = useSelector((state) => state.recipe?.mealFilter);
  const filteredRecipes = useSelector((state) => state.recipe?.filteredRecipes);
  const allIngredients = useSelector((state) => state.ingredients?.ingredients);
  const { meals, cuisines, diet } = useSelector((state) => state?.filters);

  const loaderSidebarIngs = useSelector(
    (state) => state?.ingredients?.ingredientsLoader
  );

  const loaderSidebarMeals = useSelector(
    (state) => state?.recipe?.loadingSidebarMeals
  );

  useEffect(() => {
    if (selected.length === 0 && filteredRecipes.length > 0) {
      setLoading(true);

      dispatch(clearFilter());
      setfoodCards(recipes);
      setLoading(false);
    } else if (selected?.length > 0) {
      setLoading(true);
      let select = allIngredients?.filter((e) => selected.includes(e));
      dispatch(
        getRecipesByIngredientsRequest({
          payloadData: select.map((r) => {
            return r.name.toLowerCase();
          }),
          mealFilters,
          filters: filters,
          responseCallback: () => {
            setLoading(false);
          },
        })
      );
    }
  }, [selected, mealFilters]);

  useEffect(() => {
    if (selected?.length > 0) {
      setLoading(true);
      let select = allIngredients?.filter((e) => selected.includes(e));
      dispatch(
        getRecipesByIngredientsRequest({
          payloadData: select.map((r) => {
            return r.name.toLowerCase();
          }),
          mealFilters,
          filters: filters,
          responseCallback: () => {
            setLoading(false);
          },
        })
      );
    } else {
      if (!loaderSidebarIngs && !loaderSidebarMeals) {
        setLoading(true);
        dispatch(
          getRecipesRequest({
            filters: filters,
            mealFilters,
            responseCallBack: (res) => {
              setLoading(false);
            },
          })
        );
      }
    }
  }, [filters, mealFilters, loaderSidebarIngs, loaderSidebarMeals]);

  useEffect(() => {
    if (selected?.length > 0) {
      setfoodCards(filteredRecipes);
    } else {
      setfoodCards(recipes);
    }
  }, [selected, recipes, filteredRecipes]);

  const handleViewDetailList = (data, i, n) => {
    setviewDetailsCard(data);
    let mod = (i + 1) % n;

    let newIndex = n - mod < n ? i + 1 + n - mod : i + 1;
    let foodcards = foodCards.slice(0);
    if (foodCards.find((dt) => dt?.display)) {
      let displayIndex = foodCards.findIndex((dt) => dt?.display);
      foodcards.splice(displayIndex, 1);
    }

    foodcards.splice(newIndex, 0, { title: "", display: data });
    setfoodCards(foodcards);
  };

  const handleDeleteIngredient = (indx) => {
    dispatch(ingredDelete(indx));
    dispatch(clearFilter());
  };

  const handleClear = () => {
    dispatch(ingredClear());
    dispatch(clearFilter());
  };

  const onChangeDietFilter = (value) => {
    setDietFilter([...value]);
  };

  const onChangeMealFilter = (value) => {
    setMealFilter(value);
  };

  const handleClearFilters = () => {
    setMealFilter(null);
    setDietFilter([]);
    setCuisineFilter(null);
    setExcludeFilter([]);
    setCookTime(null);
    setMaxIngredients(null);
    setIncludeIngredients([]);
    setFilters({ ...initialState });
  };

  const handleSetFilters = () => {
    const dietFiltersObj = [];

    const excludeFilterIds = [];

    const includeIngredientsIds = [];

    let maxIng = "";
    let cookTimeData = "";

    if (maxIngredients) {
      maxIng = MAX_INGREDIENTS.find((ing) => ing?.value === maxIngredients);
    }

    if (cookTime) {
      cookTimeData = COOK_TIME.find((a) => a?.value == cookTime);
    }

    if (dietFilter?.length > 0) {
      for (let dietItem of dietFilter) {
        const d = diet?.find((a) => a.value === dietItem);
        if (d && !_.isEmpty(d)) {
          dietFiltersObj.push(d);
        }
      }
    }

    if (excludeFilter.length > 0) {
      for (let excludeName of excludeFilter) {
        const exclude = excludeOptions?.find(
          (item) => item?.value === excludeName
        );

        if (!_.isEmpty(exclude)) {
          excludeFilterIds.push(exclude.id);
        }
      }
    }

    if (includeIngredients.length > 0) {
      for (let ingredientName of includeIngredients) {
        const ingredient = excludeOptions?.find(
          (item) => item?.value === ingredientName
        );

        if (!_.isEmpty(ingredient)) {
          includeIngredientsIds.push(ingredient.id);
        }
      }
    }

    // if(includeIngredients)

    const mealType = meals.find((m) => m.value === mealFilter);
    const cuisine = cuisines.find((c) => c.value === cuisineFilter);

    setFilters({
      ...initialState,
      diet: dietFiltersObj,
      mealType: { ...mealType },
      exclude: [...excludeFilter],
      keyIngredients: [...includeIngredients],
      cuisines: { ...cuisine },
      strapiExclude: [...excludeFilterIds],
      strapiKeyIngredients: [...includeIngredientsIds],
      maxIngredientsEdamam: maxIng?.edamam,
      maxIngredientsStrapi: maxIng?.strapi,
      cookTimeData,
    });
  };

  const excludeOptions =
    allIngredients?.map((item) => {
      return {
        ...item,
        label: item?.name,
        value: item?.name,
      };
    }) || [];

  const filteredFoodCards = foodCards?.filter((f) =>
    f?.title?.toLowerCase()?.match?.(search?.toLowerCase())
  );

  return (
    <div className="foodie-content-wrapper">
      <Row gutter={[16, 0]} align="middle" className="top-row">
        <Col
          xl={{ span: 12 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="title">
            {selected.length > 0 && !loading ? (
              foodCards.length > 0 ? (
                <h4>
                  You Can Make{" "}
                  {selectedDetails ? foodCards.length - 1 : foodCards.length}{" "}
                  Recipes
                </h4>
              ) : (
                <h4>No Recipes Found With Selected Ingredients</h4>
              )
            ) : (
              <h4>Every Ingredient You Add Unlocks More Recipes</h4>
            )}
          </span>
        </Col>
        <Col
          xl={{ span: 12 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="content-filters">
            <div className="content-filters_inputWrapper">
              <Input
                placeholder="Search..."
                className="content-filters_inputWrapper-input"
                value={search}
                onChange={(e) => setSearch(e.target?.value)}
              />

              <img src={Images.Search} />
            </div>
            |
            <img
              src={isFilter ? Images.FiltersActive : Images.Filters}
              onClick={() => setIsFilter(!isFilter)}
            />
          </span>
        </Col>
      </Row>
      {isFilter && (
        <Row gutter={[10, 10]} className="top-row" justify="end">
          {/* <Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Key Ingredients"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
							options={excludeOptions}
							mode={"multiple"}
							value={includeIngredients}
							onChange={(value) => setIncludeIngredients([...value])}
						/>
					</Col> */}
          {/* <Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Exclude"
							mode="multiple"
							options={excludeOptions}
							value={excludeFilter}
							onChange={(value) => setExcludeFilter([...value])}
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col> */}
          <Col
            lg={{ span: 8 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Select
              value={mealFilter}
              placeholder="Meal Type"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              options={meals}
              onChange={onChangeMealFilter}
            />
          </Col>
          <Col
            lg={{ span: 8 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Select
              placeholder="Cuisines"
              options={cuisines}
              value={cuisineFilter}
              onChange={(val) => setCuisineFilter(val)}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Col>
          {/* <Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Diet"
							options={diet}
							mode="multiple"
							value={dietFilter}
							onChange={onChangeDietFilter}
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col> */}
          {/* <Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Max Ingredients"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
							options={MAX_INGREDIENTS}
							value={maxIngredients}
							onChange={(value) => setMaxIngredients(value)}
						/>
					</Col> */}

          <Col
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Select
              placeholder="Recipe Time"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              options={COOK_TIME}
              value={cookTime}
              onChange={(val) => setCookTime(val)}
            />
          </Col>

          {/* <Col
						lg={{ span: 6 }}
						md={{ span: 12 }}
						sm={{ span: 12 }}
						xs={{ span: 24 }}
					>
						<Select
							placeholder="Rating"
							getPopupContainer={(triggerNode) => triggerNode.parentElement}
						/>
					</Col> */}

          <Col
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Button className="filter-clear-btn" onClick={handleClearFilters}>
              Clear Filters
            </Button>
          </Col>

          <Col
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Button type="primary" onClick={handleSetFilters}>
              Apply Filter
            </Button>
          </Col>
        </Row>
      )}

      <Row gutter={[20, 15]}>
        {/* {selected?.length > 0 && (
					<Col span={24} className="selected-wrapper">
						{selected?.map((ingredientId) => {
							const item = allIngredients?.find(
								(ing) => ing?.id == ingredientId?.id
							);

							if (_.isEmpty(item)) {
								return <></>;
							}

							return (
								<div className="added-item-wrapper">
									<div className="added-item">
										<p>{item.name}</p>
									</div>
									<div
										className="del-icon-wrapper"
										onClick={() => {
											handleDeleteIngredient(item.id);
										}}
									>
										<img src={Images.Cross} />
									</div>
								</div>
							);
						})}

						{filters?.cuisines?.label && (
							<div className="added-item-wrapper">
								<div className="added-item">
									<p>{filters?.cuisines?.label}</p>
								</div>
								<div
									className="del-icon-wrapper"
									onClick={() => {
										// handleDeleteIngredient(item.id);
									}}
								>
									<img src={Images.Cross} />
								</div>
							</div>
						)}

						<div className="clear-btn-wrapper">
							<Button className="clear-btn" onClick={handleClear}>
								Clear All
							</Button>
						</div>
					</Col>
				)} */}

        {loading ? (
          <Col span={24} className="loader-cont">
            <BeatLoader size={20} color={"#40B9B6"} />
          </Col>
        ) : (
          filteredFoodCards?.map((item, i) => (
            <>
              {item?.display === viewDetailsCard ? (
                <Col
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  className="content-popover"
                >
                  <img
                    src={Images.CloseIcon}
                    className="popover-cancel"
                    onClick={() => {
                      setfoodCards(
                        selected.length > 0
                          ? [...filteredRecipes]
                          : [...recipes]
                      );
                      setSelectedDetails(undefined);
                    }}
                  />
                  <DetailsPopoverContent
                    image={
                      foodCards?.find?.((dt) => dt?.id === viewDetailsCard)
                        ?.thumbnail
                    }
                    title={
                      foodCards?.find?.((dt) => dt?.id === viewDetailsCard)
                        ?.title
                    }
                    subtitle={
                      foodCards?.find?.((dt) => dt?.id === viewDetailsCard)
                        ?.source
                    }
                    ingredients={
                      foodCards?.find?.((dt) => dt?.id === viewDetailsCard)
                        ?.ingredients
                    }
                    handleFullRecipe={() =>
                      window.open(
                        foodCards?.find?.((dt) => dt?.id === viewDetailsCard)
                          ?.url,
                        "_blank"
                      )
                    }
                    isDisabled={
                      foodCards?.find?.((dt) => dt?.id === viewDetailsCard)?.url
                        ? false
                        : true
                    }
                    owner={
                      foodCards?.find?.((dt) => dt?.id === viewDetailsCard)
                        ?.owner
                    }
                    id={
                      foodCards?.find?.((dt) => dt?.id === viewDetailsCard)?.id
                    }
                  />
                </Col>
              ) : (
                <Col
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                  md={{ span: 8 }}
                  sm={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <span className="responsive-1">
                    <FoodCardComponent
                      title={item.title}
                      image={item.thumbnail}
                      subtitle={item.source}
                      handleDetails={() => {
                        handleViewDetailList(item.id, i, 4);
                        setSelectedDetails(item.id);
                      }}
                      id={item.id}
                      selected={selectedDetails}
                      {...item}
                    />
                  </span>
                  <span className="responsive-2">
                    <FoodCardComponent
                      title={item.title}
                      image={item.thumbnail}
                      subtitle={item.source}
                      handleDetails={() => {
                        handleViewDetailList(item.id, i, 3);
                        setSelectedDetails(item.id);
                      }}
                      id={item.id}
                      selected={selectedDetails}
                      {...item}
                    />
                  </span>
                  <span className="responsive-3">
                    <FoodCardComponent
                      title={item.title}
                      image={item.thumbnail}
                      subtitle={item.source}
                      handleDetails={() => {
                        navigate(`/recipe-details/${item.id}`);
                      }}
                      id={item.id}
                      selected={selectedDetails}
                      {...item}
                    />
                  </span>
                </Col>
              )}
            </>
          ))
        )}
      </Row>
    </div>
  );
}

export default FoodieContent;
