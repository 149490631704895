// @flow
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const RatingsReducer = createSlice({
	name: "ratings",
	initialState: {
		ratings: [],
	},
	reducers: {
		createRatingRequest() {},

		getRatingsRequest() {},

		getRatingsSuccess(state, action) {
			state.ratings = action.payload;
		},

		updateRatingRequest() {},
	},
});

export const {
	createRatingRequest,
	getRatingsRequest,
	getRatingsSuccess,
	updateRatingRequest,
} = RatingsReducer.actions;

export default RatingsReducer.reducer;
