import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DetailsPopoverContent } from "../../../components";
import { FOOD_CARD } from "../../../constants";
import { Images } from "../../../theme";
import "./styles.scss";
function RecipeDetails() {
	const recipes = useSelector((state) => state.recipe?.recipes);
	const navigate = useNavigate();
	const { id } = useParams();
	const item = recipes?.find((x) => x.id.toString() === id);

	return (
		<div className="recipe-details-wrapper">
			<Row gutter={[16, 0]} align="middle" className="top-row">
				<Col span={24}>
					<span className="title">
						<img src={Images.Back} onClick={() => navigate(-1)} />
						<h4>Back</h4>
					</span>
				</Col>
			</Row>
			<Row gutter={[10, 10]}>
				<Col span={24}>
					<DetailsPopoverContent
						image={item?.thumbnail}
						title={item?.title}
						subtitle={item?.source}
						{...item}
						handleFullRecipe={() => window.open(item?.url, "_blank")}
						isDisabled={item?.url ? false : true}
					/>
				</Col>
			</Row>
		</div>
	);
}

export default RecipeDetails;
