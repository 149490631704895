import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SIDEBAR_INGREDIENTS } from "../../constants";
import { ingredDelete, ingredSelect } from "../../redux/slicers/ingredients";
import { clearFilter } from "../../redux/slicers/recipe";
import { Images } from "../../theme";
import IngredientsCard from "../IngredientsCard";
import RegisterModal from "../registerModal";
import SuggestIngredientsModal from "../SuggestIngredientsModal";
import SuggestRecipe from "../SuggestRecipe";
import "./styles.scss";
function SideBarIngredients({ list }) {
	const dispatch = useDispatch();

	const [selectedIngredients, setSelectedIngredients] = useState([]);
	const [openIngredientModal, setOpenIngredientModal] = useState(false);
	const [openSuggestRecipeModal, setOpenSuggestRecipeModal] = useState(false);
	const [registerModalOpen, setRegisterModalOpen] = useState(false);

	const selected = useSelector(({ ingredients }) => ingredients.selected);
	const isAuthenticated = useSelector(({ user }) => user?.isAuthenticated);

	const handleingredient = (indx) => {
		let clone = selected.slice(0);
		if (!clone.includes(indx)) {
			clone = [...clone, indx];

			dispatch(ingredSelect(indx));
		} else {
			dispatch(ingredDelete(indx?.id));
			dispatch(clearFilter());
		}
		setSelectedIngredients(clone);
	};

	const openRecipeModal = () => {
		if (!isAuthenticated) {
			setRegisterModalOpen(true);
			return;
		}

		setOpenSuggestRecipeModal(true);
	};

	return (
		<>
			<div className="ingredients-wrapper">
				<h4>Ingredients</h4>
				{list?.length > 0 &&
					list?.map((item, i) => (
						<IngredientsCard
							selectedIng={selectedIngredients}
							handleIng={handleingredient}
							title={item?.name}
							// image={item.image}
							items={item?.items}
							kidey={item.id}
						/>
					))}

				<div className="sidebar-wrapper_footer">
					<button
						className="sidebar-wrapper_footer-recipe"
						onClick={openRecipeModal}
					>
						Add your recipe
					</button>

					<button
						className="sidebar-wrapper_footer-ingredient"
						onClick={() => setOpenIngredientModal(true)}
					>
						Suggest ingredient
					</button>
				</div>
			</div>

			{openIngredientModal && (
				<SuggestIngredientsModal
					handleClose={() => setOpenIngredientModal(false)}
				/>
			)}

			{openSuggestRecipeModal && (
				<SuggestRecipe handleClose={() => setOpenSuggestRecipeModal(false)} />
			)}

			{registerModalOpen && (
				<RegisterModal
					handleClose={() => setRegisterModalOpen(false)}
					callback={() => {
						setOpenSuggestRecipeModal(true);
					}}
				/>
			)}
		</>
	);
}

export default SideBarIngredients;
