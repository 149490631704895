import { Checkbox, Col, Row } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setMealFilter } from "../../redux/slicers/recipe";
import "./styles.scss";

function SideBarMeals() {
  const dispatch = useDispatch();
  const [initLenght, setInitLength] = useState(6);
  const [selectedMeal, setSelectedMeal] = useState({});
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);

  const mealsOptions = useSelector((state) => state?.recipe?.sidebarMeals);

  const storeMealFilter = useSelector((state) => state?.recipe?.mealFilter);

  useEffect(() => {
    if (!_.isEmpty(storeMealFilter)) {
      setSelectedMeal(storeMealFilter);
    }
    setLoadedFirstTime(true);
  }, []);

  useEffect(() => {
    if (loadedFirstTime) {
      dispatch(setMealFilter({ payloadData: selectedMeal }));
    }
  }, [selectedMeal]);

  const handleChangeCheckBox = (val, item) => {
    const selectedMealClone = { ...selectedMeal };

    const updateOptionIdx = selectedMealClone?.options?.findIndex(
      (op) => op?.id == item?.id
    );

    // debugger;

    if (updateOptionIdx > -1) {
      selectedMealClone.options = selectedMealClone.options?.map((option) => {
        if (option?.id == item?.id) {
          return {
            ...option,
            check: val?.target?.checked,
          };
        } else {
          return {
            ...option,
            check: false,
          };
        }
      });

      setSelectedMeal({ ...selectedMealClone });
    }
  };

  console.log({ selectedMeal });
  return (
    <div className="meals-wrapper">
      <h4>Meals</h4>
      <div className="meals-options">
        {mealsOptions?.length > 0 &&
          mealsOptions?.slice(0, initLenght)?.map((item) => (
            <>
              {item.name !== "Low Carb Recipes" && (
                <div
                  className={`meals-options-items ${
                    selectedMeal?.name == item?.name &&
                    "meals-options-items-active "
                  }`}
                  onClick={() => setSelectedMeal(item)}
                  key={item?.name}
                >
                  <span>{item.name}</span>
                </div>
              )}
            </>
          ))}
        {mealsOptions?.length > 6 && (
          <div
            className=" meals-options-items meals-options-items-active"
            onClick={() => {
              if (initLenght > 6) {
                setInitLength(6);
              } else {
                setInitLength(mealsOptions.length);
              }
            }}
          >
            <span>{initLenght > 6 ? "See Less" : "See More"}</span>
          </div>
        )}
        {selectedMeal?.name && (
          <Row style={{ width: "100%", marginTop: "30px" }} gutter={[0, 10]}>
            <Col span={24}>
              <span
                className="meals-options-items meals-options-items-active meals-options-selected "
                onClick={() => setSelectedMeal(null)}
              >
                <span>{selectedMeal.name}</span>
              </span>
            </Col>

            {/* {selectedMeal?.options?.length > 0 &&
              selectedMeal?.options?.map((option) => (
                <Col span={24}>
                  <Checkbox
                    checked={option?.check}
                    onChange={(val) => handleChangeCheckBox(val, option)}
                  >
                    {"<"}
                    {option?.minQuantity} {option?.unit} of {option?.name}
                  </Checkbox>
                </Col>
              ))} */}

            {/* <Col span={24}>
							<Checkbox>{"<"}20 grams of carbohydrates/serving</Checkbox>
						</Col> */}
          </Row>
        )}
      </div>
    </div>
  );
}

export default SideBarMeals;
