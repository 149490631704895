import { call, fork, put, take, takeEvery } from "redux-saga/effects";
import {
	BASE_URL,
	callRequest,
	CREATE_INGREDIENT,
	GET_CATEGORIES,
	GET_INGREDIENTS,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import { categoriesManipulator } from "../../dataManuplator/categories";
import { toastAlert } from "../../services/utils";
import {
	createIngredientRequest,
	getIngredientsCategoriesRequest,
	getIngredientsCategoriesSuccess,
	getIngredientsRequest,
	getIngredientsSuccess,
	setIngsLoader,
} from "../slicers/ingredients";

function* getCategories() {
	while (true) {
		const { payload } = yield take(getIngredientsCategoriesRequest.type);
		const { payloadData, responseCallback } = payload;
		yield put(setIngsLoader(true));

		try {
			const response = yield call(
				callRequest,
				GET_CATEGORIES,
				{},
				"",
				"",
				{},
				BASE_URL
			);
			if (response.data) {
				yield put(
					getIngredientsCategoriesSuccess(categoriesManipulator(response.data))
				);
				responseCallback(true, response);
			} else {
				responseCallback(false, response);
			}
			yield put(setIngsLoader(false));
		} catch (err) {
			yield put(setIngsLoader(false));

			responseCallback && responseCallback(false, err);
		}
	}
}

function* getIngredients() {
	while (true) {
		const { payload } = yield take(getIngredientsRequest.type);
		const { payloadData, responseCallback } = payload;

		try {
			const response = yield call(
				callRequest,
				GET_INGREDIENTS,
				{},
				"",
				"",
				{},
				BASE_URL
			);
			if (response.data) {
				yield put(getIngredientsSuccess(response));
				responseCallback(true, response);
			} else {
				responseCallback(false, response);
			}
		} catch (err) {
			responseCallback && responseCallback(false, err);
		}
	}
}

function* ingredientCreate() {
	yield takeEvery(createIngredientRequest.type, createIngredient);
}

function* createIngredient({ payload }) {
	const { payloadData, responseCallback } = payload;
	try {
		const response = yield call(
			callRequest,
			CREATE_INGREDIENT,
			payloadData,
			"",
			"",
			{},
			BASE_URL
		);

		if (response.data) {
			yield put(getIngredientsSuccess(response));
			responseCallback && responseCallback(true, response);
		} else {
			responseCallback && responseCallback(false, response);
		}
	} catch (err) {
		responseCallback && responseCallback(false, err);
	}
}

export default function* root() {
	yield fork(getCategories);
	yield fork(getIngredients);
	yield fork(ingredientCreate);
}
